/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminModelType, AdminObject, UserModelType, UserRoleEnum } from "@platinium/cdk";
import { cloneDeep, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useRef } from "react";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridForm } from "../../components/DataGrid/DataGridForm";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from '../../components/Loader/Loader';
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";
import { AdminForm } from "./AdminForm";

const columns = [
  {
    label: "Prenom",
    name: "user.firstName",
  },
  {
    label: "Nom",
    name: "user.lastName",
  },
  {
    label: "Email",
    name: "user.email",
  },
  {
    label: "Téléphone",
    name: "user.phoneNumber",
  },
  {
    label: "",
    name: "actions",
  },
];

export const Admins = observer(() => {
  const { admins: adminsStore } = useStore();
  const { admins: adminsApi, users: usersApi } = useApi();
  const {
    search,
    setSearch,
    entities: admins,
    pages,
    currentPage,
    setCurrentPage,
    ordering,
    setOrdering,
  } = useDataGrid({
    findFunction: adminsStore.findAdmins,
    searchBuilder: useCallback((search) => ({
      $or: [
        {
          "user.firstName": {
            $contL: search,
          },
        },
        {
          "user.lastName": {
            $contL: search,
          },
        },
        {
          "user.email": {
            $contL: search,
          },
        },
      ],
    }), []),
  });

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onSubmitForm = useCallback(
    async (data: AdminObject) => {
      if (data.id) {
        const user = {
          email: data.user.email,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          phoneNumber: data.user.phoneNumber,
        };
        await usersApi.updateUser(data.user.id, { ...pickBy(user) });
      } else {
        const { user } = data;
        const userCreated = await usersApi.createUser({ ...pickBy(user), role: UserRoleEnum.ADMIN });
        await adminsApi.createAdmin({ userId: userCreated.id });
      }
      admins.reload();
    },
    [admins, adminsApi, usersApi]
  );

  const onDelete = useCallback(
    async (data: AdminModelType) => {
      await adminsApi.deleteAdmin(data.id);
      admins.reload();
    },
    [admins, adminsApi]
  );

  const descriptionName = (user: UserModelType) => {
    const { firstName, lastName } = user || {};
    return firstName || lastName ? `${firstName || ""} ${lastName || ""}` : "cet admin ";
  };

  return (
    <>
      {admins.data ? (
        <div className="flex-1">
          <DataGridForm
            ref={formRef}
            title={(form) => {
              const data = form.getValues();
              return data.id ? `Modifier un admin` : "Créer un admin";
            }}
            onSubmit={onSubmitForm}
          >
            {(form) => <AdminForm form={form}></AdminForm>}
          </DataGridForm>

          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer un admin"
            description={(data) => `Êtes vous sur de vouloir supprimer ${descriptionName(data?.user)}?`}
          />
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader
                searchPlaceholder="Chercher un admin par nom, prénom, email"
                search={search}
                onSearchChange={setSearch}
                actions={
                  <button onClick={() => formRef.current && formRef.current.open()} type="button" className="normal-case btn btn-sm">
                    NOUVEL ADMIN
                  </button>
                }
              />
              <div className="overflow-x-auto">
                <DataGridTable dataProvider={admins} columns={columns} ordering={ordering} onOrderingChange={setOrdering}>
                  {(data) =>
                    data.data.map((admin: AdminModelType) => {
                      const isAdminAuthenticated = admin.user.email == adminsStore.adminAuthenticatedAdmin;
                      return (
                        <tr
                          key={admin.id}
                          className="cursor-pointer hover"
                          onClick={(e) => {
                            formRef.current && formRef.current.open(cloneDeep(admin));
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">{admin.user.firstName || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">{admin.user.lastName || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">
                            {isAdminAuthenticated ? (
                              <>
                                <b>{admin.user.email}</b>
                                <div className="inline-flex px-2 ml-2 text-xs text-white bg-black rounded-xl w-min">Vous</div>
                              </>
                            ) : (
                              admin.user.email
                            )}
                          </th>
                          <th className="text-sm whitespace-nowrap">{admin.user.phoneNumber || "N/A"}</th>
                          <th className="space-x-2 text-sm font-medium text-right">
                            <button
                              onClick={(e) => {
                                formRef.current && formRef.current.open(cloneDeep(admin));
                              }}
                              type="button"
                              title="Edit"
                              className="hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>

                            {!isAdminAuthenticated ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteRef.current && deleteRef.current.open(admin);
                                }}
                                type="button"
                                title="Delete"
                                className="text-error hover:text-error-active"
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            ) : (
                              <button type="button" title="Delete" className="text-transparent">
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            )}
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {admins.data && admins.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{admins.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
