import { faCirclePlus, faFilePdf, faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TrainingModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { useStore } from "../../context/store.context";

interface TrainingProps {
  training: TrainingModelType;
}
export const TrainingItem = observer(({ training }: TrainingProps) => {
  const { control, handleSubmit } = useForm();
  const [isForm, setIsForm] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const {
    trainings: { findTrainings, uploadDocument, deleteDocument, updateTrainings, deleteTraining },
  } = useStore();

  useEffect(() => {
    findTrainings();
  }, []);

  const uploadFile = async (files: FileList, id: string) => {
    await uploadDocument(id, files);
    findTrainings();
  };

  const onSubmit = async (data: any) => {
    if (data.text != "" && data.title != "") {
      const item = {
        text: data.text,
        title: data.title,
      };
      await updateTrainings(training.id, item);
      findTrainings();
      setIsForm(!isForm);
      setErrorText(false);
      setErrorTitle(false);
    } else {
      if (data.title == "") {
        setErrorTitle(true);
      } else {
        setErrorTitle(false);
      }
      if (data.text == "") {
        setErrorText(true);
      } else {
        setErrorText(false);
      }
    }
  };

  const onDeleteDocument = async (entityId: string) => {
    await deleteDocument(entityId);
  };
  const onDeleteTraining = async (entityId: string) => {
    await deleteTraining(entityId);
  };
  const deleteRefDocument = useRef<ElementRef<typeof DataGridDelete>>(null);
  const deleteRefTraining = useRef<ElementRef<typeof DataGridDelete>>(null);

  const vidRef = useRef(null);

  return (
    <div className="mb-auto">
      <DataGridDelete
        ref={deleteRefDocument}
        onDelete={onDeleteDocument}
        title="Supprimer un document"
        description={() => `Êtes vous sur de vouloir supprimer ce document?`}
      />
      <DataGridDelete
        ref={deleteRefTraining}
        onDelete={onDeleteTraining}
        title="Supprimer une formation"
        description={() => `Êtes vous sur de vouloir supprimer cette formation?`}
      />
      <div tabIndex={0} key={training.id} className="border collapse border-base-300 bg-base-100">
        <input type="checkbox" />
        <div className="flex justify-between text-lg font-medium collapse-title">
          <div>{training.title}</div>
        </div>
        <div className="collapse-content">
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => {
                setIsForm(!isForm);
              }}
              type="button"
              title="Edit"
              className="hover:text-blue-500"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              onClick={() => {
                deleteRefTraining.current && deleteRefTraining.current.open(training.id);
              }}
              type="button"
              title="Delete"
              className="text-error hover:text-error-active"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row space-x-2 ">
              <div className="flex flex-col basis-2/4">
                {errorTitle && <div className="text-error">Champs obligatoire pour créer une formation</div>}

                {isForm && (
                  <Controller
                    control={control}
                    name={"title"}
                    render={({ field: { onChange, value = training.title } }) => (
                      <input
                        type="text"
                        className={
                          errorTitle
                            ? "border-error w-full mt-2 flex-full input input-bordered"
                            : "w-full mt-2 flex-full input input-bordered"
                        }
                        value={value}
                        onChange={onChange}
                        placeholder={"Titre"}
                      />
                    )}
                  />
                )}
                {errorText && <div className="text-error">Champs obligatoire pour créer une formation</div>}

                {!isForm ? (
                  <p tabIndex={0} className="basis-2/4">
                    {training.text}
                  </p>
                ) : (
                  <Controller
                    control={control}
                    name={"text"}
                    render={({ field: { onChange, value = training.text } }) => (
                      <textarea
                        className={
                          errorText
                            ? "border-error w-full h-full mt-2 flex-full textarea input input-bordered"
                            : "w-full h-full mt-2 flex-full textarea input input-bordered"
                        }
                        value={value}
                        onChange={onChange}
                        placeholder={"Description de la consigne"}
                      />
                    )}
                  />
                )}
              </div>
              {training.documents &&
                training.documents.map((document: any) => {
                  return (
                    <div key={document.id}>
                      {document.document.key != null && (
                        <div>
                          <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100">
                            <label className="w-20 h-full rounded" htmlFor={"modal-" + document.document.id}>
                              {document.document.extension == "mp4" ? (
                                <video ref={vidRef}>
                                  <source src={document.document.key} type="video/mp4" />
                                </video>
                              ) : document.document.extension == "pdf" ? (
                                <div className="flex justify-center m-auto">
                                  <div>
                                    <FontAwesomeIcon className="fa-3x" icon={faFilePdf} />
                                  </div>
                                </div>
                              ) : (
                                <img src={document.document.key} />
                              )}
                              {isForm && (
                                <label
                                  className="absolute top-0 right-0 border-none btn btn-sm btn-circle bg-error"
                                  onClick={() => {
                                    deleteRefDocument.current && deleteRefDocument.current.open(document.id);
                                  }}
                                >
                                  ✕
                                </label>
                              )}
                            </label>
                          </div>

                          <input type="checkbox" id={"modal-" + document.document.id} className="modal-toggle" />
                          <div className="modal">
                            <div className="relative modal-box">
                              <label htmlFor={"modal-" + document.document.id} className="absolute btn btn-sm btn-circle right-2 top-2">
                                ✕
                              </label>

                              <div className="flex justify-center">
                                {document.document.extension == "mp4" ? (
                                  <video ref={vidRef} controls>
                                    <source src={document.document.key} type="video/mp4" />
                                  </video>
                                ) : document.document.extension == "pdf" ? (
                                  <a href={document.document.key}>Download Pdf</a>
                                ) : (
                                  <img src={document.document.key} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100 hover:text-blue-500 hover:border-blue-500 hover:border">
                <label className="flex w-5 m-auto rounded">
                  <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
                  <input
                    type="file"
                    accept="video/mp4,application/pdf"
                    id="file1"
                    className="hidden"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files) {
                        uploadFile(event.target.files, training.id);
                      }
                    }}
                  />
                </label>
              </div>
            </div>
            {isForm && (
              <div className="flex justify-end mt-2">
                <button
                  type="button"
                  className="mr-2 normal-case border-none btn btn-sm bg-error"
                  onClick={() => {
                    setIsForm(!isForm);
                  }}
                >
                  Annuler
                </button>
                <button className="normal-case border-none btn btn-sm bg-success" type="submit">
                  Enregistrer
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
});
