import { faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from "../../components/Loader/Loader";
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";
import { displayContracts } from './client.utils';

interface FormData {
  name: string;
}

const columns = [
  {
    label: "Nom du site",
    name: "name",
  },
  {
    label: "Contrat",
    name: "isClientPlatinium",
  },
  {
    label: "Contact principal",
    name: "mainContactEmail",
  },
  {
    label: "",
    name: "actions",
  },
];
const sortableColumns = ["name"];

export const ClientLocations = observer(() => {
  const { setValue } = useForm<FormData>();
  const { clientLocations: clientLocationsStore } = useStore();
  const {
    contacts: { clearContact },
  } = useStore();
  const { clientLocations: clientLocationsApi } = useApi();

  const {
    clients: { client, fetchEntity },
  } = useStore();
  const {
    clientLocations: { clearClientLocation },
  } = useStore();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const {
    search,
    setSearch,
    entities: clientLocations,
    pages,
    currentPage,
    setCurrentPage,
    ordering,
    setOrdering,
  } = useDataGrid({
    findFunction: clientLocationsStore.findClientLocations,
    searchBuilder: (search) => ({
      $and: [
        {
          $or: [
            {
              name: {
                $contL: search,
              },
            },
          ],
        },
        {
          clientId: {
            $eq: clientId,
          },
        },
      ],
    }),
  });
  
  useEffect(() => {
    if (clientId) {
      fetchEntity(clientId);
      clearContact();

    }
  }, [clientId]);

  useEffect(() => {
    if (client) {
      setValue("name", client.name);
    }
  }, [client]);

  useEffect(() => {
    clearClientLocation();
  }, []);

  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onDelete = useCallback(
    async (data: ClientModelType) => {
      await clientLocationsApi.deleteClientLocation(data.id);
      clientLocations.reload();
    },
    [clientLocations, clientLocationsApi]
  );

  return (
    <>
      {clientLocations.data ? (
        <div className="mb-auto">
          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer un client"
            description={(data) => `Êtes vous sur de vouloir supprimer ${data?.name} ?`}
          />
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader
                searchPlaceholder="Chercher un site par nom"
                search={search}
                onSearchChange={setSearch}
                actions={
                  <button
                    onClick={() => navigate(`/clientLocation/?clientId=${clientId}`)}
                    type="button"
                    className="normal-case btn btn-sm"
                  >
                    NOUVEAU SITE
                  </button>
                }
              />
              <div className="overflow-x-auto">
                <DataGridTable dataProvider={clientLocations} columns={columns} ordering={ordering} onOrderingChange={setOrdering} sortableColumns={sortableColumns}>
                  {(data) =>
                    data.data.map((clientLocation: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="cursor-pointer hover"
                          onClick={() => {
                            navigate("/clientLocation/" + clientLocation.id);
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">{clientLocation.name || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">
                            {displayContracts(clientLocation.isClientPlatinium, clientLocation.isClientSunset)}
                          </th>
                          <th className="text-sm truncate whitespace-nowrap">
                            {clientLocation.mainContactEmail ? clientLocation.mainContactEmail : "N/A"}
                          </th>
                          <th className="space-x-2 text-sm font-medium text-right">
                            <button
                              onClick={() => {
                                navigate("/clientLocation/" + clientLocation.id);
                              }}
                              type="button"
                              title="Edit"
                              className="hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>

                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteRef.current && deleteRef.current.open(clientLocation);
                              }}
                              type="button"
                              title="Delete"
                              className="text-error hover:text-error-active"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {clientLocations.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{clientLocations.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-10">
          <Loader />
        </div>
      )}
    </>
  );
});
