import { Instance, types } from "mobx-state-tree";

import { DateTimeType } from "src/modules/models/date-time.model";

export const MeetingModel = types.model("AgentModel", {
  id: types.identifier,
  status: types.boolean,
  text: types.string,
  createdAt: DateTimeType,
  updatedAt: DateTimeType,
});

export type MeetingModelType = Instance<typeof MeetingModel>;
