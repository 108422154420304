import { ClientLocationObject, ProductObject } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

export const ClientLocationProductModel = types.model("ClientLocationProductModel", {
  id: types.identifier,
  clientLocation: types.frozen<ClientLocationObject>(),
  product: types.frozen<ProductObject>(),
});

export type ClientLocationProductModelType = Instance<typeof ClientLocationProductModel>;
