/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  adminsStore,
  AdminsStore,
  agentsStore,
  AgentsStore,
  axiosInstance,
  clientLocationAgentsStore,
  ClientLocationAgentsStore,
  clientLocationCheckinsStore,
  ClientLocationCheckinsStore,
  ClientLocationContactsStore,
  clientLocationContactsStore,
  clientLocationDatasStore,
  ClientLocationDatasStore,
  clientLocationsStore,
  ClientLocationsStore,
  ClientsStore,
  clientsStore,
  contactsStore,
  ContactsStore,
  createAdminApi,
  createAgentApi,
  createAuthenticationApi,
  createClientApi,
  createClientLocationAgentApi,
  createClientLocationApi,
  createClientLocationCheckinsApi,
  createClientLocationContactApi,
  createClientLocationDataApi,
  createClientLocationProductApi,
  createContactApi,
  createInstructionApi,
  createMeetingApi,
  createOperationApi,
  createOrderApi,
  createOrderProductApi,
  createProblemApi,
  createProductApi,
  createTrainingApi,
  createUserApi,
  OperationsStore,
  operationsStore,
  ordersStore,
  OrdersStore,
  ProblemsStore,
  problemsStore,
  productsStore,
  ProductsStore,
  regularInstructionsStore,
  RegularInstructionsStore,
  trainingsStore,
  TrainingsStore,
  urgentInstructionsStore,
  UrgentInstructionsStore,
  userStore,
  UserStore,
} from "@platinium/cdk";
import { Instance, types } from "mobx-state-tree";

export const RootStore = types.model("RootStore", {
  admins: AdminsStore,
  users: UserStore,
  agents: AgentsStore,
  clients: ClientsStore,
  clientLocations: ClientLocationsStore,
  clientLocationCheckins: ClientLocationCheckinsStore,
  regularInstructions: RegularInstructionsStore,
  urgentInstructions: UrgentInstructionsStore,
  clientLocationDatas: ClientLocationDatasStore,
  contacts: ContactsStore,
  clientLocationAgent: ClientLocationAgentsStore,
  clientLocationContacts: ClientLocationContactsStore,
  trainings: TrainingsStore,
  orders: OrdersStore,
  operations: OperationsStore,
  products: ProductsStore,
  problems: ProblemsStore,
});

declare global {
  interface Window {
    __RUNTIME_CONFIG__: {
      REACT_APP_API_URL: string;
    };
  }
}

export type RootStoreType = Instance<typeof RootStore>;

export const createStore = () => {
  return RootStore.create(
    {
      users: userStore,
      admins: adminsStore as any,
      agents: agentsStore as any,
      clients: clientsStore as any,
      clientLocationCheckins: clientLocationCheckinsStore as any,
      regularInstructions: regularInstructionsStore,
      urgentInstructions: urgentInstructionsStore,
      clientLocations: clientLocationsStore as any,
      clientLocationDatas: clientLocationDatasStore,
      contacts: contactsStore as any,
      clientLocationAgent: clientLocationAgentsStore,
      trainings: trainingsStore,
      orders: ordersStore as any,
      operations: operationsStore as any,
      products: productsStore as any,
      problems: problemsStore,
      clientLocationContacts: clientLocationContactsStore as any,
    },
    {
      api: {
        authentication: createAuthenticationApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        admins: createAdminApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        users: createUserApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        agents: createAgentApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        meetings: createMeetingApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        clients: createClientApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        instructions: createInstructionApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        contacts: createContactApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        orders: createOrderApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        operations: createOperationApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        orderProducts: createOrderProductApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        products: createProductApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        problems: createProblemApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        clientLocationAgents: createClientLocationAgentApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        clientLocationContacts: createClientLocationContactApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        clientLocationProducts: createClientLocationProductApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        clientLocations: createClientLocationApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        clientLocationDatas: createClientLocationDataApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        clientLocationCheckins: createClientLocationCheckinsApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
        trainings: createTrainingApi(axiosInstance(window.__RUNTIME_CONFIG__.REACT_APP_API_URL)),
      },
    }
  );
};

export const rootStore = createStore();
