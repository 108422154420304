import { faCirclePlus, faDownload, faPen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserRoleEnum } from "@platinium/cdk";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { Loader } from "../../components/Loader/Loader";
import { Problems } from "../../components/Problem/Problems";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { catchErrorAlert } from "../../utils/error.utils";
import { european } from "./european";
import { MeetingList } from "./MettingList";
import { nationalityOption } from "./nationality";
interface FormData {
  address: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumberPer: string;
  phoneNumberPro: string;
  civility: string;
  nationality: string;
  expirationDateResidencePermit: any;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
}

export const AgentForm = observer(() => {
  const [isInput, setIsInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();
  const {
    agents: { agent, fetchEntity },
  } = useStore();
  const { agents: agentsApi, users: usersApi } = useApi();
  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormData>();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const agentId = query.get("agentId");

  useEffect(() => {
    (async () => {
      if (agentId != null) {
        await fetchEntity(agentId);
        setIsLoading(true);
      } else {
        setIsLoading(true);
      }
    })();
  }, [agentId]);

  useEffect(() => {
    if (agent) {
      setValue("email", agent.user.email);
      if (agent.user.lastName) setValue("lastName", agent.user.lastName);
      if (agent.user.firstName) setValue("firstName", agent.user.firstName);
      if (agent.user.civility) setValue("civility", agent.user.civility);
      if (agent.user.nationality) setValue("nationality", agent.user.nationality);
      if (agent.phoneNumberPro) setValue("phoneNumberPro", agent.phoneNumberPro);
      if (agent.user.phoneNumber) setValue("phoneNumberPer", agent.user.phoneNumber);
      if (agent.addressLine1) setValue("addressLine1", agent.addressLine1);
      if (agent.addressLine2) setValue("addressLine2", agent.addressLine2);
      if (agent.postalCode) setValue("postalCode", agent.postalCode);
      if (agent.city) setValue("city", agent.city);
      if (agent.expirationDateResidencePermit)
        setValue("expirationDateResidencePermit", agent.expirationDateResidencePermit.toString().substring(0, 10));
    }
  }, [agent]);

  const onSubmit = async (data: FormData) => {
    const user = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumberPer,
      nationality: data.nationality,
      civility: data.civility,
    };
    if (agent) {
      await usersApi.updateUser(agent.user.id, { ...pickBy(user) }).catch(catchErrorAlert);
      const result = await agentsApi
        .updateAgent(agent.id, {
          isMultiEmployee: false,
          phoneNumberPro: data.phoneNumberPro,
          expirationDateResidencePermit: data.expirationDateResidencePermit,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          postalCode: data.postalCode,
          city: data.city,
        })
        .catch(catchErrorAlert);
      fetchEntity(result.id);
    } else {
      const userCreated = await usersApi.createUser({ ...pickBy(user), role: UserRoleEnum.AGENT }).catch(catchErrorAlert);
      try {
        const result = await agentsApi
          .createAgent({
            userId: userCreated.id,
            role: UserRoleEnum.AGENT,
            isMultiEmployee: false,
            phoneNumberPro: data.phoneNumberPro,
            expirationDateResidencePermit: data.expirationDateResidencePermit,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            postalCode: data.postalCode,
            city: data.city,
          })
          .catch(catchErrorAlert);
        fetchEntity(result.id);
        setValue("email", result.user.email);
      } catch (error) {
        await usersApi.deleteUser(userCreated.id);
        throw error;
      }
    }
    setIsInput(!isInput);
  };

  const uploadFile = async (file: File) => {
    if (!agentId) return;
    try {
      await agentsApi.uploadAgentDocument(agentId, file);
      fetchEntity(agentId);
    } catch (error) {
      console.log("error", error);
    }
  };
  const isEuropeen = () => {
    const e = european.findIndex((e) => e == agent?.user.nationality);
    return e;
  };

  const requestAdminPassword = useCallback(() => {
    if (agent) {
      usersApi
        .requestPasswordAdmin({
          email: agent.user.email,
        })
        .then((data: any) => {
          window.open(data.resetPasswordLink, "_blank");
        });
    }
  }, [agent]);

  return (
    <>
      {isLoading ? (
        <div className="container py-5 mx-auto flex-1 ">
          <div className="w-full">
            <div className="m-2 shadow-xl card bg-base-100 ">
              <div className="card-body">
                {isInput ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between">
                      <div></div>
                      <div className="flex justify-center m-2 space-x-4 ">
                        <div>
                          <select className="items-center w-full select select-bordered" {...register("civility")}>
                            <option disabled selected>
                              Civilité
                            </option>
                            <option value={"MALE"}>Homme</option>
                            <option value={"FEMALE"}>Femme</option>
                            <option value={"OTHER"}>Autre</option>
                          </select>
                        </div>
                        <div>
                          <Controller
                            control={control}
                            name="lastName"
                            rules={{ required: true }}
                            render={({ field: { onChange, value = "" } }) => (
                              <input
                                type="text"
                                value={value}
                                onChange={onChange}
                                placeholder="Nom"
                                className="w-full input input-bordered"
                              />
                            )}
                          />
                          {errors.lastName?.type === "required" && <span className="text-error">Un nom est requis</span>}
                        </div>
                        <div>
                          <Controller
                            control={control}
                            name="firstName"
                            rules={{ required: true }}
                            render={({ field: { onChange, value = "" } }) => (
                              <input
                                type="text"
                                value={value}
                                onChange={onChange}
                                placeholder={"Prénom"}
                                className="w-full input input-bordered"
                              />
                            )}
                          />
                          {errors.firstName?.type === "required" && <span className="text-error">Un prénom est requis</span>}
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          setIsInput(!isInput);
                        }}
                        type="button"
                        title="Edit"
                        className="hover:text-blue-500"
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </div>

                    <div className="w-full space-y-2 ">
                      <Controller
                        control={control}
                        name={"phoneNumberPro"}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder={"N° de téléphone professionnel"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      {errors.phoneNumberPro?.type === "required" && (
                        <span className="text-error">Un numéro de téléphone pro est requis</span>
                      )}

                      <Controller
                        control={control}
                        name={"phoneNumberPer"}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder={"N° de téléphone personnel"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      {errors.phoneNumberPer?.type === "required" && (
                        <span className="text-error">Un numéro de téléphone personnel est requis</span>
                      )}

                      <Controller
                        control={control}
                        name={"email"}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value = "" } }) => (
                          <input
                            type="email"
                            placeholder="Rentrez votre email"
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            className="w-full input input-bordered"
                          ></input>
                        )}
                      />
                      {errors.email?.type === "required" && <span className="text-error">Un email est requis</span>}
                      <select className="items-center w-full select select-bordered" {...register("nationality")}>
                        <option disabled selected>
                          Nationalité
                        </option>
                        {nationalityOption &&
                          nationalityOption.map((nationality) => {
                            return (
                              <option key={nationality.key} value={nationality.key}>
                                {nationality.value}
                              </option>
                            );
                          })}
                      </select>
                      <Controller
                        control={control}
                        name={"addressLine1"}
                        rules={{ required: true }}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"addresse ligne 1"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      {errors.nationality?.type === "required" && <span className="text-error">Une nationalité personnel est requis</span>}

                      <Controller
                        control={control}
                        name={"addressLine2"}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"addresse ligne 2"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={"postalCode"}
                        rules={{ required: true }}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"Code postal"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      {errors.postalCode?.type === "required" && <span className="text-error">Un code postal personnel est requis</span>}

                      <Controller
                        control={control}
                        name={"city"}
                        rules={{ required: true }}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"Ville"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      {errors.city?.type === "required" && <span className="text-error">Une ville est requis</span>}

                      <Controller
                        control={control}
                        name={"expirationDateResidencePermit"}
                        rules={{ required: false }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <input
                            type="date"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder={"Date expiration titre de séjour"}
                            className="w-full pt-2 input input-bordered"
                          />
                        )}
                      />
                      {errors.expirationDateResidencePermit?.type === "required" && <span className="text-error">Une date est requis</span>}
                    </div>
                    <div className="flex justify-end mt-2">
                      <button
                        type="button"
                        className="mr-2 normal-case border-none btn btn-sm bg-error"
                        onClick={() => {
                          setIsInput(!isInput);
                        }}
                      >
                        Annuler
                      </button>
                      <button className="normal-case border-none btn btn-sm bg-success" type="submit">
                        Enregistrer
                      </button>
                    </div>
                  </form>
                ) : (
                  <div>
                    <div className="flex justify-between">
                      <div></div>
                      <h2 className="card-title">
                        {agent ? (agent.user.lastName != null ? agent.user.lastName : "Nom") : "Nom"}{" "}
                        {agent ? (agent.user.firstName != null ? agent.user.firstName : "Prénom") : "Prénom"}
                      </h2>

                      <button
                        onClick={() => {
                          setIsInput(!isInput);
                        }}
                        type="button"
                        title="Edit"
                        className="hover:text-blue-500"
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </div>
                    <div className="w-full">
                      <label className="label">
                        <span className="label-text">Civilité : {agent ? agent.user.civility : ""}</span>
                      </label>
                      <label className="label">
                        <span className="label-text">N° de téléphone pro : {agent ? agent.phoneNumberPro : ""}</span>
                      </label>
                      <label className="label">
                        <span className="label-text">N° de téléphone personnel : {agent ? agent.user.phoneNumber : ""}</span>
                      </label>
                      <label className="label">
                        <span className="label-text">Email : {agent ? agent.user.email : ""}</span>
                      </label>
                      <label className="label">
                        <span className="label-text">Nationalité : {agent ? agent.user.nationality : ""}</span>
                      </label>
                      <div className="flex-col items-start label">
                        <p className="label-text">Adresse :</p>
                        {agent && <p className="label-text">{agent.addressLine1 != null ? agent.addressLine1 : ""}</p>}
                        {agent && <p className="label-text">{agent.addressLine2 != null ? agent.addressLine2 : ""}</p>}
                        {agent && (
                          <p className="label-text">
                            {agent.postalCode != null ? agent.postalCode : ""} {agent.city != null ? agent.city : ""}
                          </p>
                        )}
                      </div>
                      <label className="label">
                        <span className="label-text">
                          Date expiration titre de séjour : {agent ? agent.expirationDateResidencePermit?.toString().substring(0, 10) : ""}
                        </span>
                      </label>
                      <label className="label">
                        <span className="label-text">
                          Client(s) assigné(s) :{" "}
                          {agent
                            ? agent.clientLocationAgents?.length > 0
                              ? agent.clientLocationAgents.map((clientLocationAgent: any, index: number) => {
                                  return (
                                    <span key={clientLocationAgent.id}>
                                      {(index ? ", " : "") + clientLocationAgent.clientLocation.name}
                                    </span>
                                  );
                                })
                              : "N/A"
                            : "N/A"}
                        </span>
                      </label>
                      {isEuropeen() == -1 && (
                        <div>
                          <label className="justify-start label">
                            <span className="mr-3 label-text">Titre de séjour </span>
                            {agent?.document?.key && (
                              <button
                                onClick={() => {
                                  if (agent.document?.key) {
                                    const link = document.createElement("a");
                                    link.href = agent.document.key;
                                    link.setAttribute("target", "_blank");
                                    document.body.appendChild(link);
                                    link.click();
                                  }
                                }}
                                type="button"
                                title="Download"
                              >
                                <FontAwesomeIcon icon={faDownload} className="hover:text-blue-500" />
                              </button>
                            )}
                            <label className="flex w-5 ml-5 rounded cursor-pointer hover:text-blue-500">
                              <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
                              <input
                                type="file"
                                accept="image/*,video/mp4,application/pdf"
                                id="file1"
                                className="hidden"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  if (event.target.files) {
                                    uploadFile(event.target.files[0]);
                                  }
                                }}
                              />
                            </label>
                          </label>
                        </div>
                      )}
                      <label className="label">
                        <span className="label-text">
                          Mot de passe :{" "}
                          <button
                            type="button"
                            className="ml-2 normal-case border-none btn btn-sm bg-error"
                            onClick={() => {
                              requestAdminPassword();
                            }}
                          >
                            Changer le mot de passe
                          </button>
                        </span>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className=" md:flex">
            <div className="md:basis-1/2">
              <div className="m-2 shadow-xl card bg-base-100">
                <div className="card-body">
                  <div className="flex justify-center">
                    <h2 className="card-title">Signalement de problèmes</h2>{" "}
                  </div>
                  {agent && <Problems agentId={agent.id} />}
                </div>
              </div>
            </div>
            <div className="md:basis-1/2">
              <div className="m-2 shadow-xl card bg-base-100">
                <div className="card-body">
                  <div className="flex justify-center">
                    <h2 className="card-title">Demande de rendez-vous</h2>
                  </div>
                  {agent && <MeetingList agentId={agent.id} meetings={agent ? agent.meetings : []} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
