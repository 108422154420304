/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgentObject } from '@platinium/shared';
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export interface AuthenticateAgentDto {
  email: string;
  password: string;
}

export function createAgentApi(axios: AxiosInstance) {
  return {
    async authenticate(data: AuthenticateAgentDto) {
      return axios({
        url: "/agents/authenticate",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async current() {
      return axios({
        url: "/agents/me",
        method: "GET",
      }).then((response) => response.data);
    },
    async fetchAgents(params?: any) {
      return axios({
        url: "/agents",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchAllAgents(params?: any) {
      return axios({
        url: "/agents/all",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneAgent(entityId: string): Promise<PaginatedResponse<any>> {
      return axios({
        url: `/agents/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async createAgent(data: Partial<AgentObject>): Promise<AgentObject> {
      return axios({
        url: "/agents",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async updateAgent(entityId: string, data: Partial<any>) {
      return axios({
        url: `/agents/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async deleteAgent(entityId: string) {
      return axios({
        url: `/agents/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
    async uploadAgentDocument(agentId: string, file: File) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("agentId", agentId);
      return axios({
        url: "/agents/upload-document",
        method: "POST",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data);
    },
  };
}

export type AgentApi = ReturnType<typeof createAgentApi>;
