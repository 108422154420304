import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import LogoPlatinium from "../../assets/images/platinium-logo/Exe_Logo_Platinium_Haut.svg";
import { useStore } from "../../context/store.context";

interface FormData {
  email: string;
  password: string;
  resetPasswordToken: string;
}

export const FirstConnection = () => {
  const { control, handleSubmit, setValue } = useForm<FormData>();
  const { search } = useLocation();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const email = query.get("email");
  const resetPasswordToken = query.get("resetPasswordToken");

  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const {
    users: { resetPassword },
  } = useStore();
  const handleChange = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (!resetPasswordToken) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email]);

  const onSubmit = async (data: FormData) => {
    if (resetPasswordToken) {
      data.resetPasswordToken = resetPasswordToken;
    }
    await resetPassword({
      email: data.email,
      password: data.password,
      resetPasswordToken: data.resetPasswordToken,
    });
    navigate("/");
  };

  return (
    <div className="flex min-h-screen">
      <div className="m-auto">
        <div className="flex justify-center pb-10">
          <img src={LogoPlatinium} className="w-60" alt="Platinium logo"></img>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 ">
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value = "" } }) => (
              <input
                type="email"
                onBlur={onBlur}
                onChange={onChange}
                placeholder={"email"}
                value={value}
                className="w-full input input-bordered"
                disabled
              ></input>
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value = "" } }) => (
              <input
                type={passwordShown ? "text" : "password"}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                placeholder="Rentrez votre mot de passe"
                className="w-full input input-bordered"
              />
            )}
          />
          <label className="justify-start cursor-pointer label">
            <input type="checkbox" checked={passwordShown} onChange={handleChange} className="checkbox" />
            <span className="pl-2 label-text">Montrer le mot de passe</span>
          </label>
          <div className="flex justify-center">
            <button className="btn" type="submit">
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
