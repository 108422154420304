/* eslint-disable @typescript-eslint/no-explicit-any */
import { flow, getEnv, types } from "mobx-state-tree";

import { ClientLocationDataModel } from "src/modules/models";

import { RootStoreEnv } from "./root.store";

export const ClientLocationDatasStore = types
  .model("ClientLocationData", {
    clientLocationDatas: types.maybeNull(types.array(ClientLocationDataModel)),
    clientLocationData: types.maybeNull(ClientLocationDataModel),
  })
  .actions((self) => ({
    findClientLocationDatas: flow(function* _(entityId: string) {
      const {
        api: { clientLocationDatas },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield clientLocationDatas.fetchClientLocationDatas(entityId);
        self.clientLocationDatas = result;
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    findOneClientLocationData: flow(function* _(entityId: string) {
      const {
        api: { clientLocationDatas },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield clientLocationDatas.fetchOneClientLocationData(entityId);
        self.clientLocationData = result;

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    clearClientLocationData() {
      self.clientLocationData = null;
    },
  }))
  .actions((self) => ({
    updateClientLocationDatas: flow(function* _(clientLocationId: string, entityId: string, data: any) {
      const {
        api: { clientLocationDatas },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield clientLocationDatas.updateClientLocationDatas(entityId, data);
        self.findClientLocationDatas(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    uploadDocument: flow(function* _(clientLocationId: string, entityId: string, files: FileList) {
      const {
        api: { clientLocationDatas },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield clientLocationDatas.uploadDocument(entityId, files);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteDocument: flow(function* _(entityId: string) {
      const {
        api: { clientLocationDatas },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield clientLocationDatas.deleteDocument(entityId);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteClientLocationData: flow(function* _(clientLocationId: string, entityId: string) {
      const {
        api: { clientLocationDatas },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield clientLocationDatas.deleteClientLocationData(entityId);
        self.findClientLocationDatas(clientLocationId);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    createClientLocationData: flow(function* _(clientLocationId: string, data: any) {
      const {
        api: { clientLocationDatas },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield clientLocationDatas.createClientLocationData(data);
        self.findOneClientLocationData(result.id);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
  }))
  .views(() => ({}));

export const clientLocationDatasStore = ClientLocationDatasStore.create({
  clientLocationDatas: [],
  clientLocationData: null,
});
