import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";

import { useApi } from '../../hooks/useApi';

interface UserFormProps {
  form: UseFormReturn;
}

interface FormInput {
  name: string;
  type: string;
  placeholder: string;
  label: string;
  validate: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

const inputs: FormInput[] = [
  {
    name: "user.email",
    type: "email",
    placeholder: "Email de l'user",
    label: "Email",
    requiredIf: () => true,
    validate: () => true,
  },
  {
    name: "user.firstName",
    type: "text",
    placeholder: "Prénom",
    label: "Prénom",
    requiredIf: () => false,
    validate: () => true,
  },
  {
    name: "user.lastName",
    type: "text",
    placeholder: "Nom",
    label: "Nom",
    requiredIf: () => false,
    validate: () => true,
  },
  {
    name: "user.phoneNumber",
    type: "text",
    placeholder: "Téléphone",
    label: "Téléphone",
    validate: (val: string) => isValidPhoneNumber(val, "FR"),
    requiredIf: () => true,
  },
];

export const AdminForm = (props: UserFormProps) => {
  const { form } = props;
  const data = form.watch();

  const { users: usersApi } = useApi();
  const requestAdminPassword = useCallback(() => {
    if (data.id) {
      usersApi
        .requestPasswordAdmin({
          email: data.user.email,
        })
        .then((data: any) => {
          window.open(data.resetPasswordLink, '_blank');
        });
    }
  }, [data]);

  return (
    <div className="px-6">
      {inputs.map((input) => (
        <div key={input.name} className="w-full">
          <label htmlFor={input.name} className="label">
            <span className="label-text">{input.label}</span>
          </label>
          <input
            {...form.register(input.name, {
              validate: (val: any) => {
                if (!input.validate(val)) {
                  form.setValue("error", `${input.label} non valide`);
                } else {
                  form.setValue("error", "");
                }
                return input.validate(val);
              },
            })}
            type={input.type}
            placeholder={input.placeholder}
            required={input.requiredIf && input.requiredIf(data)}
            className="w-full input input-bordered"
          />
        </div>
      ))}
      {data.id && (
        <div className="w-full">
          <label className="label">
            <span className="label-text">Mot de passe</span>
          </label>
          <button
            type="button"
            className="normal-case border-none btn btn-sm bg-error"
            onClick={() => {
              requestAdminPassword();
            }}
          >
            Changer le mot de passe
          </button>
        </div>
      )}
      {data.error && <p className="text-sm text-error">{data.error}</p>}
    </div>
  );
};
