import { ClientLocationObject } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

import { ContactModel } from "src/modules/models/contact.model";

export const ClientLocationContactModel = types.model("ClientLocationContactModel", {
  id: types.identifier,
  contact: types.frozen(ContactModel),
  clientLocation: types.frozen<ClientLocationObject>(),
});

export type ClientLocationContactModelType = Instance<typeof ClientLocationContactModel>;
