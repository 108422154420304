import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { useStore } from "../../context/store.context";
import { ProblemItem } from "./ProblemItem";


interface ProblemsProps {
  agentId: string;
}
export const Problems = observer(({ agentId }: ProblemsProps) => {
  const {
    problems: { problems, findProblems },
  } = useStore();

  useEffect(() => {
    findProblems(`s={"$and": [{"agentId": "${agentId}"}]}`);
  }, []);

  return (
    <div className="mt-5 mb-auto">
      <div className="container m-auto">
        {problems && problems.length > 0 ?
          problems.map((problem: any) => {
            return <ProblemItem key={problem.id} problem={problem} />;
          }) : <div className="flex justify-center">Aucun signalement</div>}
      </div>
    </div>
  );
});
