import { DateTime } from "luxon";

export const getDiffInMinutesAndHours = (time1: DateTime | null, time2: DateTime | null) => {
  if (!time1 || !time2) return "N/A";
  const diff = time1?.diff(time2,['hours', 'minutes']).toObject();
  let h = diff.hours || 0;
  if(diff.minutes && Math.ceil(diff.minutes) == 60) h = h +1;
  const minutes = !diff.minutes || Math.ceil(diff.minutes) == 60 ? "00" : Math.ceil(diff.minutes);
  const hours = h === 0 ? "0" : Math.ceil(h);
  return `${hours}:${padTo2Digits(minutes)}`;
};

function padTo2Digits(num: any) {
  return num.toString().padStart(2, '0');
}
