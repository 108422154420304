import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MeetingModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useRef } from "react";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";

interface PropsMeeting {
  agentId: string;
  meetings: MeetingModelType[];
}

export const MeetingList = observer(({ agentId, meetings }: PropsMeeting) => {
  const { meetings: meetingsApi } = useApi();
  const {
    agents: { fetchEntity },
  } = useStore();

  const onDelete = async (data: MeetingModelType) => {
    const archive = {
      status: true,
    };
    await meetingsApi.updateMeeting(data.id, archive);
    fetchEntity(agentId);
  };
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  return (
    <div className="overflow-x-auto">
      <DataGridDelete
        ref={deleteRef}
        onDelete={onDelete}
        title="Supprimer un rendez-vous"
        description={() => `Êtes vous sur de vouloir archiver ce rendez-vous?`}
      />
      <table className="table w-full mt-5 mb-5">
        <tbody>
          
          { meetings && meetings.length > 0 ? meetings.map((meeting: MeetingModelType) => {
            return (
              !meeting.status && (
                <tr key={meeting.id}>
                  <td>{meeting.createdAt.toString().substring(0, 10)}</td>
                  <td className="flex justify-end">
                    <button
                      onClick={() => {
                        deleteRef.current && deleteRef.current.open(meeting);
                      }}
                      type="button"
                      title="Delete"
                      className="justify-end text-error hover:text-error-active"
                    >
                      <FontAwesomeIcon icon={faArchive} />
                    </button>
                  </td>
                </tr>
              )
            );
          }): <div className="flex justify-center">Aucun RDV</div>}
        </tbody>
      </table>
      <div tabIndex={0} className="w-full collapse collapse-arrow border-base-300 bg-base-100 rounded-box">
        <input type="checkbox" />

        <div className="text-lg font-medium collapse-title">Demande de rendez-vous archivé</div>
        <div className="overflow-scroll collapse-content h-52">
          <table className="table w-full">
            {meetings.map((meeting: MeetingModelType) => {
              return (
                meeting.status && (
                  <tr key={meeting.id}>
                    <td>{meeting.createdAt.toString().substring(0, 10)}</td>
                  </tr>
                )
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
});
