import { Instance, types } from "mobx-state-tree";

import { DateTimeType } from "src/modules/models/date-time.model";
import { UserModel } from 'src/modules/models/user.model';

export const AdminModel = types.model("AdminModel", {
  id: types.identifier,
  user: types.frozen(UserModel),
  createdAt: DateTimeType,
  updatedAt: DateTimeType,
});

export type AdminModelType = Instance<typeof AdminModel>;
