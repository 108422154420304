export enum OperationTypeEnum {
  WINDOWS = "WINDOWS",
  CLEANING = "CLEANING",
  GARBAGE = "GARBAGE",
  SMALL_WORKS = "SMALL_WORKS",
  MOQUETTE = "MOQUETTE",
  KARCHER = "KARCHER",
  SUNSET = "SUNSET",
  OTHER = "OTHER"
}
