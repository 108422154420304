import { faCirclePlus, faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UrgentInstructionModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useStore } from "../../context/store.context";
import { DataGridDelete } from "../DataGrid/DataGridDelete";
import { UrgentInstructionItem } from "./UrgentInstructionItem";

export const UrgentInstruction = observer(() => {
  const { clientLocationId } = useParams();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [isNewEntry, setIsNewEntry] = useState(false);

  const {
    urgentInstructions: {
      urgentInstructions,
      urgentInstruction,
      deleteUrgentDocument,
      uploadUrgentDocument,
      updateUrgentInstructions,
      createUrgentInstruction,
      findUrgentInstructions,
      findUrgentInstruction,
      deleteUrgentInstruction,
      clearUrgentInstruction,
    },
  } = useStore();

  useEffect(() => {
    if (clientLocationId) {
      findUrgentInstructions(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
      clearUrgentInstruction();
    }
  }, [clientLocationId]);

  const onSubmit = async (dataForm: any) => {
    if (dataForm.text && dataForm.title) {
      const data = {
        text: dataForm.text,
        title: dataForm.title,
        clientLocationId: clientLocationId,
      };

      if (clientLocationId) {
        if (!urgentInstruction) {
          await createUrgentInstruction(clientLocationId, data);
          await findUrgentInstructions(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
          setValue("title", "");
          setValue("text", "");
        } else {
          await updateUrgentInstructions(clientLocationId, urgentInstruction.id, data);
          await findUrgentInstructions(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
          setValue("title", "");
          setValue("text", "");
        }
      }
      setIsNewEntry(!isNewEntry);
      clearUrgentInstruction();
    }
  };

  const uploadFile = async (files: FileList) => {
    const item = {
      text: "",
      clientLocationId: clientLocationId,
    };
    if (clientLocationId) {
      if (urgentInstruction == null) {
        const newInstruction = await createUrgentInstruction(clientLocationId, item);
        await uploadUrgentDocument(clientLocationId, newInstruction.id, files);
        await findUrgentInstruction(newInstruction.id);
      } else {
        await uploadUrgentDocument(clientLocationId, urgentInstruction.id, files);
        await findUrgentInstruction(urgentInstruction.id);
      }
    }
  };

  const onDeleteDocument = async (entityId: string) => {
    if (urgentInstruction) {
      await deleteUrgentDocument(entityId);
      findUrgentInstruction(urgentInstruction.id);
    }
  };
  const cancel = async () => {
    if (clientLocationId && urgentInstruction) {
      await deleteUrgentInstruction(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`, urgentInstruction.id);
    }
    setIsNewEntry(!isNewEntry);
    clearUrgentInstruction();
    setValue("title", "");
    setValue("text", "");
  };

  const deleteRefDocument = useRef<ElementRef<typeof DataGridDelete>>(null);

  const vidRef = useRef(null);

  return (
    <div className="mt-5 mb-auto">
      <DataGridDelete
        ref={deleteRefDocument}
        onDelete={onDeleteDocument}
        title="Supprimer un document"
        description={() => `Êtes vous sur de vouloir supprimer ce document?`}
      />
      <div className="container m-auto">
        <div className="border collapse border-base-300 bg-base-100">
          <div className="text-xl font-medium text-center collapse-title">Consignes urgentes</div>
        </div>
        {urgentInstructions &&
          urgentInstructions.map((urgentInstruction: UrgentInstructionModelType) => {
            return <UrgentInstructionItem key={urgentInstruction.id} instruction={urgentInstruction} />;
          })}
        {isNewEntry && (
          <div tabIndex={0} className="border collapse collapse-open border-base-300 bg-base-100">
            <input type="checkbox" />
            <div className="flex justify-between text-lg font-medium collapse-title">
              <div>Nouvelle consigne</div>
            </div>
            <div className="collapse-content">
              <form onSubmit={handleSubmit(onSubmit)}>
                {errors.title?.type === "required" && <div className="text-error">Champs obligatoire pour créer une consigne</div>}

                <div className="flex flex-row space-x-2 ">
                  <div className="flex flex-col basis-2/4">
                    <Controller
                      control={control}
                      name={"title"}
                      rules={{ required: true }}
                      render={({ field: { onChange, value = "" } }) => (
                        <input
                          type="text"
                          className={
                            "w-full mt-2 flex-full input input-bordered " + (errors.title?.type === "required" ? "border-error" : "")
                          }
                          value={value}
                          onChange={onChange}
                          placeholder={"Titre"}
                        />
                      )}
                    />
                    {errors.text?.type && <div className="text-error">Champs obligatoire pour créer une consigne</div>}

                    <Controller
                      control={control}
                      name={"text"}
                      render={({ field: { onChange, value = "" } }) => (
                        <textarea
                          className={
                            "w-full h-full mt-2 flex-full textarea input input-bordered " +
                            (errors.text?.type === "required" ? "border-error" : "")
                          }
                          value={value}
                          onChange={onChange}
                          placeholder={"Description de la consigne"}
                        />
                      )}
                    />
                  </div>
                  {urgentInstruction &&
                    urgentInstruction.documents &&
                    urgentInstruction.documents?.length != 0 &&
                    urgentInstruction.documents.map((document: any) => {
                      return (
                        <div key={document.id}>
                          {document.document.key != null && (
                            <div>
                              <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100">
                                <label className="w-20 rounded" htmlFor={"modal-" + document.document.id}>
                                  {document.document.extension == "mp4" ? (
                                    <video ref={vidRef}>
                                      <source src={document.document.key} type="video/mp4" />
                                    </video>
                                  ) : document.document.extension == "pdf" ? (
                                    <div className="flex justify-center m-auto">
                                      <div>
                                        <FontAwesomeIcon className="fa-3x" icon={faFilePdf} />
                                      </div>
                                    </div>
                                  ) : (
                                    <img src={document.document.key} />
                                  )}

                                  <label
                                    className="absolute top-0 right-0 border-none btn btn-sm btn-circle bg-error"
                                    onClick={() => {
                                      deleteRefDocument.current && deleteRefDocument.current.open(document.id);
                                    }}
                                  >
                                    ✕
                                  </label>
                                </label>
                              </div>

                              <input type="checkbox" id={"modal-" + document.document.id} className="modal-toggle" />
                              <div className="modal">
                                <div className="relative modal-box">
                                  <label htmlFor={"modal-" + document.document.id} className="absolute btn btn-sm btn-circle right-2 top-2">
                                    ✕
                                  </label>

                                  <div className="flex justify-center">
                                    {document.document.extension == "mp4" ? (
                                      <video ref={vidRef} controls>
                                        <source src={document.document.key} type="video/mp4" />
                                      </video>
                                    ) : document.document.extension == "pdf" ? (
                                      <a href={document.document.key}>Download Pdf</a>
                                    ) : (
                                      <img src={document.document.key} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}

                  <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100 hover:text-blue-500 hover:border-blue-500 hover:border">
                    <label className="flex w-5 m-auto rounded">
                      <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
                      <input
                        type="file"
                        accept="image/*,video/mp4,application/pdf"
                        id="file1"
                        className="hidden"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.files) {
                            uploadFile(event.target.files);
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    type="button"
                    className="mr-2 normal-case border-none btn btn-sm bg-error"
                    onClick={() => {
                      cancel();
                    }}
                  >
                    Annuler
                  </button>
                  <button className="normal-case border-none btn btn-sm bg-success" type="submit">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        <div tabIndex={0} className="border cursor-pointer collapse border-base-300 bg-base-100 hover:text-blue-500" onClick={() => setIsNewEntry(!isNewEntry)}>
          <div className="flex justify-between text-xl font-medium collapse-title">
            <div className="flex w-5 m-auto">
              <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
