import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../context/store.context";
import { dateToFrench } from "../../utils/dateToFrench";

export const MyAccount = observer(() => {
  const {
    admins: { currentAdmin },
  } = useStore();
  const navigate = useNavigate();
  return (
    <div className="flex-1">
      <div className="flex justify-center m-auto">
        <div className="justify-center w-full max-w-2xl bg-white rounded-lg">
          <div className="p-4 border-b">
            <h2 className="text-2xl text-center">Mon compte</h2>
          </div>
          <div>
            <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
              <p className="text-gray-600">Nom</p>
              <div>
                <p className="font-semibold text-left">{currentAdmin?.user.lastName}</p>
              </div>
            </div>
            <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
              <p className="text-gray-600">Prénom</p>
              <p className="font-semibold text-left">{currentAdmin?.user.firstName}</p>
            </div>
            <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
              <p className="text-gray-600 ">Email</p>
              <p className="font-semibold text-left">{currentAdmin?.user.email}</p>
            </div>
            <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
              <p className="text-gray-600 ">Téléphone bureau</p>
              <p className="font-semibold text-left">{currentAdmin?.user.phoneNumber}</p>
            </div>
            {currentAdmin?.user.dateOfBirth && (
              <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                <p className="text-gray-600 ">Date d&apos;anniversaire</p>
                <p className="font-semibold text-left">{dateToFrench(currentAdmin?.user.dateOfBirth?.toString())}</p>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-10">
            <button onClick={() => navigate("/settings")} type="button" className="btn btn-sm btn-outline btn-primary">
              Changer mon mot de passe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
