import { faCheck, faPen, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientLocationContactModelType, UserRoleEnum } from "@platinium/cdk";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { dateToFrench } from "../../utils/dateToFrench";
import { Loader } from "../Loader/Loader";

interface FormData {
  city: string;
  civility: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneNumberPerso: string;
  fonction: string;
  dateOfBirth: any;
}
interface ContactProps {
  contactId?: string;
  clientLocationId: string;
  openContacts: () => void;
}

export const Contact = observer(({ clientLocationId, openContacts }: ContactProps) => {
  const [isInput, setIsInput] = useState(false);
  const [contactTable, setContactTable] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectOption, setSelectOption] = useState("");
  const [contactSelected, setContactSelected] = useState();
  const [clientLocationContact, setClientLocationContact] = useState<ClientLocationContactModelType>();
  const { users: usersApi } = useApi();
  const [error, setError] = useState(null);
  const {
    contacts: { contacts, findContacts, updateContact, createContact },
  } = useStore();
  const { clientLocationContacts: clientLocationContactsApi } = useApi();
  const { control, handleSubmit, setValue, register } = useForm<FormData>();

  useEffect(() => {
    (async () => {
      await getContact();
      const clientLocationContact = await clientLocationContactsApi.fetchOneClientLocationContact(
        `?s={"$and": [{"clientLocationId":"${clientLocationId}", "isMainContact":"TRUE"}]}`
      );
      setClientLocationContact(clientLocationContact.data[0]);
      setIsLoading(true);
    })();
  }, []);

  useEffect(() => {
    if (clientLocationContact?.contact.user.firstName) setValue("firstName", clientLocationContact?.contact.user.firstName);
    if (clientLocationContact?.contact.user.lastName) setValue("lastName", clientLocationContact?.contact.user.lastName);
    if (clientLocationContact?.contact.user.email) setValue("email", clientLocationContact?.contact.user.email);
    if (clientLocationContact?.contact.user.phoneNumber) setValue("phoneNumber", clientLocationContact?.contact.user.phoneNumber);
    if (clientLocationContact?.contact.phoneNumberPerso) setValue("phoneNumberPerso", clientLocationContact?.contact.phoneNumberPerso);
    if (clientLocationContact?.contact.fonction) setValue("fonction", clientLocationContact?.contact.fonction);
    if (clientLocationContact?.contact.civility) setValue("civility", clientLocationContact?.contact.civility);
    if (clientLocationContact?.contact.user.dateOfBirth) {
      setValue("dateOfBirth", clientLocationContact?.contact.user.dateOfBirth.toString().substring(0, 10));
    } else {
      setValue("dateOfBirth", "");
    }
  }, [clientLocationContact]);

  const onSubmit = async (dataForm: FormData) => {
    if (selectOption == "nocreate") {
      const data = {
        clientLocationId: clientLocationId,
        contactId: contactSelected,
        isMainContact: true,
      };
      const clientLocationContact = await clientLocationContactsApi.fetchOneClientLocationContact(
        `?s={"$and": [{"clientLocationId":"${clientLocationId}", "isMainContact":"true"}]}`
      );
      if (clientLocationContact.data.length > 0) {
        await clientLocationContactsApi.deleteClientLocationContact(clientLocationContact.data[0].id);
      }
      const newClientLocation = await clientLocationContactsApi.createClientLocationContact(data);
      setClientLocationContact(newClientLocation);
    } else if (clientLocationContact) {
      const user = {
        email: dataForm.email,
        firstName: dataForm.firstName,
        lastName: dataForm.lastName,
        phoneNumber: dataForm.phoneNumber,
        dateOfBirth: dataForm.dateOfBirth,
      };
      const data = {
        civility: dataForm.civility,
        phoneNumberPerso: dataForm.phoneNumberPerso,
        fonction: dataForm.fonction,
      };
      await usersApi.updateUser(clientLocationContact.contact.user.id, { ...pickBy(user) });
      await updateContact(clientLocationContact.contact.id, data);
      const MainContact = await clientLocationContactsApi.fetchOneClientLocationContact(
        `?s={"$and": [{"clientLocationId":"${clientLocationId}", "isMainContact":"true"}]}`
      );
      setClientLocationContact(MainContact.data[0]);
    } else {
      if (selectOption == "create") {
        const user = {
          email: dataForm.email,
          firstName: dataForm.firstName,
          lastName: dataForm.lastName,
          phoneNumber: dataForm.phoneNumber,
          role: UserRoleEnum.CLIENT,
          dateOfBirth: dataForm.dateOfBirth,
        };

        const userCreated = await usersApi
          .createUser({ ...pickBy(user) })
          .then((result: any) => {
            return result;
          })
          .catch((err: any) => {
            setError(err.response.data.message);
          });
        const data = {
          civility: dataForm.civility,
          phoneNumberPerso: dataForm.phoneNumberPerso,
          fonction: dataForm.fonction,
          userId: userCreated.id,
        };
        const newContact = await createContact(data);
        const clientLocationContactData = {
          clientLocationId: clientLocationId,
          contactId: newContact.id,
          isMainContact: true,
        };
        const newClientLocation = await clientLocationContactsApi.createClientLocationContact(clientLocationContactData);
        setClientLocationContact(newClientLocation);
        setError(null);
      }
    }
    setIsInput(!isInput);
  };

  const onChangeValue = (e: any) => {
    if (e.target.value == "nocreate" || e.target.value == "create") {
      setSelectOption(e.target.value);
    }
  };

  const handleChangeSelected = (optionSelected: any) => {
    setContactSelected(optionSelected.value);
  };

  const getContact = async () => {
    await findContacts();
    const contactList: any[] = [];
    if (contacts && contacts.data) {
      contacts.data.map((contact) => {
        if (contact) {
          const a = { value: contact.id, label: contact.user.firstName + " " + contact.user.lastName };
          contactList.push(a);
        }
      });
      setContactTable(contactList);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="px-1 mt-5 flex-1 sm:px-6 lg:px-8 max-w-7xl">
          {/* -- Contact -- */}
          <div className="w-full p-5 pt-6 shadow-xl card card-bordered bg-base-100">
            <div className="">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex justify-between">
                  <div></div>
                  <h2 className="justify-center card-title">Contact principal</h2>
                  {!isInput ? (
                    <button
                      onClick={() => {
                        setIsInput(!isInput);
                        getContact();
                      }}
                      type="button"
                      title="Edit"
                      className="pl-4 hover:text-blue-500"
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                  ) : (
                    <div className="flex justify-content">
                      <button
                        onClick={() => {
                          setIsInput(!isInput);
                        }}
                        type="button"
                        title="Edit"
                        className="pl-4 hover:text-error"
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                      <button type="submit" title="Edit" className="pl-4 hover:text-green-500">
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </div>
                  )}
                </div>
                {isInput ? (
                  <div onChange={onChangeValue} className="card-body">
                    <label className="justify-start cursor-pointer label">
                      <input
                        type="radio"
                        name="radio"
                        value="nocreate"
                        className="m-2 radio radio-sm"
                        checked={selectOption === "nocreate"}
                      />

                      <span>Selectionner un contact existant</span>
                    </label>

                    <Select
                      options={contactTable}
                      onChange={(e) => {
                        handleChangeSelected(e);
                        setSelectOption("nocreate");
                      }}
                      className="text-sm"
                    />
                    <label className="justify-start cursor-pointer label">
                      <input type="radio" name="radio" value="create" className="m-2 radio radio-sm" checked={selectOption === "create"} />
                      <span>{clientLocationContact ? "Modifier le contact" : "Créer un nouveau contact"}</span>
                    </label>
                    {error && <label className="text-error">{error}</label>}
                    <div onClick={() => setSelectOption("create")} className="space-y-1">
                      <select className="items-center w-full select select-bordered" {...register("civility")}>
                        <option disabled selected>
                          Civilité
                        </option>
                        <option value={"Mr"}>M</option>
                        <option value={"Mme"}>Mme</option>
                      </select>
                      <div className="flex space-x-1">
                        <Controller
                          control={control}
                          name={"firstName"}
                          render={({ field: { onChange, value = "" } }) => (
                            <input
                              type="text"
                              value={value}
                              onChange={onChange}
                              placeholder={"Prénom"}
                              className="w-full input input-bordered"
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={"lastName"}
                          render={({ field: { onChange, value = "" } }) => (
                            <input
                              type="text"
                              value={value}
                              onChange={onChange}
                              placeholder={"Nom"}
                              className="w-full input input-bordered"
                            />
                          )}
                        />
                      </div>
                      <Controller
                        control={control}
                        name={"email"}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"Email"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={"phoneNumber"}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"Numéro de téléphone fixe"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={"phoneNumberPerso"}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"Numéro de téléphone perso"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={"fonction"}
                        render={({ field: { onChange, value = "" } }) => (
                          <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={"Fonction"}
                            className="w-full input input-bordered"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={"dateOfBirth"}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <input
                            type="date"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder={"Date de naissance"}
                            className="items-center w-full input input-bordered"
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="justify-center w-full max-w-2xl bg-white rounded-lg card-body">
                    <div>
                      <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                        <p className="text-gray-600">Nom</p>
                        <div>
                          <p className="font-semibold text-left">
                            {clientLocationContact?.contact && clientLocationContact?.contact.civility != null
                              ? clientLocationContact.contact.civility + " "
                              : ""}
                            {clientLocationContact?.contact != null
                              ? clientLocationContact?.contact.user.firstName + " " + clientLocationContact?.contact.user.lastName
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                        <p className="text-gray-600 ">Email</p>
                        <p className="font-semibold text-left">
                          {clientLocationContact?.contact != null ? "" + clientLocationContact.contact.user.email : ""}
                        </p>
                      </div>
                      <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                        <p className="text-gray-600 ">Téléphone bureau</p>
                        <p className="font-semibold text-left">
                          {clientLocationContact?.contact.user.phoneNumber != null
                            ? "" + clientLocationContact.contact.user.phoneNumber
                            : ""}
                        </p>
                      </div>
                      <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                        <p className="text-gray-600 ">Téléphone portable</p>
                        <p className="font-semibold text-left">
                          {" "}
                          {clientLocationContact?.contact.phoneNumberPerso != null
                            ? "" + clientLocationContact.contact.phoneNumberPerso
                            : ""}
                        </p>
                      </div>
                      <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                        <p className="text-gray-600 ">Fonction</p>
                        <p className="font-semibold text-left">
                          {clientLocationContact?.contact.fonction != null ? clientLocationContact.contact.fonction : ""}
                        </p>
                      </div>

                      <div className="p-4 space-y-1 md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                        <p className="text-gray-600 ">Date d&apos;anniversaire</p>
                        <p className="font-semibold text-left">
                          {" "}
                          {clientLocationContact?.contact.user.dateOfBirth
                            ? dateToFrench(clientLocationContact?.contact.user.dateOfBirth?.toString())
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </form>
              <div className="flex justify-center pb-5">
                <button className="w-96 btn btn-sm" onClick={() => openContacts()}>
                  Voir tous les contacts
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
