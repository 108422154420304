export enum ClientLocationDataTypeEnumFR {
  KEYS = "Clés",
  CONSUMABLE = "Consommable",
  LOCKS = "Serrure",
  ELECTRICITY = "Lumière",
  GARBAGES = "Poubelles",
  CLEANING_PRODUCTS = "Matériels et produits",
  ACCESS_CODE = "Code d'accès",
  ALARM = "Alarme",
  SUBWAY = "Métro",
  PRODUCTS = "Produits",
  QRCODE = "Qr code",
  ADDRESS = "Adresse",
}
