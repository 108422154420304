/* eslint-disable @typescript-eslint/no-explicit-any */
import { faFile, faFileLock, faLoader, faPen, faTrashAlt, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OperationModelType, OperationStatusEnum, OperationStatusEnumTranslations, OperationTypeEnum, OperationTypeEnumTranslations } from '@platinium/cdk';
import clsx from 'clsx';
import { cloneDeep, pick } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useRef, useState } from "react";
import Select from 'react-select';

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridForm } from "../../components/DataGrid/DataGridForm";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from "../../components/Loader/Loader";
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";
import { OperationForm, operationStatusOptions } from "./OperationForm";

const columns = [
  {
    label: "Date",
    name: "createdAt",
  },
  {
    label: "Nom du client",
    name: "clientName",
  },
  {
    label: "Statut",
    name: "status",
  },
  {
    label: "Type",
    name: "type",
  },
  {
    label: "",
    name: "actions",
  },
];

type DownloadIconProps = {
  report?: "private" | "public",
  operationId: string,
  icon: IconDefinition,
  title: string
};

const defaultOperationValues = {
  status: OperationStatusEnum.CREATED,
  clientName: null,
  clientContactName: null,
  adressLine1: null,
  adressLine2: null,
  postalCode: null,
  city: null,
  type: OperationTypeEnum.OTHER,
  accessInformations: null,
  requiredEquipments: null,
  isRequestingManager: false,
  agentsCount: 0,
  requestInformations: null,
  isBillable: false,
  operatingTime: null,
  privateResult: null,
  publicResult: null,
  completedOperationDate: null,
  requestedOperationDate: null,
  plannedOperationDate: null,
};

export const DownloadIcon = observer((props: DownloadIconProps) => {
  const {
    report = "public",
    operationId,
    icon,
    title
  } = props;
  const { operations: operationsApi } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  return <button
    onClick={async (e) => {
      setIsLoading(true);
      try {
        e.preventDefault();
        e.stopPropagation();
        const operationReport = report === "private"
          ? await operationsApi.generatePrivateOperationReport(operationId)
          : await operationsApi.generatePublicOperationReport(operationId);
        const link = document.createElement("a");
        link.href = operationReport.signedUrl;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }}
    disabled={isLoading}
    type="button"
    title={title}
    className="hover:text-blue-500"
  >
    <FontAwesomeIcon className={clsx({ "animate-spin": !!isLoading })} icon={isLoading ? faLoader : icon} />
  </button>;
});

export const Operations = observer(() => {
  const { operations: operationsStore, admins: { currentAdmin } } = useStore();
  const { operations: operationsApi } = useApi();

  const [statusOption, setStatusOption] = useState<string | null>(null);
  const {
    search,
    setSearch,
    entities: operations,
    pages,
    currentPage,
    setCurrentPage,
    ordering,
    setOrdering,
  } = useDataGrid({
    initialOrdering: { "createdAt": "DESC" },
    findFunction: operationsStore.findOperations,
    searchBuilder: useCallback((search) => ({
      $and: [
        {
          $or: [
            {
              clientName: {
                $contL: search,
              },
            },
            {
              clientContactName: {
                $contL: search,
              },
            },
            {
              type: {
                $contL: search,
              },
            },
          ],
        },
        ...(statusOption
          ? [
            {
              status: {
                $contL: statusOption,
              },
            },
          ]
          : []),
      ]
    }), [statusOption]),
  });

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onSubmitForm = useCallback(
    async (data: any) => {
      const payload = {
        ...pick(data, [
          "status",
          "clientName",
          "clientContactName",
          "adressLine1",
          "adressLine2",
          "postalCode",
          "city",
          "type",
          "accessInformations",
          "isRequestingManager",
          "agentsCount",
          "requestInformations",
          "isBillable",
          "operatingTime",
          "privateResult",
          "publicResult",
          "completedOperationDate",
          "requestedOperationDate",
          "plannedOperationDate",
          "requiredEquipments"
        ]),
        agentsCount: parseInt(data.agentsCount),
        operatingTime: parseInt(data.operatingTime),
        ownerId: currentAdmin?.user.id
      };

      if (data.id) {
        await operationsApi.updateOperation(data.id, payload);
      } else {
        await operationsApi.createOperation(payload);
      }
      formRef.current?.close();
      operations.reload();
    },
    [operations]
  );

  const onDelete = useCallback(
    async (data: OperationModelType) => {
      await operationsApi.deleteOperation(data.id);
      operations.reload();
    },
    [operations, operationsApi]
  );

  const cancel = (form: any) => {
    if (form.id) {
      operationsApi.deleteOperation(form.id);
    }
  };

  return (
    <>
      {operations.data ? (
        <div className="flex-1">
          <DataGridForm
            ref={formRef}
            title={(form) => {
              const data = form.getValues();
              return data.id ? `Modifier une opération` : "Créer une opération";
            }}
            onSubmit={onSubmitForm}
            cancel={cancel}
            closeOnSubmit={false}
            submitLabel='VALIDER'
            defaultValues={defaultOperationValues}
          >
            {(form) => <OperationForm form={form}></OperationForm>}
          </DataGridForm>

          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer une opération"
            description={() => "Êtes-vous sûr de vouloir supprimer cette opération"}
          />
          <div className="h-full flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl overflow-hidden">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader
                searchPlaceholder="Chercher une opération par son client"
                search={search}
                onSearchChange={setSearch}
                actions={
                  <button onClick={() => formRef.current && formRef.current.open()} type="button" className="btn btn-sm">
                    Nouvelle opération
                  </button>
                }
              />
              <div className="flex justify-end m-2">
                <Select
                  className="w-52 z-50"
                  closeMenuOnSelect={true}
                  placeholder="Filtrer par statut"
                  onChange={(data: any) => {
                    setStatusOption(data && data.value);
                    setCurrentPage(1);
                  }}
                  options={operationStatusOptions}
                  value={statusOption && (operationStatusOptions.find(operationType => operationType.value === statusOption) || { value: statusOption, label: statusOption })}

                  isClearable={true}
                />
              </div>
              <div className="overflow-x-auto">

                <DataGridTable dataProvider={operations} columns={columns} ordering={ordering} onOrderingChange={setOrdering}>
                  {(data) =>
                    data.data.map((operation: OperationModelType) => {
                      return (
                        <tr
                          key={operation.id}
                          className="cursor-pointer hover"
                          onClick={() => {
                            formRef.current && formRef.current.open(cloneDeep(operation));
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">{operation.createdAt?.toFormat('f', {
                            locale: 'fr',
                          }) || "N/A"}</th>
                          <th className="text-sm font-medium whitespace-nowrap">{operation.clientName || "N/A"}</th>
                          <th className="text-sm font-medium whitespace-nowrap">{(operation.status && (OperationStatusEnumTranslations as any)[operation.status]) || operation.status || "N/A"}</th>
                          <th className="text-sm font-medium whitespace-nowrap">{(operation.type && (OperationTypeEnumTranslations as any)[operation.type]) || operation.type || "N/A"}</th>
                          <th className="space-x-2 text-sm font-medium text-right">
                            <DownloadIcon report="private" icon={faFileLock} title="Télécharger le rapport interne" operationId={operation.id} />
                            <DownloadIcon report="public" icon={faFile} title="Télécharger le rapport externe" operationId={operation.id} />
                            <button
                              onClick={() => {
                                formRef.current && formRef.current.open(cloneDeep(operation));
                              }}
                              type="button"
                              title="Edit"
                              className="hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteRef.current && deleteRef.current.open(operation);
                              }}
                              type="button"
                              title="Delete"
                              className="text-error hover:text-error-active"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {operations.data && operations.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{operations.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
