import { UserObject } from "@platinium/shared";
import { AxiosInstance } from "axios";

export interface ResetPasswordDto {
  email: string;
  password: string;
  resetPasswordToken: string;
}

export interface RequestPasswordDto {
  email: string;
}

export function createUserApi(axios: AxiosInstance) {
  return {
    async resetPassword(data: ResetPasswordDto) {
      return axios({
        url: "/users/reset-password",
        method: "POST",
        data,
      });
    },
    async requestPassword(data: RequestPasswordDto) {
      return axios({
        url: "/users/request-password",
        method: "POST",
        data,
      });
    },
    async updateUser(entityId: string, data: Partial<UserObject>): Promise<UserObject> {
      return axios({
        url: `/users/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async createUser(data: Partial<UserObject>): Promise<UserObject> {
      return axios({
        url: "/users",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async deleteUser(entityId: string): Promise<void> {
      return axios({
        url: `/users/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async requestPasswordAdmin(data: RequestPasswordDto) {
      return axios({
        url: "/users/request-password-admin",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
  };
}

export type UserApi = ReturnType<typeof createUserApi>;
