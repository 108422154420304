import React from "react";
import { BrowserRouter } from "react-router-dom";

import { StoreProvider } from "./context/store.context";
import { GlobalRouter } from "./routers/GlobalRouter";

function App() {
  return (
    <BrowserRouter>
      <StoreProvider>
        <GlobalRouter />
      </StoreProvider>
    </BrowserRouter>
  );
}

export default App;
