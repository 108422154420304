import { faSort, faSortDown, faSortUp, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaginatedModels } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import { IAnyType } from "mobx-state-tree";
import React, { ReactNode, useCallback } from "react";
import { AsyncState } from "react-async";

interface DataGridTableProps<ENTITY extends IAnyType> {
  columns: { name: string; label: string }[];
  children: (data: PaginatedModels<ENTITY>) => ReactNode;
  dataProvider: AsyncState<PaginatedModels<ENTITY>>;
  ordering: Record<string, "ASC" | "DESC">;
  onOrderingChange: (ordering: Record<string, "ASC" | "DESC">) => void;
  sortableColumns?: string[];
}

export const DataGridTable = observer(<ENTITY extends IAnyType>(props: DataGridTableProps<ENTITY>) => {
  const { columns, children, dataProvider, ordering, onOrderingChange, sortableColumns } = props;
  const onUpdateSorting = useCallback(
    (columnName: string, type: "ASC" | "DESC" | null) => {
      if (type === "ASC" || type === "DESC") {
        onOrderingChange({
          ...ordering,
          [columnName]: type,
        });
      } else {
        const updatedOrdering = {
          ...ordering,
        };
        delete updatedOrdering[columnName];
        onOrderingChange(updatedOrdering);
      }
    },
    [ordering, onOrderingChange]
  );
  return (
    <table className="table w-full table-compact">
      <thead>
        <tr>
          {columns &&
            columns.map((column) => (
              <th key={column.name}>
                {!ordering[column.name] && sortableColumns?.includes(column.name) && sortableColumns && (
                  <button type="button" onClick={() => onUpdateSorting(column.name, "ASC")}>
                    {column.label}
                    <FontAwesomeIcon className="invisible ml-2 group-hover:visible" icon={faSort} />
                  </button>
                )}
                {!ordering[column.name] && !sortableColumns && (
                  <button type="button" onClick={() => onUpdateSorting(column.name, "ASC")}>
                    {column.label}
                    <FontAwesomeIcon className="invisible ml-2 group-hover:visible" icon={faSort} />
                  </button>
                )}
                {ordering[column.name] === "ASC" && (
                  <button type="button" onClick={() => onUpdateSorting(column.name, "DESC")}>
                    {column.label}
                    <FontAwesomeIcon className="ml-2" icon={faSortUp} />
                  </button>
                )}
                {ordering[column.name] === "DESC" && (
                  <button type="button" onClick={() => onUpdateSorting(column.name, null)}>
                    {column.label}
                    <FontAwesomeIcon className="ml-2" icon={faSortDown} />
                  </button>
                )}
                {sortableColumns && !sortableColumns?.includes(column.name) && <div>{column.label}</div>}
              </th>
            ))}
        </tr>
      </thead>

      <tbody>
        {dataProvider.status === "initial" && (
          <tr>
            <td colSpan={columns.length + 1}>
              <div className="flex items-center justify-center h-10 overflow-hidden rounded-sm">
                <FontAwesomeIcon icon={faSpinnerThird} className="animation animate-spin" />
              </div>
            </td>
          </tr>
        )}
        {dataProvider.status === "rejected" && (
          <tr>
            <td colSpan={columns.length + 1}>
              <div className="flex items-center justify-center h-10 overflow-hidden text-white rounded-sm bg-error">
                Une erreur est survenue
              </div>
            </td>
          </tr>
        )}
        {dataProvider.data && !dataProvider.data.data.length && (
          <tr>
            <td colSpan={columns.length + 1}>
              <div className="flex items-center justify-center h-10 ">Aucun résultats</div>
            </td>
          </tr>
        )}
        {dataProvider.data && children(dataProvider.data)}
      </tbody>
      <tfoot>
        <tr>{columns && columns.map((column) => <th key={column.name}>{column.label}</th>)}</tr>
      </tfoot>
    </table>
  );
});
