import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusOrderEnum } from "@platinium/shared";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import BackButton from "../../assets/icons/back_button.svg";
import { Loader } from '../../components/Loader/Loader';
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { toStringLength } from "../../utils/utils";
import { ModalAddProduct } from "./AddProductModal";

/* Options for react Select */
const optionsOrderStatus = [
  {
    value: StatusOrderEnum.PROCESSING,
    label: "à traiter",
  },
  {
    value: StatusOrderEnum.SENT,
    label: "en livraison",
  },
  {
    value: StatusOrderEnum.RECEIVED,
    label: "livré",
  },
];

export const Order = observer(() => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [modalAddProductOpen, setModalAddProductOpen] = useState(false);
  const {
    orders: { fetchEntity, order, updateOrder, generateDeliveryOrder },
  } = useStore();

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [orderProducts, setOrderProducts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDeliveryOrder, setDeliveryOrder] = useState(false);

  const {
    orderProducts: { deleteOrderProduct, updateOrderProduct, fetchOrderProducts, createOrderProduct },
  } = useApi();

  /* Fetch the order with the given parameter */
  useEffect(() => {
    const fetchOrderData = async () => {
      if (orderId) {
        await fetchEntity(orderId);
        const orderProductsResponse = await fetchOrderProducts(`s={"orderId": "${orderId}"}`);
        setOrderProducts(orderProductsResponse);
        setIsLoading(true);
      }
    };
    fetchOrderData();
  }, [orderId]);

  /* Handle delete orderProduct */
  const handleDeleteOrderProduct = async (orderProductId: string) => {
    await deleteOrderProduct(orderProductId);
    const orderProductsUpdated = orderProducts.filter((orderProduct: any) => orderProduct.id !== orderProductId);
    setOrderProducts(orderProductsUpdated);
  };

  /* Handle update quantity orderProduct */
  const handleUpdateQuantityOrderProduct = async (orderProductId: string, newQuantity: number) => {
    await updateOrderProduct(orderProductId, { quantity: newQuantity });
    const orderProductsUpdated = orderProducts.map((orderProduct: any) => {
      if (orderProduct.id === orderProductId) {
        orderProduct.quantity = newQuantity;
      }
      return orderProduct;
    });
    setOrderProducts(orderProductsUpdated);
  };

  /* Create new orderProduct */
  const onSubmitNewProduct = async (data: any) => {
    const newOrderProduct = await createOrderProduct({
      productId: data.product.value,
      orderId,
      quantity: parseInt(data.quantity, 10),
    });

    const orderProductsUpdated = [...orderProducts, newOrderProduct];
    setOrderProducts(orderProductsUpdated);
    setModalAddProductOpen(false);
  };

  /* Handle change option on status select */
  const handleChangeSelected = (optionSelected: any) => {
    if (!order?.id) {
      return;
    }
    updateOrder(order.id, { status: optionSelected.value });
  };

  /* Value for status select */
  const orderStatus = useMemo(() => {
    return optionsOrderStatus.find((option) => option.value === order?.status);
  }, [JSON.stringify(order)]);

  /* Quantity of product being updated in real time when changing quantity on order product */
  const totalQuantityProducts = useMemo(() => {
    return orderProducts.reduce((total, product) => (total += product.quantity), 0);
  }, [orderProducts]);

  const openOrderDocument = (key: string) => {
    const link = document.createElement("a");
    link.href = key;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  /* Check if document exist already or generate new one then open in new tab the document */
  const handleClickDeliveryOrder = async () => {
    if (!order?.id) {
      return;
    }
    setLoadingDocument(true);
    const newOrder = await generateDeliveryOrder(order.id)
      .then((result) => {
        return result;
      })
      .catch(() => {
        setDeliveryOrder(true);
      });
    if (newOrder?.document?.key) {
      openOrderDocument(newOrder.document.key);
    }
    setLoadingDocument(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="w-3/5 mx-auto mt-10 flex-1">
          <div className="justify-between pt-5 pl-5 md:container md:mx-auto navbar w-36">
            <img src={BackButton} className="cursor-pointer" onClick={() => navigate(-1)} />
            <p className="text-xl font-bold text-center">
              Commande {order?.id ? toStringLength(order.id, 5) : "XXX"} - {order?.clientLocation.name}
            </p>
            <div></div>
          </div>
          <ModalAddProduct
            onSubmit={onSubmitNewProduct}
            modalAddProductOpen={modalAddProductOpen}
            setModalAddProductOpen={setModalAddProductOpen}
            orderProducts={orderProducts}
          />
          <div className="flex flex-row items-center pb-5 mt-10">
            <div className="basis-1/4">Changer le statut :</div>
            <Select
              className="w-full basis-3/4"
              styles={{
                menu: (provided: any, state: any) => {
                  const zIndex = state.selectProps.menuIsOpen ? 100 : 1;
                  return { ...provided, zIndex };
                },
              }}
              placeholder="Statut de la commande"
              value={orderStatus}
              onChange={(e) => handleChangeSelected(e)}
              options={optionsOrderStatus}
            />
          </div>

          <div className="w-full mt-4 overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Référence du produit</th>
                  <th>Désignation</th>
                  <th className="text-center">Quantité</th>
                  <th className="text-right">
                    <button
                      onClick={() => setModalAddProductOpen(true)}
                      type="button"
                      className="btn btn-sm"
                      disabled={orderStatus?.value != StatusOrderEnum.PROCESSING ? true : false}
                    >
                      +
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderProducts?.map((orderProduct: any) => {
                  return (
                    <tr key={orderProduct.id}>
                      <td>{orderProduct.product.reference}</td>
                      <td>{orderProduct.product.description}</td>
                      <td className="flex justify-center space-x-3">
                        {orderStatus?.value == StatusOrderEnum.PROCESSING && (
                          <button
                            onClick={() => handleUpdateQuantityOrderProduct(orderProduct.id, orderProduct.quantity - 1)}
                            type="button"
                          >
                            -
                          </button>
                        )}
                        <p>{orderProduct.quantity}</p>
                        {orderStatus?.value == StatusOrderEnum.PROCESSING && (
                          <button
                            onClick={() => handleUpdateQuantityOrderProduct(orderProduct.id, orderProduct.quantity + 1)}
                            type="button"
                            disabled={orderStatus?.value != StatusOrderEnum.PROCESSING ? true : false}
                          >
                            +
                          </button>
                        )}
                      </td>
                      <th className="text-right">
                        {orderStatus?.value == StatusOrderEnum.PROCESSING && (
                          <button
                            onClick={() => {
                              handleDeleteOrderProduct(orderProduct.id);
                            }}
                            type="button"
                            title="Delete"
                            className="text-error hover:text-error-active"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        )}
                      </th>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total produit</th>
                  <th></th>
                  <th className="text-base text-center">{totalQuantityProducts}</th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
          {errorDeliveryOrder && (
            <div className="mt-2 text-center text-error">Le site {order?.clientLocation.name} n&apos;a pas de contact principal</div>
          )}
          <div className="w-full mt-4 text-center">
            <button
              onClick={() => handleClickDeliveryOrder()}
              className={`mx-auto cursor-pointer btn btn-sm ${loadingDocument ? "loading" : ""}`}
            >
              Télécharger le bon de livraison
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
