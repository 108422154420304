/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgentObject } from "@platinium/shared";
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createClientApi(axios: AxiosInstance) {
  return {
    async fetchClients(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: "/clients",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneClient(entityId: string) {
      return axios({
        url: `/clients/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async createClient(data: Partial<AgentObject>): Promise<AgentObject> {
      return axios({
        url: "/clients",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async updateClient(entityId: string, data: Partial<any>) {
      return axios({
        url: `/clients/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async deleteClient(entityId: string) {
      return axios({
        url: `/clients/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
  };
}

export type ClientApi = ReturnType<typeof createClientApi>;
