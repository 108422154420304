import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
interface UserFormProps {
  form: UseFormReturn;
}

interface FormInput {
  name: string;
  type: string;
  placeholder: string;
  label: string;
  requiredIf?: (data: any) => boolean;
}

const inputs: FormInput[] = [
  {
    name: "user.email",
    type: "email",
    placeholder: "Email de l'user",
    label: "Email",
    requiredIf: () => true,
  },
  {
    name: "civility",
    type: "text",
    placeholder: "Civilité",
    label: "Civilité",
    requiredIf: () => false,
  },
  {
    name: "user.firstName",
    type: "text",
    placeholder: "Prénom",
    label: "Prénom",
    requiredIf: () => false,
  },
  {
    name: "user.lastName",
    type: "text",
    placeholder: "Nom",
    label: "Nom de famille",
    requiredIf: () => false,
  },
  {
    name: "user.phoneNumber",
    type: "text",
    placeholder: "Téléphone fixe",
    label: "Téléphone fixe",
    requiredIf: () => false,
  },
  {
    name: "phoneNumberPerso",
    type: "text",
    placeholder: "Téléphone perso",
    label: "Téléphone perso",
    requiredIf: () => false,
  },
  {
    name: "fonction",
    type: "text",
    placeholder: "Fonction",
    label: "Fonction",
    requiredIf: () => false,
  },
  {
    name: "user.dateOfBirth",
    type: "date",
    placeholder: "Date de naissance",
    label: "Date de naissance",
    requiredIf: () => false,
  },
];

export const ContactForm = (props: UserFormProps) => {
  const { form } = props;
  const data = form.watch();

  return (
    <div className="px-6">
      {inputs.map((input) => (
        <div key={input.name} className="w-full">
          <label htmlFor={input.name} className="label">
            <span className="label-text"> {input.label}</span>
          </label>
          {input.name == "civility" ? (
            <select className="w-full select select-sm select-bordered" {...form.register("civility")}>
              <option disabled selected>
                Civilité
              </option>
              <option value={"Mr"}>Mr</option>
              <option value={"Mme"}>Mme</option>
            </select>
          ) : input.name == "user.dateOfBirth" ? (
            <div>
              {data.user && <Controller
                control={form.control}
                name="user.dateOfBirth"
                render={({ field: { onChange, value=data.user.dateOfBirth?.toString().substring(0, 10) } }) => (
                  <input
                    type="date"
                    onChange={onChange}
                    value={value}
                    placeholder="Date de naissance"
                    className="w-full pt-2 input input-bordered "
                  />
                )}
              />}
            </div>
          ) : (
            <input
              {...form.register(input.name)}
              type={input.type}
              placeholder={input.placeholder}
              required={input.requiredIf && input.requiredIf(data)}
              className="w-full input input-bordered"
            />
          )}
        </div>
      ))}
    </div>
  );
};
