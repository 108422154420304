/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, IAnyType, types } from "mobx-state-tree";

// import { PaginatedModels } from "src/modules/models";
import { ClientLocationCheckinModel } from "src/modules/models/clientLocationCheckin.model";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const PaginatedModelsClientLocationsCheckin = <T extends IAnyType>(model: T) => {
  return types.model("PaginatedModels(clientLocationCheckins)", {
    data: types.array(model),
    count: types.number,
    total: types.number,
    page: types.number,
    pageCount: types.number,
    totalHours : types.string,
  });
};
export const ClientLocationCheckinReference = createEntityReference("clientLocationCheckins", ClientLocationCheckinModel);

export const ClientLocationCheckinsStore = createEntityStore("ClientLocationCheckin", ClientLocationCheckinModel)
  .props({
    clientLocationCheckins: types.maybe(PaginatedModelsClientLocationsCheckin(ClientLocationCheckinReference)),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: any) {
      const {
        api: { clientLocationCheckins: clientLocationCheckinsApi },
      } = getEnv<RootStoreEnv>(self);

      const clientLocationCheckIn = yield clientLocationCheckinsApi.fetchOneClientLocationCheckin(entityId);

      return ClientLocationCheckinModel.create({
        rawclientLocationCheckIn: clientLocationCheckIn,
        ...clientLocationCheckIn,
      });
    }),

    findClientLocationCheckins: flow(function* (findClientLocationCheckinsData: any) {
      const {
        api: { clientLocationCheckins: clientLocationCheckinsApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientLocationCheckinsApi.fetchClientLocationCheckins(findClientLocationCheckinsData);

      const clientLocationCheckins = result.data.map((clientLocationCheckIn: any) => {
        const entityModel = self.cacheEntity(
          ClientLocationCheckinModel.create({
            rawclientLocationCheckIn: clientLocationCheckIn,
            ...(clientLocationCheckIn as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.clientLocationCheckins = cast({
        ...result,
        data: cast(clientLocationCheckins as any),
      });

      return self.clientLocationCheckins as any;
    }),
  }));

export const clientLocationCheckinsStore = ClientLocationCheckinsStore.create({}) as any;
