import { UserObject } from '@platinium/shared';
import { Instance, types } from "mobx-state-tree";

import { DateTimeType } from 'src/modules/models/date-time.model';

export const OperationModel = types.model("OperationModel", {
  id: types.identifier,
  status: types.string,
  clientName: types.string,
  clientContactName: types.string,
  adressLine1: types.maybeNull(types.string),
  adressLine2: types.maybeNull(types.string),
  postalCode: types.string,
  city: types.string,
  type: types.string,
  accessInformations: types.maybeNull(types.string),
  agentsCount: types.number,
  isRequestingManager: types.boolean,
  requestInformations: types.maybeNull(types.string),
  requiredEquipments: types.maybeNull(types.string),
  requestedOperationDate: types.maybeNull(DateTimeType),
  plannedOperationDate: types.maybeNull(DateTimeType),
  completedOperationDate: types.maybeNull(DateTimeType),
  isBillable: types.boolean,
  operatingTime: types.maybeNull(types.number),
  privateResult: types.maybeNull(types.string),
  publicResult: types.maybeNull(types.string),
  ownerId: types.maybeNull(types.string),
  owner: types.maybeNull(types.frozen<UserObject>()),
  createdAt: types.maybeNull(DateTimeType),
  updatedAt: types.maybeNull(DateTimeType),
});

export type OperationModelType = Instance<typeof OperationModel>;
