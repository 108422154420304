import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import LogoPlatinium from "../../assets/images/platinium-logo/Exe_Logo_Platinium_Haut.svg";
import { useStore } from "../../context/store.context";

interface FormData {
  email: string;
  password: string;
}
export const Login = observer(() => {
  const { control, handleSubmit } = useForm<FormData>();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const {
    admins: { isLoggedIn, authenticate },
  } = useStore();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const handleChange = () => {
    setPasswordShown(!passwordShown);
  };

  const authenticateAdmin = async (data: FormData) => {
    const result = await authenticate({
      email: data.email,
      password: data.password,
    });
    if (!result) {
      setLoginFailed(true);
    } else if (!result.accessToken) {
      setLoginFailed(true);
    }
  };

  return (
    <div>
      <div className="flex min-h-screen">
        <div className="m-auto">
          <div className="m-5">
            <div className="flex justify-center pb-10">
              <img src={LogoPlatinium} className="w-60" alt="Platinium logo"></img>
            </div>
            <form onSubmit={handleSubmit(authenticateAdmin)} className="space-y-2 ">
              {loginFailed && <div className="text-error">Adresse mail ou mot de passe incorrect.</div>}
              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value = "" } }) => (
                  <input
                    type="email"
                    placeholder="Rentrez votre email"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    className="w-full input input-bordered"
                  ></input>
                )}
              />
              <Controller
                control={control}
                name="password"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value = "" } }) => (
                  <input
                    type={passwordShown ? "text" : "password"}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="Rentrez votre mot de passe"
                    className="w-full input input-bordered"
                  />
                )}
              />

              <label className="justify-start cursor-pointer label">
                <input type="checkbox" checked={passwordShown} onChange={handleChange} className="checkbox" />
                <span className="pl-2 label-text">Montrer le mot de passe</span>
              </label>
              <div className="flex justify-center">
                <button className="btn" type="submit">
                  Se connecter
                </button>
              </div>
            </form>
            <Link className="flex justify-center pt-5 link" to="/request-password">
              Mot de passe oublié
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});
