/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductObject } from '@platinium/shared';
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createProductApi(axios: AxiosInstance) {
  return {
    async findProducts(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: "/products",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchProducts(params?: any): Promise<any> {
      return axios({
        url: "/products/all",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneProduct(entityId: string): Promise<any> {
      return axios({
        url: `/products/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async createProduct(data: Partial<ProductObject>): Promise<ProductObject> {
      return axios({
        url: "/products",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async deleteProduct(entityId: string) {
      return axios({
        url: `/products/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
    async uploadProductDocument(productId: string, documentProductType: "securityDocumentId" | "documentId", file: File) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("productId", productId);
      bodyFormData.append("documentProductType", documentProductType);
      return axios({
        url: "/products/upload-document",
        method: "POST",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data);
    },
    async updateProduct(entityId: string, data: Partial<ProductObject>) {
      return axios({
        url: `/products/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
  };
}

export type ProductApi = ReturnType<typeof createProductApi>;
