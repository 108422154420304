import { faCheck, faPen, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import BackButton from "../../assets/icons/back_button.svg";
import { Loader } from "../../components/Loader/Loader";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { ClientLocations } from "./ClientLocations";

interface FormData {
  name: string;
}

export const Client = observer(() => {
  const [isInput, setIsInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, setValue } = useForm<FormData>();
  const { clients: clientsApi } = useApi();
  const {
    clients: { client, fetchEntity },
  } = useStore();
  const { clientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (clientId) {
        await fetchEntity(clientId);
        setIsLoading(true);
      } else {
        setIsLoading(true);
      }
    })();
  }, [clientId]);

  useEffect(() => {
    if (client) {
      setValue("name", client.name);
    }
  }, [client]);

  const onSubmit = async (dataForm: FormData) => {
    if (client) {
      const data = {
        name: dataForm.name,
      };
      await clientsApi.updateClient(client.id, data);
      await fetchEntity(client.id);
    } else {
      const data = {
        name: dataForm.name,
        isClientPlatinium: false,
        isClientSunset: false,
      };
      const clientCreated = await clientsApi.createClient(data);
      await fetchEntity(clientCreated.id);
      navigate("/client/" + clientCreated.id);
    }
    setIsInput(!isInput);
  };

  const handleChange = async (isSunset: any, isPlatinium: any) => {
    const data = {
      isClientSunset: isSunset,
      isClientPlatinium: isPlatinium,
    };
    if (client) {
      await clientsApi.updateClient(client.id, data);
      await fetchEntity(client.id);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex-1">
          <div className="pt-5 pl-5 md:container md:mx-auto navbar w-36" onClick={() => navigate("/clients")}>
            <img src={BackButton} />
          </div>
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <form onSubmit={handleSubmit(onSubmit)} className="flex justify-center m-5">
                {!isInput ? (
                  <h1 className="text-2xl ">{client ? client.name : "Nom du client"}</h1>
                ) : (
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, value = "" } }) => (
                      <input type="text" value={value} onChange={onChange} placeholder={"Nom du client"} className="input input-bordered" />
                    )}
                  />
                )}
                {!isInput ? (
                  <button
                    onClick={() => {
                      setIsInput(!isInput);
                    }}
                    type="button"
                    title="Edit"
                    className="pl-4 hover:text-blue-500"
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                ) : (
                  <div className="flex justify-content">
                    <button
                      onClick={() => {
                        setIsInput(!isInput);
                      }}
                      type="button"
                      title="Edit"
                      className="pl-4"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <button type="submit" title="Edit" className="pl-4">
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                )}
              </form>
              <div className="flex justify-center">
                {client && (
                  <div className="dropdown">
                    <label tabIndex={0} className="m-1 btn btn-sm">
                      Contrat
                    </label>
                    <ul tabIndex={0} className="p-2 shadow dropdown-content bg-base-100 rounded-box w-52">
                      <li>
                        <div className="form-control">
                          <label className="justify-start cursor-pointer label">
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={client?.isClientSunset}
                              onChange={() => handleChange(!client?.isClientSunset, client?.isClientPlatinium)}
                            />
                            <span className="pl-2 label-text">Sunset</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-control">
                          <label className="justify-start cursor-pointer label">
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={client?.isClientPlatinium}
                              onChange={() => handleChange(client?.isClientSunset, !client?.isClientPlatinium)}
                            />
                            <span className="pl-2 label-text">Platinium</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {clientId && <ClientLocations />}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
