import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { Layout } from "../components/Layout/Layout";
import { useStore } from "../context/store.context";
import { Admins } from "../pages/Admins/Admins";
import { AgentForm } from "../pages/Agents/AgentForm";
import { Agents } from "../pages/Agents/Agents";
import { FirstConnection } from "../pages/Auth/FirstConnection";
import { Login } from "../pages/Auth/Login";
import { RequestPassword } from "../pages/Auth/RequestPassword";
import { Client } from "../pages/Clients/Client";
import { ClientLocation } from "../pages/Clients/ClientLocation";
import { Clients } from "../pages/Clients/Clients";
import { Map } from "../pages/Clients/Map/Map";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Instruction } from "../pages/Instruction/Instruction";
import { MyAccount } from '../pages/MyAccount/MyAccount';
import { Operations } from '../pages/Operations/Operations';
import { Order } from "../pages/Orders/Order";
import { Orders } from "../pages/Orders/Orders";
import { Products } from "../pages/Products/Products";
import { Settings } from "../pages/Settings/Settings";
import { Trainings } from "../pages/Training/Trainings";
import { ProtectedRoute } from "./ProtectedRoute";

export const GlobalRouter = observer(() => {
  const {
    admins: { isLoggedIn, clearCurrentAdmin, fetchCurrentAdmin },
  } = useStore();

  useEffect(() => {
    if (isLoggedIn) {
      fetchCurrentAdmin();
    } else {
      clearCurrentAdmin();
    }
  }, [isLoggedIn, fetchCurrentAdmin, clearCurrentAdmin]);

  return (
    <Routes>
      <Route>
        <Route path="/login" element={<Login />} />
        <Route path="/request-password" element={<RequestPassword />} />
        <Route path="reset-password" element={<FirstConnection />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/myaccount" element={<MyAccount />} />
          <Route path="clients" element={<Clients />} />
          <Route path="client/:clientId" element={<Client />} />
          <Route path="client" element={<Client />} />
          <Route path="clientLocation" element={<ClientLocation />} />
          <Route path="clientLocation/:clientLocationId" element={<ClientLocation />} />
          <Route path="clientLocation/:clientLocationId/map" element={<Map />} />
          <Route path="agents" element={<Agents />} />
          <Route path="agent" element={<AgentForm />} />
          <Route path="orders" element={<Orders />} />
          <Route path="operations" element={<Operations />} />
          <Route path="orders/:orderId" element={<Order />} />
          <Route path="admins" element={<Admins />} />
          <Route path="products" element={<Products />} />
          <Route path="instructions/:clientLocationId" element={<Instruction />} />
          <Route path="trainings" element={<Trainings />} />
        </Route>
      </Route>
    </Routes>
  );
});
