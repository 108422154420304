import { AgentObject, OrderSourceEnum, StatusOrderEnum } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

import { ClientLocationModel } from "src/modules/models/clientLocation.model";
import { DocumentModel } from "src/modules/models/document.model";
import { ProductModel } from "src/modules/models/product.model";

export const OrderProductModel = types.model("OrderProductModel", {
  id: types.identifier,
  quantity: types.number,
  productId: types.string,
  product: ProductModel,
});
export type OrderProductModelType = Instance<typeof OrderModel>;

export const OrderModel = types.model("OrderModel", {
  id: types.identifier,
  clientLocationId: types.string,
  clientLocation: ClientLocationModel,
  documentId: types.maybeNull(types.string),
  document: types.maybe(types.maybeNull(DocumentModel)),
  agentId: types.string,
  agent: types.maybe((types.frozen<AgentObject>())),
  subscriptionId: types.maybeNull(types.string),
  instructions: types.maybeNull(types.string),
  defaultQuantity: types.maybeNull(types.number),
  orderProducts: types.array(OrderProductModel),
  status: types.enumeration(Object.values(StatusOrderEnum)),
  source: types.enumeration(Object.values(OrderSourceEnum)),
});

export type OrderModelType = Instance<typeof OrderModel>;
