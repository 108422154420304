import { faCirclePlus, faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pick } from "lodash";
import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { useApi } from "../../hooks/useApi";

interface DocumentCardProps {
  documentKey: string | null;
  documentLabel: string;
  documentId: string | null;
  documentProductType: "securityDocumentId" | "documentId";
  documentTitle: string;
  product: any;
  productId: string;
  form: UseFormReturn;
  setError: (value: any) => void;
}

export const DocumentCard = (props: DocumentCardProps) => {
  const { documentId, documentKey, documentProductType, documentTitle, documentLabel, product, productId, form, setError } = props;

  const { products: productsApi } = useApi();
  const [updatedDocumentKey, setUpdatedDocumentKey] = useState("");
  const [updatedDocumentId, setUpdatedDocumentId] = useState("");

  const updateState = () => {
    setUpdatedDocumentKey(documentKey || "");
    setUpdatedDocumentId(documentId || "");
  };

  useEffect(() => {
    updateState();
    setError(false);
  }, [documentId, documentKey, productId]);

  useEffect(() => {
    setError(false);
  }, []);
  const uploadFile = async (file: File) => {
    let productID = productId;
    setError(false);
    if (productId == undefined) {
      if (product.defaultQuantity == undefined || product.description == undefined) {
        if (product.defaultQuantity == undefined) {
          setError(true);
        }
        if (product.description == undefined) {
          setError(true);
        }
        return;
      } else {
        if (!product.orderableBy) {
          product.orderableBy = [];
        }

        const payload = {
          ...pick(product, ["reference", "description", "orderableBy", "type"]),
          defaultQuantity: parseInt(product.defaultQuantity),
        };
        const newProduct = await productsApi.createProduct(payload);
        form.setValue("id", newProduct.id);
        productID = newProduct.id;
      }
    }

    try {
      const {
        document: { key, id },
      } = (await productsApi.uploadProductDocument(productID, documentProductType, file)) || {};
      setUpdatedDocumentKey(key);
      setUpdatedDocumentId(id);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="flex items-center my-6">
      <p className="w-4/12 pr-3 font-bold">{documentLabel}: </p>
      {updatedDocumentId && <p className="w-5/12 pr-3 overflow-hidden text-sm text-ellipsis whitespace-nowrap">{updatedDocumentId}</p>}
      {updatedDocumentKey && (
        <button
          onClick={() => {
            const link = document.createElement("a");
            link.href = updatedDocumentKey;
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          }}
          type="button"
          title={`Télécharger ${documentTitle} du produit`}
          className="hover:text-blue-500"
        >
          <FontAwesomeIcon icon={faDownload} />
        </button>
      )}

      <label className="flex w-5 ml-5 rounded cursor-pointer hover:text-blue-500">
        <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
        <input
          type="file"
          accept="image/*,video/mp4,application/pdf"
          id="file1"
          className="hidden hover:text-blue-500"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
              uploadFile(event.target.files[0]);
            }
          }}
        />
      </label>
    </div>
  );
};
