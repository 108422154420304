import { DocumentObject } from '@platinium/shared';
import { Instance, types } from "mobx-state-tree";

export const UrgentInstructionModel = types.model("UrgentInstructionModel", {
  id: types.identifier,
  title: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  documents: types.array(types.frozen<DocumentObject>()),
  viewed: types.boolean
});

export type UrgentInstructionModelType = Instance<typeof UrgentInstructionModel>;
