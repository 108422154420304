import { ClientLocationOperationTypeEnum } from "@platinium/shared";
import { DocumentObject } from "@platinium/shared";
import { ClientLocationAgentObject } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

import { ClientModel } from "src/modules/models/client.model";
import { ProductModel } from "src/modules/models/product.model";
import { UserModel } from "src/modules/models/user.model";

export const ContactObject = types.model("ContactObject", {
  id: types.identifier,
  user: types.frozen(UserModel),
  phoneNumberPerso: types.maybeNull(types.string),
  civility: types.maybeNull(types.string),
  fonction: types.maybeNull(types.string),
  isMainContact: types.boolean,
});

export const ProductsObject = types.model("ProductObject", {
  id: types.identifier,
  product: types.maybeNull(ProductModel),
});

export const ClientLocationContactObject = types.model("ClientLocationContactObject", {
  id: types.identifier,
  contacts: types.maybeNull(ContactObject),
});

export const ClientLocationModel = types.model("ClientLocationModel", {
  id: types.identifier,
  name: types.string,
  adressLine1: types.string,
  adressLine2: types.maybeNull(types.string),
  postalCode: types.string,
  city: types.string,
  digitalCodes: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  plasticBagHandled: types.boolean,
  sanitaryConsumablesHandled: types.boolean,
  isClientSunset: types.boolean,
  isClientPlatinium: types.boolean,
  size: types.maybeNull(types.string),
  geolocation: types.maybeNull(types.string),
  operationType: types.enumeration(Object.values(ClientLocationOperationTypeEnum)),
  contacts: types.maybe(types.maybeNull(types.array(ContactObject))),
  clientLocationAgents: types.maybe(types.array(types.frozen<ClientLocationAgentObject>())),
  clientLocationContacts: types.maybe(types.maybeNull(types.array(ClientLocationContactObject))),
  products: types.maybe(types.maybeNull(types.array(ProductsObject))),
  client: types.maybe(ClientModel),
  qrcodeDocument: types.maybeNull(types.frozen<DocumentObject>()),
  mainContactEmail: types.maybe(types.string),
});

export type ClientLocationModelType = Instance<typeof ClientLocationModel>;
