/* eslint-disable @typescript-eslint/no-explicit-any */
import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from "lodash";
import React, { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

interface DataGridFormProps {
  title: ((form: UseFormReturn) => string) | string;
  showCta?: boolean;
  defaultValues?: any;
  okLabel?: string;
  onSubmit: (data: any) => Promise<void>;
  children: (form: UseFormReturn) => ReactNode;
  cancel?: (form: UseFormReturn) => void;
  closeOnSubmit?: boolean;
  submitLabel?: string;
}

interface DataGridFormHandle {
  isOpen: boolean;
  open(data?: any): void;
  close(): void;
  reset(): void;
  values: any;
}

export const DataGridForm = forwardRef<DataGridFormHandle, DataGridFormProps>((props, ref) => {
  const {
    children,
    onSubmit: onSubmitForm,
    title,
    defaultValues,
    cancel,
    closeOnSubmit = true,
    submitLabel = "Confirmer"
  } = props;
  const [isOpen, setOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const form = useForm({
    defaultValues: defaultValues || {},
  });

  useEffect(() => {
    if (form.getValues().id) {
      setIsCreate(false);
    } else {
      setIsCreate(true);
    }
  }, [isOpen]);

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    onSubmitForm(form.getValues())
      .then(() => {
        if (closeOnSubmit) {
          setOpen(false);
        }
        setError("");
      })
      .catch((err) => {
        if (typeof err.response.data.message === "string") {
          setError(err.response.data.message);
        } else {
          setError("Une erreur est survenue");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [form, onSubmitForm, closeOnSubmit, setIsLoading]);

  useImperativeHandle(ref, () => ({
    get values() {
      return form.getValues();
    },
    reset() {
      return form.reset();
    },
    open(data?: any) {
      form.reset();
      if (data) {
        map(data, (value: any, key: string) => {
          form.setValue(key, value, { shouldDirty: true });
        });
      }
      setOpen(true);
    },
    close() {
      setOpen(false);
    },
    get isOpen() {
      return isOpen;
    },
  }));

  return (
    <label htmlFor="my-modal-4" className={`${isOpen ? "modal-open" : ""} modal`}>
      <label className="relative modal-box max-w-2xl" htmlFor="">
        <form className="flex flex-col h-full overflow-y-auto bg-white" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex-1">
            <div className=" bg-background-header">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <h3 className="text-lg font-medium">{title && (typeof title === "string" ? title : title(form))}</h3>
                </div>
              </div>
            </div>

            {/* Divider container */}
            <div>{children(form)}</div>
          </div>

          {/* <p className='text-sm'>{error}</p> */}
          {error && (
            <div className="mt-4 shadow-lg alert alert-error">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>{error}</span>
              </div>
            </div>
          )}
          {/* Action buttons */}
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button disabled={isLoading} type="submit" className="ml-2 btn btn-outline btn-success">
              { submitLabel}
              {isLoading && <FontAwesomeIcon className="ml-2 animate-spin" icon={faLoader} />}
            </button>
            <button
              disabled={isLoading}
              type="button"
              className=" btn btn-outline"
              onClick={() => {
                setError("");
                setOpen(false);
                if (isCreate) {
                  if (cancel) {
                    cancel(form.getValues());
                  }
                }
              }}
            >
              Annuler
            </button>
          </div>
        </form>
      </label>
    </label>
  );
});

DataGridForm.displayName = "DataGrid";
