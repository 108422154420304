/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { AuthenticateAgentDto } from "src/modules/api";
import { AgentModel, PaginatedModels } from "src/modules/models";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const AgentReference = createEntityReference("agents", AgentModel);

export const AgentsStore = createEntityStore("Agent", AgentModel)
  .props({
    agents: types.maybe(PaginatedModels(AgentReference)),
    currentAgent: types.maybeNull(AgentModel),
    agent: types.maybeNull(AgentModel),
    isLoggedIn: types.optional(types.boolean, () => false),
  })
  .actions((self) => ({
    authenticate: flow(function* _(data: AuthenticateAgentDto) {
      const {
        api: { agents: agentApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield agentApi.authenticate(data);
        if (result.accessToken) {
          self.isLoggedIn = true;
          localStorage.setItem("is-logged", "true");
        }
        return result;
      } catch (error) {
        console.error("[Auth]", error);
      }
    }),
    fetchCurrentAgent: flow(function* _() {
      const {
        api: { agents: agentApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const agent = yield agentApi.current();
        self.currentAgent = agent;
        return agent;
      } catch (error) {
        console.log("error", error);
        self.isLoggedIn = false;
      }
    }),

    clearCurrentAgent() {
      self.currentAgent = null;
    },
    clearAgent() {
      self.agent = null;
    },
    logout() {
      const {
        api: { authentication },
      } = getEnv<RootStoreEnv>(self);
      try {
        authentication.logout();
        self.isLoggedIn = false;
        self.currentAgent = null;
        localStorage.removeItem("is-logged");
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  }))
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { agents: agentApi },
      } = getEnv<RootStoreEnv>(self);

      const agent = yield agentApi.fetchOneAgent(entityId);

      self.agent = agent;
      return AgentModel.create({
        rawAgent: agent,
        ...agent,
      });
    }),

    findAgents: flow(function* (findAgentData?: any) {
      const {
        api: { agents: agentApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield agentApi.fetchAgents(findAgentData);
      const agents = result.data.map((agent: any) => {
        const entityModel = self.cacheEntity(
          AgentModel.create({
            rawAgent: agent,
            ...(agent as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.agents = cast({
        ...result,
        data: cast(agents as any),
      });

      return self.agents as PaginatedModels<typeof AgentModel>;
    }),
  }))
  .views((self) => ({
    get isAuthenticated() {
      return self.isLoggedIn && !!self.currentAgent;
    },
    get fullNameCurrentAgent() {
      if (!self.currentAgent?.user) {
        return "";
      } else {
        const { firstName, lastName } = self.currentAgent.user;
        return `${firstName} ${lastName}`;
      }
    },
    get fullAdressCurrentAgent() {
      if (!self.currentAgent) {
        return "";
      } else {
        const { addressLine1, addressLine2, city, postalCode } = self.currentAgent;
        if (addressLine1 === null || city === null || postalCode === null) {
          return "";
        }
        const clearVar = (arg: string | null) => (arg !== null ? arg : "");
        return `${addressLine1} ${clearVar(addressLine2)} ${postalCode} ${city}`;
      }
    },
  }));

export const agentsStore = AgentsStore.create({
  currentAgent: null,
  agent: null,
  isLoggedIn: localStorage.getItem("is-logged") ? true : false,
});
