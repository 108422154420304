/* eslint-disable @typescript-eslint/no-explicit-any */
import { flow, getEnv, types } from "mobx-state-tree";

import { ClientLocationAgentModel } from "src/modules/models/clientLocationAgent.model";

import { RootStoreEnv } from "./root.store";

export const ClientLocationAgentsStore = types
  .model("ClientLocationAgent", {
    clientLocationAgents: types.maybeNull(types.array(ClientLocationAgentModel)),
    clientLocationAgent: types.maybeNull(ClientLocationAgentModel),

  })
  .actions((self) => ({
    fetchClientLocationAgent: flow(function* (params?: string) {
      const {
        api: { clientLocationAgents },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientLocationAgents.fetchClientLocationAgent(params);
      self.clientLocationAgents = result;
      return self.clientLocationAgent;
    }),
    fetchOneClientLocationAgent: flow(function* (params?: string) {
      const {
        api: { clientLocationAgents },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientLocationAgents.fetchClientLocationAgent(params);
      self.clientLocationAgent = result;
      return self.clientLocationAgent;
    }),
  }))
  .views(() => ({}));

export const clientLocationAgentsStore = ClientLocationAgentsStore.create({
  clientLocationAgents: [],
});
