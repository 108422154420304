import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Expose } from "class-transformer";
import { IsNotEmpty, IsString } from "class-validator";

import { ClientLocationObject } from "../clientLocation/clientLocation.object";

export class ClientLocationAgentObject {
  @Expose()
  @ApiProperty({
    description: "Contact's id",
    example: "6387d87d-468b-427d-aea6-571b32302ba6",
  })
  @IsString()
  id: string;

  @Expose()
  @ApiProperty({
    description: "clientLocation's object",
  })
  @IsString()
  @IsNotEmpty()
  clientLocation: ClientLocationObject;
}
