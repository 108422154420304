import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { useApi } from "../../hooks/useApi";

export const ModalAddProduct = observer(
  ({
    modalAddProductOpen,
    setModalAddProductOpen,
    onSubmit,
    orderProducts,
  }: {
    modalAddProductOpen: boolean;
    setModalAddProductOpen: (arg0: boolean) => void;
    onSubmit: (data: any) => void;
    orderProducts: any[];
  }) => {
    const {
      products: { fetchProducts },
    } = useApi();

    const [productsOption, setProductsOption] = useState<any[]>([]);

    useEffect(() => {
      (async () => {
        await getProducts();
        setValue("product", null);
        setValue("quantity", null);
      })();
    }, [modalAddProductOpen]);

    const getProducts = async () => {
      const productList: any[] = [];
      const productsFetch = await fetchProducts();

      if (productsFetch) {
        productsFetch.map((product: any) => {
          if (product) {
            const a = { value: product.id, label: product.description };
            productList.push(a);
          }
        });
        const productsFiltered = productList.filter((product) => {
          return !orderProducts?.find((p: any) => p.productId === product.value);
        });
        setProductsOption(productsFiltered);
      }
    };

    const { control, register, handleSubmit, watch, setValue } = useForm();
    const data = watch();

    return (
      <label htmlFor="my-modal-4" className={`${modalAddProductOpen ? "modal-open" : ""} modal`}>
        <label className="relative modal-box" htmlFor="">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full overflow-y-auto bg-white">
            <div className="flex-1">
              <div className=" bg-background-header">
                <div className="flex items-start justify-between space-x-3">
                  <div className="space-y-1">
                    <h3 className="mb-5 text-lg font-medium">Ajouter un produit</h3>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <Controller
                  control={control}
                  name="product"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="mb-5"
                      closeMenuOnSelect={true}
                      placeholder="Sélectionner un produit"
                      value={value}
                      onChange={onChange}
                      hideSelectedOptions={true}
                      options={productsOption}
                      styles={{
                        menuList: (styles) => ({ ...styles, height: "150px" }),
                      }}
                    />
                  )}
                />
                <label htmlFor="quantity" className="label">
                  <span className="label-text"> Quantité</span>
                </label>
                <input
                  {...register("quantity")}
                  type="number"
                  placeholder="5"
                  min="1"
                  required={true}
                  disabled={!data.product ? true : false}
                  className="w-full input input-bordered"
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="submit" className={`ml-2 btn btn-outline btn-success ${!data.product ? "btn-disabled" : ""}`}>
                Confirmer
              </button>
              <button type="button" className=" btn btn-outline" onClick={() => setModalAddProductOpen(false)}>
                Annuler
              </button>
            </div>
          </form>
        </label>
      </label>
    );
  }
);
