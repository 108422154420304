import { Instance, types } from "mobx-state-tree";

import { DateTimeType } from "src/modules/models/date-time.model";

export const DocumentModel = types.model("DocumentModel", {
  id: types.identifier,
  name: types.maybe(types.maybeNull(types.string)),
  key: types.maybeNull(types.string),
  extension: types.maybe(types.maybeNull(types.string)),
  bucket: types.maybe(types.maybeNull(types.string)),
  createdAt: types.maybe(DateTimeType),
  updatedAt: types.maybe(DateTimeType),
});

export type DocumentModelType = Instance<typeof DocumentModel>;
