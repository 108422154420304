import { useEffect, useState } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref: any) => {
  const [outside, setOutside] = useState<boolean>(true);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOutside(true);
      }else{
        setOutside(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  return outside;
};

export default useOutsideAlerter;
