import { Instance, types } from "mobx-state-tree";

import { ClientLocationModelType } from 'src/modules/models/clientLocation.model';
import { DocumentModelType } from "src/modules/models/document.model";

export const ProblemModel = types.model("ProblemModel", {
  id: types.identifier,
  agentId: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  documents: types.maybeNull(types.array(types.frozen<DocumentModelType>())),
  clientLocation: types.maybeNull(types.frozen<ClientLocationModelType>()),
});

export type ProblemModelType = Instance<typeof ProblemModel>;
