/* eslint-disable @typescript-eslint/no-explicit-any */
import { flow, getEnv, types } from "mobx-state-tree";

import { ProblemModel } from "src/modules/models";

import { RootStoreEnv } from "./root.store";

export const ProblemsStore = types
  .model("Problem", {
    problems: types.maybeNull(types.array(ProblemModel)),
    problem: types.maybeNull(ProblemModel),
  })
  .actions((self) => ({
    findProblems: flow(function* _(entityId: string) {
      const {
        api: { problems },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield problems.findProblems(entityId);
        self.problems = result;
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    findOneProblem: flow(function* _(entityId: string) {
      const {
        api: { problems },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield problems.findOneProblem(entityId);
        self.problem = result;

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    clearProblem() {
      self.problem = null;
    },
  }))
  .actions((self) => ({
    updateProblems: flow(function* _(clientLocationId: string, entityId: string, data: any) {
      const {
        api: { problems },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield problems.updateProblems(entityId, data);
        self.findProblems(clientLocationId);
        self.findOneProblem(clientLocationId);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    uploadDocument: flow(function* _(clientLocationId: string, entityId: string, files: File) {
      const {
        api: { problems },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield problems.uploadDocument(entityId, files);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteDocument: flow(function* _(problemId: string, entityId: string) {
      const {
        api: { problems },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield problems.deleteDocument(entityId);
        self.findOneProblem(problemId);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteProblem: flow(function* _(entityId: string) {
      const {
        api: { problems },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield problems.deleteProblem(entityId);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    createProblem: flow(function* _(data: any) {
      const {
        api: { problems },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield problems.createProblem(data);
        self.findOneProblem(result.id);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
  }))
  .views(() => ({}));

export const problemsStore = ProblemsStore.create({
  problems: [],
  problem: null,
});
