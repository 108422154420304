import React from "react";
import { Link, useNavigate } from "react-router-dom";

// import LogoPlatinium from "../../assets/images/platinium-logo/Logo_PLATINIUM.png";
import LogoPlatinium from "../../assets/images/platinium-logo/Exe_Logo_Platinium_BL_Long.svg";
import { useStore } from "../../context/store.context";

export const Navbar = () => {
  const {
    admins: { logout },
  } = useStore();

  const navigate = useNavigate();
  return (
    <div className="pt-2 pb-2 shadow-xl">
      <div className="md:container md:mx-auto navbar">
        <div className="w-full navbar-start">
          <div className="flex-none dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
              </svg>
            </label>
            <ul tabIndex={0} className="p-2 mt-3 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 ">
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to="clients">Clients</Link>
              </li>
              <li>
                <Link to="agents">Agents</Link>
              </li>
              <li>
                <Link to="orders">Commandes</Link>
              </li>
              <li>
                <Link to="operations">Interventions</Link>
              </li>
              <li>
                <Link to="admins">Administrateurs</Link>
              </li>
              <li>
                <Link to="products">Produits</Link>
              </li>
              <li>
                <Link to="trainings">Formations</Link>
              </li>
            </ul>
          </div>
          <Link to="/" className="text-xl normal-case btn btn-link">
            <img src={LogoPlatinium} alt="Platinium logo" width="160px" height="auto"/>
          </Link>
          <div className="hidden navbar-center lg:flex">
            <ul className="p-0 menu menu-horizontal">
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to="clients">Clients</Link>
              </li>
              <li>
                <Link to="agents">Agents</Link>
              </li>
              <li>
                <Link to="orders">Commandes</Link>
              </li>
              <li>
                <Link to="operations">Interventions</Link>
              </li>
              <li>
                <Link to="admins">Administrateurs</Link>
              </li>
              <li>
                <Link to="products">Produits</Link>
              </li>
              <li>
                <Link to="trainings">Formations</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="dropdown dropdown-end ">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img src="https://gravatar.com/avatar/709464d2868f858421cdb9877c15ce70?s=400&d=robohash&r=x" />
            </div>
          </label>
          <ul tabIndex={0} className="p-2 mt-3 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
            <li onClick={() => {navigate("/myaccount"); (document.activeElement as HTMLElement).blur();}}>
              <a>Mon compte</a>
            </li>
            <li onClick={() => logout()}>
              <a>Se déconnecter</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
