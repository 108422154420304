export enum ClientLocationDataTypeEnum {
  KEYS = "KEYS",
  ELECTRICITY = "ELECTRICITY",
  GARBAGES = "GARBAGES",
  CLEANING_PRODUCTS = "CLEANING_PRODUCTS",
  ACCESS_CODE = "ACCESS_CODE",
  ALARM = "ALARM",
  SUBWAY = "SUBWAY",
  PRODUCTS = "PRODUCTS",
  QRCODE = "QRCODE",
  ADDRESS = "ADDRESS",
  CONSUMABLE = "CONSUMABLE",
  LOCKS = "LOCKS",
}
