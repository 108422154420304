/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserModelType, UserRoleEnum } from "@platinium/cdk";
import { cloneDeep, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridForm } from "../../components/DataGrid/DataGridForm";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";
import { dateToFrench } from "../../utils/dateToFrench";
import { Loader } from "../Loader/Loader";
import { ContactForm } from "./ContactForm";

const columns = [
  {
    label: "Prenom",
    name: "user.firstName",
  },
  {
    label: "Nom",
    name: "user.lastName",
  },
  {
    label: "Email",
    name: "user.email",
  },
  {
    label: "Téléphone pro",
    name: "user.phoneNumber",
  },
  {
    label: "Téléphone perso",
    name: "contact.phoneNumberPerso",
  },
  {
    label: "Fonction",
    name: "contact.fonction",
  },
  {
    label: "Date de naissance",
    name: "user.dateOfBirth",
  },
  {
    label: "",
    name: "actions",
  },
];

interface ContactProps {
  clientLocationId: string;
}

export const Contacts = observer(({ clientLocationId }: ContactProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(null);
  const { contacts: contactsStore } = useStore();
  const { clientLocationContacts: clientLocationContactsStore } = useStore();
  const {
    contacts: { contacts, findContacts },
  } = useStore();
  const [contactTable, setContactTable] = useState<any[]>([]);
  const { clientLocationContacts: clientLocationContactsApi } = useApi();

  const { contacts: contactsApi, users: usersApi } = useApi();
  const {
    search,
    setSearch,
    entities: clientLocationContacts,
    pages,
    currentPage,
    setCurrentPage,
    ordering,
    setOrdering,
  } = useDataGrid({
    findFunction: clientLocationContactsStore.findClientLocationContacts,
    searchBuilder: useCallback((search) => ({
      $and: [
        {
          $or: [
            {
              "user.firstName": {
                $contL: search,
              },
            },
            {
              "user.lastName": {
                $contL: search,
              },
            },
            {
              "user.email": {
                $contL: search,
              },
            },
          ],
        },
        {
          clientLocationId: {
            $eq: clientLocationId,
          },
        },
        {
          isMainContact: {
            $eq: false,
          },
        },
      ],
    }), [clientLocationId]),
  });
  useEffect(() => {
    (async () => {
      await getContact();
      setIsLoading(true);
    })();
  }, []);

  const getContact = async () => {
    await findContacts();
    const contactList: any[] = [];
    if (contacts && contacts.data) {
      contacts.data.map((contact) => {
        if (contact) {
          const a = { value: contact.id, label: contact.user.firstName + " " + contact.user.lastName };
          contactList.push(a);
        }
      });
      const clientLocationContact = await clientLocationContactsApi.fetchClientLocationContacts(
        `s={"$and": [{"clientLocationId":"${clientLocationId}"}]}`
      );
      const contactsFiltered = contactList.filter((contact) => {
        return !clientLocationContact.data.find((clientLocationContact: any) => clientLocationContact.contactId === contact.value);
      });
      setContactTable(contactsFiltered);
    }
  };

  const handleChangeSelected = async (contactSelected: any) => {
    setValue(contactSelected);
    const data = {
      clientLocationId: clientLocationId,
      contactId: contactSelected.value,
      isMainContact: false,
    };
    await clientLocationContactsApi.createClientLocationContact(data);
    clientLocationContacts.reload();
    getContact();
    setValue(null);
  };

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onSubmitForm = useCallback(
    async (data: any) => {
      const { user } = data;
      if (data.id) {
        const { id: userId, createdAt, updatedAt, ...userUpdated } = user;
        await usersApi.updateUser(userId, { ...pickBy(userUpdated) });
        const result = await contactsApi.updateContact(data.id, {
          civility: data.civility,
          fonction: data.fonction,
          phoneNumberPerso: data.phoneNumberPerso,
        });
        clientLocationContacts.reload();
      } else {
        const userCreated = await usersApi.createUser({ ...pickBy(user), role: UserRoleEnum.CLIENT });
        const dataContact = {
          civility: data.civility,
          phoneNumberPerso: data.phoneNumberPerso != "" ? data.phoneNumberPerso : null,
          fonction: data.fonction != "" ? data.fonction : null,
          userId: userCreated.id,
          clientLocationId: clientLocationId,
          isMainContact: false,
        };

        const newContact = await contactsApi.createContact(dataContact);
        const dataClientLocationContact = {
          clientLocationId: clientLocationId,
          contactId: newContact.id,
          isMainContact: false,
        };
        await clientLocationContactsApi.createClientLocationContact(dataClientLocationContact);
        await findContacts();
        await getContact();
        clientLocationContacts.reload();
      }
    },
    [clientLocationContacts, contactsApi, usersApi]
  );

  const onDelete = useCallback(
    async (data: any) => {
      await clientLocationContactsApi.deleteClientLocationContact(data);
      await getContact();
      clientLocationContacts.reload();
    },
    [clientLocationContacts, contactsApi]
  );

  const descriptionName = (user: UserModelType) => {
    const { firstName, lastName } = user || {};
    return firstName || lastName ? `${firstName || ""} ${lastName || ""}` : "cet admin ";
  };

  return (
    <>
      {clientLocationContacts.data && isLoading ? (
        <div className="flex-1 min-h-96">
          <DataGridForm
            ref={formRef}
            title={(form) => {
              const data = form.getValues();
              return data.id ? `Modifier un contact` : "Créer un contact";
            }}
            onSubmit={onSubmitForm}
          >
            {(form) => <ContactForm form={form}></ContactForm>}
          </DataGridForm>

          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer un contact"
            description={(data) => `Êtes vous sur de vouloir supprimer ce contact ?`}
          />
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2">
              <DataGridHeader
                searchPlaceholder="Chercher un contact par nom, prénom, email"
                search={search}
                onSearchChange={setSearch}
                actions={
                  <div className="flex items-center">
                    <div className="mr-5 overflow-visible">
                      <Select
                        options={contactTable}
                        onChange={(e) => {
                          handleChangeSelected(e);
                        }}
                        value={value}
                        placeholder="Assigner un contact existant"
                        className="text-sm"
                      />
                    </div>

                    <button onClick={() => formRef.current && formRef.current.open()} type="button" className="btn btn-sm">
                      Nouveau contact
                    </button>
                  </div>
                }
              />
              <div className="overflow-x-auto">
                <DataGridTable dataProvider={clientLocationContacts} columns={columns} ordering={ordering} onOrderingChange={setOrdering}>
                  {(data) =>
                    data.data.map((clientLocationContact: any) => {
                      return (
                        <tr
                          key={clientLocationContact.contact.id}
                          className="cursor-pointer hover"
                          onClick={(e) => {
                            formRef.current && formRef.current.open(cloneDeep(clientLocationContact.contact));
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">{clientLocationContact.contact.user.firstName || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">{clientLocationContact.contact.user.lastName || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">{clientLocationContact.contact.user.email}</th>
                          <td className="text-sm whitespace-nowrap">
                            <b>{clientLocationContact.contact.user.phoneNumber}</b>
                          </td>
                          <th className="text-sm whitespace-nowrap">{clientLocationContact.contact.phoneNumberPerso || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">{clientLocationContact.contact.fonction}</th>
                          <th className="text-sm font-medium whitespace-nowrap">
                            {clientLocationContact?.contact.user.dateOfBirth
                              ? dateToFrench(clientLocationContact?.contact.user.dateOfBirth?.toString())
                              : "N/A"}
                          </th>
                          <th className="space-x-2 text-sm font-medium text-right">
                            <button type="button" title="Edit" className="hover:text-blue-500">
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteRef.current && deleteRef.current.open(clientLocationContact.id);
                              }}
                              type="button"
                              title="Delete"
                              className="text-error hover:text-error-active"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {clientLocationContacts.data && clientLocationContacts.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{clientLocationContacts.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
