import { OrderableByEnum, TypeEnum } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

import { DocumentModel } from "src/modules/models/document.model";

export const ProductModel = types.model("ProductModel", {
  id: types.identifier,
  documentId: types.maybeNull(types.string),
  document: types.maybe(types.maybeNull(DocumentModel)),
  securityDocumentId: types.maybeNull(types.string),
  securityDocument: types.maybe(types.maybeNull(DocumentModel)),
  description: types.maybeNull(types.string),
  reference: types.maybeNull(types.string),
  defaultQuantity: types.maybeNull(types.number),
  orderableBy: types.array(types.enumeration(Object.values(OrderableByEnum))),
  type: types.enumeration(Object.values(TypeEnum)),
});

export type ProductModelType = Instance<typeof ProductModel>;
