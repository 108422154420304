import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Expose, Type } from "class-transformer";
import { IsString } from "class-validator";

import { UserObject } from "../users/objects/user.object";

export class AgentObject {
  @Expose()
  @ApiProperty({
    description: "Admin's id",
    example: "6387d87d-468b-427d-aea6-571b32302ba6",
  })
  @IsString()
  id: string;

  ////////////////////////////////////////////////////////////////
  // Relations
  @Expose()
  @ApiProperty({
    description: "User related to the admin",
  })
  @Type(() => UserObject)
  user: UserObject;
}
