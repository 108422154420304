import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Expose, Type } from "class-transformer";
import { IsBoolean, IsNotEmpty, IsPhoneNumber, IsString } from "class-validator";

import { UserObject } from "../users/objects/user.object";

export class ContactsObject {
  @Expose()
  @ApiProperty({
    description: "Contact's id",
    example: "6387d87d-468b-427d-aea6-571b32302ba6",
  })
  @IsString()
  id: string;
  
  @Expose()
  @ApiProperty({
    description: "Contact's civility",
    example: "Mr",
  })
  @IsString()
  @IsNotEmpty()
  civility: string;

  @Expose()
  @ApiProperty({
    description: "Contact's fonction",
  })
  @IsString()
  @IsNotEmpty()
  fonction: string;

  @Expose()
  @ApiProperty({
    description: "User's phone number",
    example: "+33.1.23.45.67.89",
  })
  @IsPhoneNumber("FR")
  @IsNotEmpty()
  phoneNumberPerso: string;

  @Expose()
  @ApiProperty({
    description: "Contact's isMainContact",
  })
  @IsBoolean()
  @IsNotEmpty()
  isMainContact: boolean;
  ////////////////////////////////////////////////////////////////
  // Relations
  @Expose()
  @ApiProperty({
    description: "User related to the contact",
  })
  @Type(() => UserObject)
  user: UserObject;
}
