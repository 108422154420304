import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Expose } from "class-transformer";
import { IsString } from "class-validator";

export class DocumentObject {
  @Expose()
  @ApiProperty({
    description: "Document's id",
    example: "6387d87d-468b-427d-aea6-571b32302ba6",
  })
  @IsString()
  id: string;

  @Expose()
  @ApiProperty({
    description: "Extension of document",
    example: "png",
  })
  @IsString()
  extension: string;

  @Expose()
  @ApiProperty({
    description: "Minio key of document",
    example: "hola mundo/locations/9 rue d'Alsace,Paris/qrcode/m54CiMgqbfxf1-NSSUJz4.pdf",
  })
  @IsString()
  key: string;

  @Expose()
  @ApiProperty({
    description: "Minio bucket of document",
    example: "users",
  })
  @IsString()
  bucket: string;

  @Expose()
  @ApiProperty({
    description: "Minio bucket of document",
    example: "product name document",
  })
  @IsString()
  name: string;
}
