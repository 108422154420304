import { nanoid } from "nanoid";
import React, { useCallback } from "react";

interface PaginationProps {
  currentPage: number;
  maxPages: number;
  onPageChange: (page: number) => void;
}

const displayIndexes = (currentPage: number, maxPages: number) => {
  const indexes = [];

  const MAX_SLOTS = 7;
  const PRIMARY_INDEXES = 4;
  const INCOMING_SLOT_1 = 6;
  const END_INDEXES = maxPages - 6;
  const INCOMING_SLOT_2 = maxPages - 5;

  if (currentPage <= PRIMARY_INDEXES) {
    // First pages disposition
    for (let i = 1; i <= MAX_SLOTS && i <= maxPages; i++) {
      i === MAX_SLOTS ? indexes.push(maxPages) : i === INCOMING_SLOT_1 ? indexes.push(0) : indexes.push(i);
    }
  } else {
    if (maxPages - currentPage <= 3) {
      // End pages disposition
      for (let i = END_INDEXES; i <= maxPages; i++) {
        i === END_INDEXES ? indexes.push(1) : i === INCOMING_SLOT_2 ? indexes.push(0) : indexes.push(i);
      }
    } else {
      // Middle pages disposition
      indexes.push(1, 0, currentPage - 1, currentPage, currentPage + 1, 0, maxPages);
    }
  }
  return indexes;
};

export const Pagination = ({ currentPage, maxPages, onPageChange }: PaginationProps) => {
  const focusChange = useCallback(
    (newValue: number) => {
      onPageChange(newValue);
    },
    [onPageChange]
  );

  return (
    <div className="flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <nav aria-label="Pagination">
          <div className="btn-group">
            {displayIndexes(currentPage, maxPages).map((index: number) => (
              <button
                key={nanoid(10)}
                onClick={() => index && focusChange(index)}
                className={index !== currentPage && index !== 0 ? "btn btn-sm" : "btn btn-disabled btn-sm"}
              >
                {index ? index : "..."}
              </button>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};
