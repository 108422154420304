import { faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientLocationDataTypeEnum } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useStore } from "../../context/store.context";
import { DataGridDelete } from "../DataGrid/DataGridDelete";
import { WhereIsItem } from "./WhereIsItem";

const typesTable = [
  { key: ClientLocationDataTypeEnum.ACCESS_CODE, value: "Code d'accès" },
  { key: ClientLocationDataTypeEnum.ALARM, value: "Alarme" },
  { key: ClientLocationDataTypeEnum.CLEANING_PRODUCTS, value: "Matériels et produits" },
  { key: ClientLocationDataTypeEnum.ELECTRICITY, value: "Lumières" },
  { key: ClientLocationDataTypeEnum.GARBAGES, value: "Poubelles" },
  { key: ClientLocationDataTypeEnum.KEYS, value: "Clés" },
  { key: ClientLocationDataTypeEnum.QRCODE, value: "QR code" },
  { key: ClientLocationDataTypeEnum.SUBWAY, value: "Métro" },
  { key: ClientLocationDataTypeEnum.ADDRESS, value: "Adresse" },
  { key: ClientLocationDataTypeEnum.LOCKS, value: "Serrure" },
  { key: ClientLocationDataTypeEnum.CONSUMABLE, value: "Consommable" },
];
export const WhereIs = observer(() => {
  const { clientLocationId } = useParams();
  const { control, handleSubmit, setValue } = useForm();
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [type, setType] = useState<string>();
  const [typeList, setTypeList] = useState<any[]>();
  const [errorType, setErrorType] = useState(false);

  const {
    clientLocationDatas: {
      clientLocationDatas,
      clientLocationData,
      findClientLocationDatas,
      findOneClientLocationData,
      createClientLocationData,
      updateClientLocationDatas,
      uploadDocument,
      deleteDocument,
      deleteClientLocationData,
      clearClientLocationData,
    },
  } = useStore();

  useEffect(() => {
    if (clientLocationId) {
      findClientLocationDatas(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
      clearClientLocationData();
    }
  }, [clientLocationId]);

  const onSubmit = async (dataForm: any) => {
    if (type) {
      const data = {
        text: dataForm.text,
        type: type,
        clientLocationId: clientLocationId,
      };

      if (clientLocationId) {
        if (!clientLocationData) {
          await createClientLocationData(clientLocationId, data);
          await findClientLocationDatas(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
          setValue("text", "");
          setErrorType(false);
          clearClientLocationData();
        } else {
          await updateClientLocationDatas(clientLocationId, clientLocationData.id, data);
          await findClientLocationDatas(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
          setValue("text", "");
          clearClientLocationData();
        }
      }
      setIsNewEntry(!isNewEntry);
    } else {
      if (!type) {
        setErrorType(true);
      } else {
        setErrorType(false);
      }
    }
  };
  const uploadFile = async (files: FileList) => {
    if (type) {
      setErrorType(false);
      const item = {
        text: "",
        type: type,
        clientLocationId: clientLocationId,
      };
      if (clientLocationId) {
        if (clientLocationData == null) {
          const newClientLocationData = await createClientLocationData(clientLocationId, item);
          await uploadDocument(clientLocationId, newClientLocationData.id, files);
          await findOneClientLocationData(newClientLocationData.id);
        } else {
          await uploadDocument(clientLocationId, clientLocationData.id, files);
          await findOneClientLocationData(clientLocationData.id);
        }
      }
    } else {
      setErrorType(true);
    }
  };

  const onDeleteDocument = async (entityId: string) => {
    if (clientLocationId && clientLocationData) {
      await deleteDocument(entityId);
      findOneClientLocationData(clientLocationData.id);
    }
  };

  const getType = () => {
    if (clientLocationDatas) {
      const typesFiltered = typesTable.filter((typeTable) => {
        return !clientLocationDatas.find((clientLocationData) => clientLocationData.type === typeTable.key);
      });
      setTypeList(typesFiltered);
    }
  };

  const cancel = async () => {
    if (clientLocationId && clientLocationData) {
      await deleteClientLocationData(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`, clientLocationData.id);
    }
    setIsNewEntry(!isNewEntry);
    clearClientLocationData();
    setErrorType(false);
    setValue("text", "");
  };
  const deleteRefDocument = useRef<ElementRef<typeof DataGridDelete>>(null);

  const vidRef = useRef(null);

  return (
    <div className="mb-auto">
      <DataGridDelete
        ref={deleteRefDocument}
        onDelete={onDeleteDocument}
        title="Supprimer un document"
        description={() => `Êtes vous sur de vouloir supprimer ce document?`}
      />
      <div className="container m-auto">
        <div className="border collapse border-base-300 bg-base-100">
          <div className="text-xl font-medium text-center collapse-title">Où est ?</div>
        </div>
        {clientLocationDatas &&
          clientLocationDatas.map((clientLocationData: any) => {
            return <WhereIsItem key={clientLocationData.id} data={clientLocationData} />;
          })}

        {isNewEntry && (
          <div tabIndex={0} className="border collapse collapse-open border-base-300 bg-base-100">
            <input type="checkbox" />
            <div className="flex justify-between text-lg font-medium collapse-title">
              <div>Nouveau &quot;où est&quot; </div>
            </div>
            <div className="collapse-content">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row space-x-2 ">
                  <div className="flex flex-col basis-2/4">
                    <label className="label">
                      <span className="font-bold label-text">Type</span>
                    </label>
                    {errorType && <div className="text-error">Champs obligatoire pour créer une consigne</div>}
                    <select
                      className={
                        errorType ? "border-error w-full select select-sm select-bordered" : "w-full select select-sm select-bordered"
                      }
                      onChange={(newValue) => {
                        setType(newValue.target.value);
                      }}
                    >
                      <option disabled selected>
                        Type
                      </option>
                      {typeList &&
                        typeList.map((type) => {
                          return (
                            <option key={type.key} value={type.key}>
                              {type.value}
                            </option>
                          );
                        })}
                    </select>
                    <label className="mt-3 label">
                      <span className="font-bold label-text">Description</span>
                    </label>
                    <Controller
                      control={control}
                      name={"text"}
                      render={({ field: { onChange, value = "" } }) => (
                        <textarea
                          className={"w-full h-full flex-full textarea input input-bordered basis-2/4"}
                          value={value}
                          onChange={onChange}
                          placeholder={"Description"}
                        />
                      )}
                    />
                  </div>
                  {clientLocationData &&
                    clientLocationData.documents &&
                    clientLocationData.documents?.length != 0 &&
                    clientLocationData.documents.map((document: any) => {
                      return (
                        <div key={document.id}>
                          {document.document.key != null && (
                            <div>
                              <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100">
                                <label className="w-20 rounded" htmlFor={"modal-" + document.document.id}>
                                  {document.document.extension == "mp4" ? (
                                    <video ref={vidRef}>
                                      <source src={document.document.key} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img src={document.document.key} />
                                  )}

                                  <label
                                    className="absolute top-0 right-0 border-none btn btn-sm btn-circle bg-error"
                                    onClick={() => {
                                      deleteRefDocument.current && deleteRefDocument.current.open(document.id);
                                    }}
                                  >
                                    ✕
                                  </label>
                                </label>
                              </div>

                              <input type="checkbox" id={"modal-" + document.document.id} className="modal-toggle" />
                              <div className="modal">
                                <div className="relative modal-box">
                                  <label htmlFor={"modal-" + document.document.id} className="absolute btn btn-sm btn-circle right-2 top-2">
                                    ✕
                                  </label>

                                  <div className="flex justify-center">
                                    {document.document.extension == "mp4" ? (
                                      <video ref={vidRef} controls>
                                        <source src={document.document.key} type="video/mp4" />
                                      </video>
                                    ) : (
                                      <img src={document.document.key} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100 hover:text-blue-500 hover:border-blue-500 hover:border">
                    <label className="flex w-5 m-auto rounded">
                      <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10" />
                      <input
                        type="file"
                        accept="image/*,video/mp4"
                        id="file1"
                        className="hidden"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.files) {
                            uploadFile(event.target.files);
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    type="button"
                    className="mr-2 normal-case border-none btn btn-sm bg-error"
                    onClick={() => {
                      cancel();
                    }}
                  >
                    Annuler
                  </button>
                  <button className="normal-case border-none btn btn-sm bg-success" type="submit">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        <div
          tabIndex={0}
          className="border cursor-pointer collapse border-base-300 bg-base-100"
          onClick={() => {
            setIsNewEntry(!isNewEntry);
            getType();
          }}
        >
          <div className="flex justify-between text-xl font-medium collapse-title hover:text-blue-500">
            <div className="flex w-5 m-auto">
              <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
