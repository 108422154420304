import { flow, getEnv, types } from "mobx-state-tree";

import { RequestPasswordDto, ResetPasswordDto } from "src/modules/api";

import { RootStoreEnv } from "./root.store";

export const UserStore = types
  .model("UserStore")
  .actions((self) => ({
    resetPassword: flow(function* _(data: ResetPasswordDto) {
      const {
        api: { users: userApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield userApi.resetPassword(data);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    requestPassword: flow(function* _(data: RequestPasswordDto) {
      const {
        api: { users: userApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield userApi.requestPassword(data);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
  }))
  .views(() => ({}));

export const userStore = UserStore.create();
