/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createClientLocationApi(axios: AxiosInstance) {
  return {
    async fetchClientLocations(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: "/clientLocations",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchAllClientLocations(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: "/clientLocations/all",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneClientLocation(entityId: string) {
      return axios({
        url: `/clientLocations/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async updateClientLocation(entityId: string, data: Partial<any>) {
      return axios({
        url: `/clientLocations/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async createClientLocation(data: Partial<any>) {
      return axios({
        url: `/clientLocations/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async deleteClientLocation(entityId: string) {
      return axios({
        url: `/clientLocations/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
    async generateQrCode(entityId: string) {
      return axios({
        url: `/clientLocations/${entityId}/generateQrCode`,
        method: "POST",
      }).then((response) => response.data);
    },
  };
}

export type ClientLocationApi = ReturnType<typeof createClientLocationApi>;
