/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { PaginatedModels } from "src/modules/models";
import { OrderModel } from "src/modules/models/order.model";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const OrderReference = createEntityReference("orders", OrderModel);

export const OrdersStore = createEntityStore("Order", OrderModel)
  .props({
    orders: types.maybe(PaginatedModels(OrderReference)),
    order: types.maybeNull(OrderModel),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { orders: orderApi },
      } = getEnv<RootStoreEnv>(self);

      const order = yield orderApi.fetchOneOrder(entityId);

      self.order = order;
      return OrderModel.create({
        rawProcuct: order,
        ...order,
      });
    }),
    updateOrder: flow(function* (entityId: string, updateOrderData: any) {
      const {
        api: { orders: orderApi },
      } = getEnv<RootStoreEnv>(self);

      const order = yield orderApi.updateOrder(entityId, updateOrderData);
      self.order = order;
      return order;
    }),

    generateDeliveryOrder: flow(function* (orderId: string) {
      const {
        api: { orders: orderApi },
      } = getEnv<RootStoreEnv>(self);
      const order = yield orderApi.generateDeliveryOrder(orderId);
      self.order = order;
      return order;
    }),
    findOrders: flow(function* (findOrderData: any) {
      const {
        api: { orders: orderApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield orderApi.findOrders(findOrderData);

      const orders = result.data.map((order: any) => {
        const entityModel = self.cacheEntity(
          OrderModel.create({
            rawOrder: order,
            ...(order as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.orders = cast({
        ...result,
        data: cast(orders as any),
      });

      return self.orders as PaginatedModels<typeof OrderModel>;
    }),
  }))
  .views(() => ({}));

export const ordersStore = OrdersStore.create({
  order: null,
}) as any;
