/* eslint-disable @typescript-eslint/no-explicit-any */
import { flow, getEnv, types } from "mobx-state-tree";

import { TrainingModel } from "src/modules/models";

import { RootStoreEnv } from "./root.store";

export const TrainingsStore = types
  .model("Training", {
    trainings: types.maybeNull(types.array(TrainingModel)),
    training: types.maybeNull(TrainingModel),
  })
  .actions((self) => ({
    findTrainings: flow(function* _() {
      const {
        api: { trainings },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield trainings.findTrainings();
        self.trainings = result;
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    findOneTraining: flow(function* _(entityId: string) {
      const {
        api: { trainings },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield trainings.findOneTraining(entityId);
        self.training = result;

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    clearTraining() {
      self.training = null;
    },
  }))
  .actions((self) => ({
    updateTrainings: flow(function* _(entityId: string, data: any) {
      const {
        api: { trainings },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield trainings.updateTrainings(entityId, data);
        self.findTrainings();

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    uploadDocument: flow(function* _(entityId: string, files: FileList) {
      const {
        api: { trainings },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield trainings.uploadDocument(entityId, files);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteDocument: flow(function* _(entityId: string) {
      const {
        api: { trainings },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield trainings.deleteDocument(entityId);
        self.findTrainings();

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteTraining: flow(function* _(entityId: string) {
      const {
        api: { trainings },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield trainings.deleteTraining(entityId);
        self.findTrainings();

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    createTraining: flow(function* _(data: any) {
      const {
        api: { trainings },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield trainings.createTraining(data);
        self.findOneTraining(result.id);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
  }))
  .views(() => ({}));

export const trainingsStore = TrainingsStore.create({
  trainings: [],
  training: null,
});
