import { faCirclePlus, faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { Loader } from '../../components/Loader/Loader';
import { useStore } from "../../context/store.context";
import { TrainingItem } from "./TrainingItem";

export const Trainings = observer(() => {
  const { control, handleSubmit, setValue } = useForm();
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    trainings: {
      trainings,
      training,
      findTrainings,
      updateTrainings,
      findOneTraining,
      createTraining,
      uploadDocument,
      deleteDocument,
      deleteTraining,
      clearTraining,
    },
  } = useStore();

  useEffect(() => {
    (async () => {
    await findTrainings();
    setIsLoading(true);
  })();
  }, []);

  const onSubmit = async (dataForm: any) => {
    if (dataForm.text && dataForm.title) {
      const data = {
        title: dataForm.title,
        text: dataForm.text,
      };
      if (!training) {
        await createTraining(data);
        await findTrainings();
        setValue("title", "");
        setValue("text", "");
      } else {
        await updateTrainings(training.id, data);
        await findTrainings();
        setValue("title", "");
        setValue("text", "");
      }
      setIsNewEntry(!isNewEntry);
      clearTraining();
    } else {
      if (!dataForm.title) {
        setErrorTitle(true);
      } else {
        setErrorTitle(false);
      }
      if (!dataForm.text) {
        setErrorText(true);
      } else {
        setErrorText(false);
      }
    }
  };
  const uploadFile = async (files: FileList) => {
    const item = {
      text: "",
    };
    if (training == null) {
      const newTraining = await createTraining(item);
      await uploadDocument(newTraining.id, files);
      await findOneTraining(newTraining.id);
    } else {
      await uploadDocument(training.id, files);
    }
  };

  const onDeleteDocument = async (entityId: string) => {
    await deleteDocument(entityId);
  };

  const cancel = async () => {
    if (training) {
      await deleteTraining(training.id);
    }
    setIsNewEntry(!isNewEntry);
    clearTraining();
    setErrorText(false);
    setErrorTitle(false);
    setValue("title", "");
    setValue("text", "");
  };

  const deleteRefDocument = useRef<ElementRef<typeof DataGridDelete>>(null);

  const vidRef = useRef(null);

  return (
    <>
      {isLoading ? (
        <div className="mt-5 flex-1">
          <DataGridDelete
            ref={deleteRefDocument}
            onDelete={onDeleteDocument}
            title="Supprimer un document"
            description={() => `Êtes vous sur de vouloir supprimer ce document?`}
          />
          <div className="container m-auto">
            <div className="border collapse border-base-300 bg-base-100">
              <div className="text-xl font-medium text-center collapse-title">Formations</div>
            </div>
            {trainings &&
              trainings.map((training: any) => {
                return <TrainingItem key={training.id} training={training} />;
              })}

            {isNewEntry && (
              <div tabIndex={0} className="border collapse collapse-open border-base-300 bg-base-100">
                <input type="checkbox" />
                <div className="flex justify-between text-lg font-medium collapse-title">
                  <div>Nouvelle formation</div>
                </div>
                <div className="collapse-content">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {errorTitle && <div className="text-error">Champs obligatoire pour créer une formation</div>}

                    <div className="flex flex-row space-x-2">
                      <div className="flex flex-col basis-2/4">
                        <Controller
                          control={control}
                          name={"title"}
                          render={({ field: { onChange, value = "" } }) => (
                            <input
                              type="text"
                              className={
                                errorTitle
                                  ? "border-error w-full mt-2 flex-full input input-bordered"
                                  : "w-full mt-2 flex-full input input-bordered"
                              }
                              value={value}
                              onChange={onChange}
                              placeholder={"Titre"}
                            />
                          )}
                        />
                        {errorText && <div className="text-error">Champs obligatoire pour créer une formation</div>}

                        <Controller
                          control={control}
                          name={"text"}
                          render={({ field: { onChange, value = "" } }) => (
                            <textarea
                              className={
                                errorText
                                  ? "border-error w-full h-full mt-2 flex-full textarea input input-bordered"
                                  : "w-full h-full mt-2 flex-full textarea input input-bordered"
                              }
                              value={value}
                              onChange={onChange}
                              placeholder={"Description de la consigne"}
                            />
                          )}
                        />
                      </div>
                      {training &&
                        training.documents &&
                        training.documents?.length != 0 &&
                        training.documents.map((document: any) => {
                          return (
                            <div key={document.id}>
                              {document.document.key != null && (
                                <div>
                                  <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100">
                                    <label className="w-20 h-full rounded" htmlFor={"modal-" + document.document.id}>
                                      {document.document.extension == "mp4" ? (
                                        <video ref={vidRef}>
                                          <source src={document.document.key} type="video/mp4" />
                                        </video>
                                      ) : document.document.extension == "pdf" ? (
                                        <div className="flex justify-center m-auto">
                                          <div>
                                            <FontAwesomeIcon className="fa-3x" icon={faFilePdf} />
                                          </div>
                                        </div>
                                      ) : (
                                        <img src={document.document.key} />
                                      )}

                                      <label
                                        className="absolute top-0 right-0 border-none btn btn-sm btn-circle bg-error"
                                        onClick={() => {
                                          deleteRefDocument.current && deleteRefDocument.current.open(document.id);
                                        }}
                                      >
                                        ✕
                                      </label>
                                    </label>
                                  </div>

                                  <input type="checkbox" id={"modal-" + document.document.id} className="modal-toggle" />
                                  <div className="modal">
                                    <div className="relative modal-box">
                                      <label
                                        htmlFor={"modal-" + document.document.id}
                                        className="absolute btn btn-sm btn-circle right-2 top-2"
                                      >
                                        ✕
                                      </label>

                                      <div className="flex justify-center">
                                        {document.document.extension == "mp4" ? (
                                          <video ref={vidRef} controls>
                                            <source src={document.document.key} type="video/mp4" />
                                          </video>
                                        ) : document.document.extension == "pdf" ? (
                                          <a href={document.document.key}>Download Pdf</a>
                                        ) : (
                                          <img src={document.document.key} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100 hover:text-blue-500 hover:border-blue-500 hover:border">
                        <label className="flex w-5 m-auto rounded">
                          <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
                          <input
                            type="file"
                            accept="video/mp4,application/pdf"
                            id="file1"
                            className="hidden"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              if (event.target.files) {
                                uploadFile(event.target.files);
                              }
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-end mt-2">
                      <button
                        type="button"
                        className="mr-2 normal-case border-none btn btn-sm bg-error"
                        onClick={() => {
                          cancel();
                        }}
                      >
                        Annuler
                      </button>
                      <button className="normal-case border-none btn btn-sm bg-success" type="submit">
                        Enregistrer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div
              tabIndex={0}
              className="border cursor-pointer collapse border-base-300 bg-base-100 hover:text-blue-500"
              onClick={() => setIsNewEntry(!isNewEntry)}
            >
              <div className="flex justify-between text-xl font-medium collapse-title">
                <div className="flex w-5 m-auto">
                  <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 " />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
