/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { AuthenticateDto } from "src/modules/api";
import { AdminModel, PaginatedModels } from "src/modules/models";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const AdminReference = createEntityReference("admins", AdminModel);

export const AdminsStore = createEntityStore("Admin", AdminModel)
  .props({
    admins: types.maybe(PaginatedModels(AdminReference)),
    currentAdmin: types.maybeNull(AdminModel),
    isLoggedIn: types.optional(types.boolean, () => false),
  })
  .actions((self) => ({
    authenticate: flow(function* _(data: AuthenticateDto) {
      const {
        api: { admins: adminApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield adminApi.authenticate(data);
        if (result.accessToken) {
          self.isLoggedIn = true;
          localStorage.setItem("is-logged", "true");
        }
        return result;
      } catch (error) {
        console.error("[Auth]", error);
      }
    }),
    fetchCurrentAdmin: flow(function* _() {
      const {
        api: { admins: adminApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const admin = yield adminApi.current();
        self.currentAdmin = admin;
        return admin;
      } catch (error) {
        console.log("error", error);
        self.isLoggedIn = false;
      }
    }),
    clearCurrentAdmin() {
      self.currentAdmin = null;
    },
    logout() {
      const {
        api: { authentication },
      } = getEnv<RootStoreEnv>(self);
      try {
        authentication.logout();
        self.isLoggedIn = false;
        self.currentAdmin = null;
        localStorage.removeItem("is-logged");
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  }))
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { admins: adminApi },
      } = getEnv<RootStoreEnv>(self);

      const admin = yield adminApi.fetchOneAdmin(entityId);

      return AdminModel.create({
        rawAdmin: admin,
        ...admin,
      });
    }),

    findAdmins: flow(function* (findAdminData?: any) {
      const {
        api: { admins: adminApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield adminApi.fetchAdmins(findAdminData);

      const admins = result.data.map((admin: any) => {
        const entityModel = self.cacheEntity(
          AdminModel.create({
            rawAdmin: admin,
            ...(admin as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.admins = cast({
        ...result,
        data: cast(admins as any),
      });

      return self.admins as PaginatedModels<typeof AdminModel>;
    }),
  }))
  .views((self) => ({
    get isAuthenticated() {
      return self.isLoggedIn && !!self.currentAdmin;
    },
    get adminAuthenticatedAdmin() {
      return self.currentAdmin ? self.currentAdmin.user.email : '';
    },
  }));

export const adminsStore = AdminsStore.create({
  isLoggedIn: localStorage.getItem("is-logged") ? true : false,
});
