/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

export function createClientLocationCheckinsApi(axios: AxiosInstance) {
  return {
    async createClientLocationCheckin(data: Partial<any>) {
      return axios({
        url: `/clientLocationCheckIns`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async updateClientLocationCheckin(entityId: string, data: Partial<any>) {
      return axios({
        url: `/clientLocationCheckIns/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async fetchClientLocationCheckins(params: string) {
      return axios({
        url: "/clientLocationCheckIns",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneClientLocationCheckin(entityId: string) {
      return axios({
        url: `/clientLocationCheckIns/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async createDownloadClientLocationCheckins(data: any) {
      return axios({
        url: `/clientLocationCheckIns/download`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async getDownloadClientLocationCheckins(token: string) {
      return axios({
        url: `/clientLocationCheckIns/download?token=${encodeURIComponent(token)}`,
        method: "GET",
        responseType: "arraybuffer",
      }).then((response) => response.data);
    },
  };
}

export type ClientLocationCheckinsApi = ReturnType<typeof createClientLocationCheckinsApi>;
