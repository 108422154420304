import { ClientLocationDataTypeEnum, DocumentObject } from '@platinium/shared';
import { Instance, types } from "mobx-state-tree";

export const ClientLocationDataModel = types.model("ClientLocationDataModel", {
  id: types.identifier,
  text: types.maybeNull(types.string),
  documents: types.maybeNull(types.array(types.frozen<DocumentObject>())),
  type: types.enumeration(Object.values(ClientLocationDataTypeEnum)),
});

export type ClientLocationDataModelType = Instance<typeof ClientLocationDataModel>;
