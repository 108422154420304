import { faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgentModelType, UserModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from "../../components/Loader/Loader";
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";

const columns = [
  {
    label: "Prenom",
    name: "user.firstName",
  },
  {
    label: "Nom",
    name: "user.lastName",
  },
  {
    label: "Site Assignés",
    name: "clientLocationAgent.clientLocation.name",
  },
  {
    label: "RDV",
    name: "meetings.status",
  },
  {
    label: "Signalement(s)",
    name: "problems.id",
  },
  {
    label: "",
    name: "actions",
  },
];

const sortableColumns = ["user.firstName", "user.lastName"];

export const Agents = observer(() => {
  const { agents: agentsStore } = useStore();
  const { agents: agentsApi } = useApi();
  const {
    agents: { clearAgent },
  } = useStore();
  const navigate = useNavigate();
  const {
    search,
    setSearch,
    entities: agents,
    pages,
    currentPage,
    setCurrentPage,
    ordering,
    setOrdering,
  } = useDataGrid({
    findFunction: agentsStore.findAgents,
    searchBuilder: useCallback((search) => ({
      $and: [
        {
          $or: [
            {
              "user.firstName": {
                $contL: search,
              },
            },
            {
              "user.lastName": {
                $contL: search,
              },
            },
            {
              "user.email": {
                $contL: search,
              },
            },
          ],
        },
      ],
    }), []),
  });

  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  useEffect(() => {
    clearAgent();
  }, []);

  const onDelete = useCallback(
    async (data: AgentModelType) => {
      await agentsApi.deleteAgent(data.id);
      agents.reload();
    },
    [agents, agentsApi]
  );

  const descriptionName = (user: UserModelType) => {
    const { firstName, lastName } = user || {};
    return firstName || lastName ? `${firstName || ""} ${lastName || ""}` : "cet agent ";
  };

  return (
    <>
      {agents.data ? (
        <div className="flex-1">
          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer un agent"
            description={(data) => `Êtes vous sur de vouloir supprimer ${descriptionName(data?.user)}?`}
          />
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader
                searchPlaceholder="Chercher un agent par nom, prénom, email"
                search={search}
                onSearchChange={setSearch}
                actions={
                  <button onClick={() => navigate("/agent")} type="button" className="btn btn-sm">
                    Nouvel agent
                  </button>
                }
              />
              <div className="overflow-x-auto">
                <DataGridTable
                  dataProvider={agents}
                  columns={columns}
                  ordering={ordering}
                  onOrderingChange={setOrdering}
                  sortableColumns={sortableColumns}
                >
                  {(data) =>
                    data.data.map((agent: any) => {
                      return (
                        <tr
                          key={agent.id}
                          className="cursor-pointer hover"
                          onClick={() => {
                            navigate("/agent?agentId=" + agent.id);
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">{agent.user.firstName || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">{agent.user.lastName || "N/A"}</th>
                          <th className="text-sm truncate whitespace-normal">
                            {agent.clientLocationAgents?.length > 0
                              ? agent.clientLocationAgents.map((clientLocationAgent: any, index: number) => {
                                  return (
                                    <span key={clientLocationAgent.id}>
                                      {(index ? ", " : "") + clientLocationAgent.clientLocation.name}
                                    </span>
                                  );
                                })
                              : "N/A"}
                          </th>
                          <th className="text-sm whitespace-nowrap">{agent.meetings?.length != 0 ? "OUI" : "NON"}</th>
                          <th className="text-sm whitespace-nowrap">{agent.problems?.length != 0 ? "OUI" : "NON"}</th>
                          <th className="space-x-2 text-sm font-medium text-right">
                            <button
                              onClick={() => {
                                navigate("/agent?agentId=" + agent.id);
                              }}
                              type="button"
                              title="Edit"
                              className="hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteRef.current && deleteRef.current.open(agent);
                              }}
                              type="button"
                              title="Delete"
                              className="text-error hover:text-error-active"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {agents.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{agents.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
