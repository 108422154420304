/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { PaginatedModels } from "src/modules/models";
import { OperationModel } from "src/modules/models/operation.model";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const OperationReference = createEntityReference("operations", OperationModel);

export const OperationsStore = createEntityStore("Operation", OperationModel)
  .props({
    operations: types.maybe(PaginatedModels(OperationReference)),
    operation: types.maybeNull(OperationModel),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { operations: operationApi },
      } = getEnv<RootStoreEnv>(self);

      const operation = yield operationApi.fetchOneOperation(entityId);

      self.operation = operation;
      return OperationModel.create({
        rawOperation: operation,
        ...operation
      });
    }),
    updateOperation: flow(function* (entityId: string, updateOperationData: any) {
      const {
        api: { operations: operationApi },
      } = getEnv<RootStoreEnv>(self);

      const operation = yield operationApi.updateOperation(entityId, updateOperationData);
      self.operation = operation;
      return operation;
    }),

    generatePrivateOperationReport: flow(function* (operationId: string) {
      const {
        api: { operations: operationApi },
      } = getEnv<RootStoreEnv>(self);
      const { key, signedUrl } = yield operationApi.generatePrivateOperationReport(operationId);
      // Update the store with any relevant information, if needed.
      return { key, signedUrl };
    }),

    generatePublicOperationReport: flow(function* (operationId: string) {
      const {
        api: { operations: operationApi },
      } = getEnv<RootStoreEnv>(self);
      const { key, signedUrl } = yield operationApi.generatePublicOperationReport(operationId);
      // Update the store with any relevant information, if needed.
      return { key, signedUrl };
    }),

    findOperations: flow(function* (findOperationData: any) {
      const {
        api: { operations: operationApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield operationApi.findOperations(findOperationData);

      const operations = result.data.map((operation: any) => {
        const entityModel = self.cacheEntity(
          OperationModel.create({
            rawOperation: operation,
            ...operation,
          }),
          true
        );
        return entityModel.id;
      });

      self.operations = cast({
        ...result,
        data: cast(operations as any),
      });

      return self.operations as PaginatedModels<typeof OperationModel>;
    }),
  }))
  .views(() => ({}));

export const operationsStore = OperationsStore.create({
  operation: null,
}) as any;
