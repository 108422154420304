/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

export function createOrderProductApi(axios: AxiosInstance) {
  return {
    async deleteOrderProduct(entityId: string) {
      return axios({
        url: `/orderProducts/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async createOrderProduct(data: any) {
      return axios({
        url: `/orderProducts`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async fetchOrderProducts(params: string) {
      return axios({
        url: `/orderProducts`,
        method: "GET",
        params
      }).then((response) => response.data);
    },
    async updateOrderProduct(entityId: string, data: Partial<any>) {
      return axios({
        url: `/orderProducts/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
  };
}

export type OrderProductApi = ReturnType<typeof createOrderProductApi>;
