import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import LogoPlatinium from "../../assets/images/platinium-logo/Exe_Logo_Platinium_Haut.svg";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";

interface FormData {
  confirmPassword: string;
  password: string;
}
export const Settings = observer(() => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    admins: { authenticate, currentAdmin },
  } = useStore();

  const { users } = useApi();

  const handleChange = () => {
    setPasswordShown(!passwordShown);
  };

  const authenticateAdmin = async (data: FormData) => {
    if (!currentAdmin) return;
    await users.updateUser(currentAdmin.user.id, { password: data.password });
    await authenticate({
      email: currentAdmin.user.email,
      password: data.password,
    });
    navigate("/");
  };

  return (
    <div className="flex-1">
      <div className="flex h-full">
        <div className="m-auto">
          <div className="m-5">
            <div className="flex justify-center">
              <img src={LogoPlatinium} className="w-60" alt="Platinium logo"></img>
            </div>
            <h1 className="my-10 text-lg text-center">Changer votre mot de passe</h1>
            <form onSubmit={handleSubmit(authenticateAdmin)} className="space-y-2 ">
              <Controller
                control={control}
                name="password"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value = "" } }) => (
                  <input
                    type={passwordShown ? "text" : "password"}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="Rentrez votre nouveau mot de passe"
                    className="w-full input input-bordered"
                  />
                )}
              />
              <Controller
                control={control}
                name="confirmPassword"
                rules={{
                  required: true,
                  validate: (val: string) => {
                    if (watch("password") != val) {
                      return "Les mots de passes ne sont pas identiques";
                    }
                  },
                }}
                render={({ field: { onChange, onBlur, value = "" } }) => (
                  <input
                    type={passwordShown ? "text" : "password"}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="Rentrez votre nouveau mot de passe à nouveau"
                    className="w-full input input-bordered"
                  />
                )}
              />
              <p className="text-error">{errors.confirmPassword?.message}</p>
              <label className="justify-start cursor-pointer label">
                <input type="checkbox" checked={passwordShown} onChange={handleChange} className="checkbox" />
                <span className="pl-2 label-text">Montrer le mot de passe</span>
              </label>
              <div className="flex justify-center">
                <button className="btn" type="submit">
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
