import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import LogoPlatinium from "../../assets/images/platinium-logo/Exe_Logo_Platinium_Haut.svg";
import { useStore } from "../../context/store.context";

interface FormData {
  email: string;
}

export const RequestPassword = () => {
  const { control, handleSubmit } = useForm<FormData>();
  const navigate = useNavigate();
  const {
    users: { requestPassword },
  } = useStore();

  const onSubmit = (data: FormData) => {
    requestPassword({email: data.email});
    navigate("/login");
  };

  return (
    <div className="flex min-h-screen">
      <div className="m-auto">
        <div className="flex justify-center pb-10">
          <img src={LogoPlatinium} className="w-60" alt="Platinium logo"></img>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 ">
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value = "" } }) => (
              <input
                type="email"
                onBlur={onBlur}
                onChange={onChange}
                placeholder={"email"}
                value={value}
                className="w-full input input-bordered"
              ></input>
            )}
          />
          <div className="flex justify-center">
            <button className="mt-3 btn" type="submit">
              Envoyer un email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
