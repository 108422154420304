/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { ProblemModelType } from "src/modules/models";

export function createProblemApi(axios: AxiosInstance) {
  return {
    async findProblems(params?: any) {
      return axios({
        url: `/problems/`,
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async findOneProblem(entityId: string) {
      return axios({
        url: `/problems/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async findProblemDocuments(entityId: string) {
      return axios({
        url: "/problemDocuments/",
        method: "GET",
        data: entityId,
      }).then((response) => response.data);
    },
    async uploadDocument(entityId: string, file: File) {
      const bodyFormData = new FormData();
      bodyFormData.append(`files`, file);

      bodyFormData.append(`problemId`, entityId);

      return axios({
        url: `/problemDocuments/upload`,
        method: "POST",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data);
    },
    async updateProblems(entityId: string, data: ProblemModelType) {
      return axios({
        url: `/problems/${entityId}`,
        method: "PATCH",
        data: data,
      }).then((response) => response.data);
    },
    async deleteDocument(entityId: string) {
      return axios({
        url: `/problemDocuments/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async deleteProblem(entityId: string) {
      return axios({
        url: `/problems/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async createProblem(data: ProblemModelType) {
      return axios({
        url: `/problems/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
  };
}

export type ProblemApi = ReturnType<typeof createProblemApi>;
