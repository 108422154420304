/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";
import { ClientLocationDataModelType } from "src/modules/models/clientLocationData.model";

export function createClientLocationDataApi(axios: AxiosInstance) {
  return {
    async fetchClientLocationDatas(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: `/clientLocationDatas/`,
        method: "GET",
        params
      }).then((response) => response.data);
    },
    async fetchOneClientLocationData(entityId: string) {
      return axios({
        url: `/clientLocationDatas/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async createClientLocationData(data: ClientLocationDataModelType) {
      return axios({
        url: `/clientLocationDatas/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async uploadDocument(entityId: string, files: FileList) {
      const bodyFormData = new FormData();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        bodyFormData.append(`files`, file);
      }
      bodyFormData.append(`clientLocationDataId`, entityId);
      return axios({
        url: `/clientLocationDataDocuments/upload`,
        method: "POST",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data);
    },
    async updateClientLocationDatas(entityId: string, data: ClientLocationDataModelType) {
      return axios({
        url: `/clientLocationDatas/${entityId}`,
        method: "PATCH",
        data: data,
      }).then((response) => response.data);
    },
    async deleteDocument(entityId: string) {
      return axios({
        url: `/clientLocationDataDocuments/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async deleteClientLocationData(entityId: string) {
      return axios({
        url: `/clientLocationDatas/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
  };
}

export type ClientLocationDataApi = ReturnType<typeof createClientLocationDataApi>;
