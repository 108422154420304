/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { ContactModel, PaginatedModels } from "src/modules/models";
import { createEntityReference, createEntityStore } from 'src/modules/stores/entity.store';

import { RootStoreEnv } from "./root.store";
export const ContactReference = createEntityReference("contacts", ContactModel);

export const ContactsStore = createEntityStore("Contact", ContactModel)
  .props({
    contacts: types.maybe(PaginatedModels(ContactReference)),
    contact: types.maybeNull(ContactModel),
  })
  .actions((self) => ({
    findContacts: flow(function* (findContactData?: any) {
      const {
        api: { contacts: contactsApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield contactsApi.fetchContacts(findContactData);

      const contacts = result.data.map((contact: any) => {
        const entityModel = self.cacheEntity(
          ContactModel.create({
            rawAdmin: contact,
            ...(contact as any),
          }),
          true
        );
        return entityModel.id;
      });
      self.contacts = cast({
        ...result,
        data: cast(contacts as any),
      });

      return self.contacts as PaginatedModels<typeof ContactModel>;
    }),
    findOneContact: flow(function* _(entityId: string) {
      const {
        api: {  contacts: contactsApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield contactsApi.fetchOneContact(entityId);
        self.contact = result;

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    clearContact() {
      self.contact = null;
    },
  }))
  .actions((self) => ({
    updateContact: flow(function* _(entityId: string, data: any) {
      const {
        api: {  contacts: contactsApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield contactsApi.updateContact(entityId, data);
        self.findOneContact(entityId);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteContact: flow(function* _(clientLocationId: string, entityId: string) {
      const {
        api: {  contacts: contactsApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield contactsApi.deleteContact(entityId);
        self.findContacts(clientLocationId);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    createContact: flow(function* _(data: any) {
      const {
        api: {  contacts: contactsApi },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield contactsApi.createContact(data);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
  }))
  .views(() => ({}));

export const contactsStore = ContactsStore.create({
  contact: null,
});
