/* eslint-disable @typescript-eslint/no-explicit-any */
import { faDownload, faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderModelType } from "@platinium/cdk";
import { StatusOrderEnum } from "@platinium/shared";
import { map } from 'lodash';
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAsync } from "react-async";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useDebounce } from "use-debounce";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from '../../components/Loader/Loader';
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";

const columns = [
  {
    label: "Nom du client",
    name: "clientLocation.client.name",
  },
  {
    label: "Statut",
    name: "status",
  },
  {
    label: "Passé par",
    name: "source",
  },
  {
    label: "",
    name: "actions",
  },
];

const sortableColumns = ["clientLocation.client.name", "status", "source"];

const statusOptions = [
  {
    value: "SENT",
    label: "en livraison",
  },
  {
    value: "PROCESSING",
    label: "à traiter",
  },
  {
    value: "RECEIVED",
    label: "livré",
  },
];
interface SelectedOption {
  name: string;
  value: string;
}
export const Orders = observer(() => {
  const { orders: ordersStore } = useStore();
  const { orders: ordersApi } = useApi();
  const navigate = useNavigate();
  const [statusOption, setStatusOption] = useState<SelectedOption | null>(null);
  const [ordering, setOrdering] = useState<Record<string, "ASC" | "DESC">>({});

  const [search, setSearch] = useState<string>("");
  const limit = 10;
  const [itemsPerPage] = useState<number>(limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { value: status } = statusOption || {};
  const searchBuilder = (search: string) => ({
    $and: [
      {
        $or: [
          {
            "clientLocation.name": {
              $contL: search,
            },
          },
          {
            "clientLocation.client.name": {
              $contL: search,
            },
          },
        ],
      },
      ...(!status
        ? [
          {
            status: {
              $ne: "RECEIVED",
            },
          },
        ]
        : []),
      ...(status
        ? [
          {
            status: {
              $contL: status,
            },
          },
        ]
        : []),
    ],
  });

  const [findEntities, { flush }] = useDebounce(
    useCallback(async () => {
      return ordersStore.findOrders({
        offset: (currentPage - 1) * itemsPerPage,
        limit,
        s: JSON.stringify(searchBuilder(search)),
        sort: map(ordering, (order, field) => (`${field},${order}`)),
      });
    }, [search, ordersStore.findOrders, status, limit, itemsPerPage, ordering, currentPage]),
    200
  );

  useEffect(() => {
    flush();
  }, [currentPage, itemsPerPage, ordering, flush]);

  const ordersEntities = useAsync({
    promiseFn: findEntities,
  }) as any;

  const pages = useMemo(() => {
    return ordersEntities.data ? ordersEntities.data.pageCount : 0;
  }, [ordersEntities, itemsPerPage]);

  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onDelete = useCallback(
    async (data: any) => {
      await ordersApi.deleteOrder(data.id);
      ordersEntities.reload();
    },
    [ordersEntities, ordersApi]
  );

  const orderStatus = {
    [StatusOrderEnum.PROCESSING]: {
      name: "à traiter",
      color: "badge-error",
    },
    [StatusOrderEnum.SENT]: {
      name: "en livraison",
      color: "badge-primary",
    },
    [StatusOrderEnum.RECEIVED]: {
      name: "livré",
      color: "badge-success",
    },
  };

  console.log(ordersEntities);

  return (
    <>
      {ordersEntities.data ? (
        <div className="flex-1">
          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer une commande"
            description={() => "Êtes vous sur de vouloir supprimer cette commande"}
          />
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader
                searchPlaceholder="Chercher une commande par son client"
                search={search}
                onSearchChange={setSearch}
                // actions={
                //   <button type="button" className="btn btn-sm" onClick={() => navigate("/order")}>
                //     Nouvelle commande
                //   </button>
                // }
              />
              <div className="overflow-x-auto">
                <div className="flex justify-end m-2">
                  <Select
                    className="w-52"
                    closeMenuOnSelect={true}
                    placeholder="Filtrer par statut"
                    onChange={(data: any) => {
                      setStatusOption(data);
                      setCurrentPage(1);
                    }}
                    options={statusOptions}
                    isClearable={true}
                  />
                </div>

                <DataGridTable dataProvider={ordersEntities} columns={columns} ordering={ordering} onOrderingChange={setOrdering} sortableColumns={sortableColumns}>
                  {(data) =>
                    data.data.map((order: OrderModelType) => {
                      return (
                        <tr
                          key={order.id}
                          className="cursor-pointer hover"
                          onClick={() => {
                            navigate(`/orders/${order.id}`);
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">
                            {order.clientLocation.client?.name + " - " + order.clientLocation.name || "N/A"}
                          </th>
                          <th className="flex items-center text-sm whitespace-nowrap">
                            <div className={`mr-2 badge badge-sm ${orderStatus[order.status].color}`}></div>
                            {orderStatus[order.status].name}
                          </th>
                          <th className="text-sm font-medium whitespace-nowrap">
                            {order.source == "AGENT"
                              ? "AGENT - " + order?.agent?.user.firstName + " " + order?.agent?.user.lastName
                              : order.source || "N/A"}
                          </th>
                          <th className="space-x-2 text-sm font-medium text-right">
                            <button
                              onClick={() => {
                                navigate(`/orders/${order.id}`);
                              }}
                              type="button"
                              title="Edit"
                              className="hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            {order.document?.key ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (order.document?.key) {
                                    const link = document.createElement("a");
                                    link.href = order.document.key;
                                    link.setAttribute("target", "_blank");
                                    document.body.appendChild(link);
                                    link.click();
                                  }
                                }}
                                type="button"
                                title="Download"
                                className="hover:text-blue-500"
                              >
                                <FontAwesomeIcon icon={faDownload} />
                              </button>
                            ) : (
                              <button type="button" title="Download" className="text-transparent">
                                <FontAwesomeIcon icon={faDownload} />
                              </button>
                            )}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteRef.current && deleteRef.current.open(order);
                              }}
                              type="button"
                              title="Delete"
                              className="text-error hover:text-error-active"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {ordersEntities.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{ordersEntities.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
