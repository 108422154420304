import { ClientLocationObject } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

export const ClientModel = types.model("ClientModel", {
  id: types.identifier,
  name: types.string,
  isClientSunset: types.boolean,
  isClientPlatinium: types.boolean,
  clientLocations: types.array(types.frozen<ClientLocationObject>()),
});

export type ClientModelType = Instance<typeof ClientModel>;
