/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createOperationApi(axios: AxiosInstance) {
  return {
    async findOperations(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: "/operations",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneOperation(entityId: string): Promise<any> {
      return axios({
        url: `/operations/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async deleteOperation(entityId: string) {
      return axios({
        url: `/operations/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
    async updateOperation(entityId: string, data: Partial<any>) {
      return axios({
        url: `/operations/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async createOperation(data: Partial<any>) {
      return axios({
        url: `/operations/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async generatePrivateOperationReport(entityId: string) {
      return axios({
        url: `/operations/${entityId}/generatePrivateReport`,
        method: "POST",
      }).then((response) => response.data);
    },
    async generatePublicOperationReport(entityId: string) {
      return axios({
        url: `/operations/${entityId}/generatePublicReport`,
        method: "POST",
      }).then((response) => response.data);
    },
  };
}

export type OperationApi = ReturnType<typeof createOperationApi>;
