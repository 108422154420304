import axios from "axios";
import qs from "qs";

export function axiosInstance(props: string | undefined) {
  const axiosCall = axios.create({
    baseURL: props,
    withCredentials: true,
    paramsSerializer: (params) => (typeof params === "string" ? params : qs.stringify(params)),
  });

  return axiosCall;
}
