import { faPen, faTrashAlt, faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientLocationDataModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { useStore } from "../../context/store.context";
import { getValueByKeyForDataType } from "../../utils/getValueByKey";

interface WhereIsProps {
  data: ClientLocationDataModelType;
}
export const WhereIsItem = observer(({ data }: WhereIsProps) => {
  const { clientLocationId } = useParams();
  const {
    control,
    handleSubmit,
    setValue,
  } = useForm();
  const [isForm, setIsForm] = useState(false);

  const {
    clientLocationDatas: { updateClientLocationDatas, uploadDocument, deleteDocument, deleteClientLocationData, findClientLocationDatas },
  } = useStore();

  useEffect(() => {
    setValue("text", data.text);
  }, [data]);

  const uploadFile = async (files: FileList, id: string) => {
    if (clientLocationId) {
      await uploadDocument(clientLocationId, id, files);
      findClientLocationDatas(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
    }
  };

  const onSubmit = async (dataForm: any) => {
      const item = {
        text: dataForm.text,
      };
    
      if (clientLocationId) {
        await updateClientLocationDatas(clientLocationId, data.id, item);
        findClientLocationDatas(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
      }
      setIsForm(!isForm);
  };

  const onDeleteDocument = async (entityId: string) => {
    if (clientLocationId) {
      await deleteDocument(entityId);
      findClientLocationDatas(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
    }
  };
  const onDeleteClientLocationData = async (entityId: string) => {
    if (clientLocationId) {
      await deleteClientLocationData(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`, entityId);
    }
  };
  const deleteRefDocument = useRef<ElementRef<typeof DataGridDelete>>(null);
  const deleteRefInstruction = useRef<ElementRef<typeof DataGridDelete>>(null);

  const vidRef = useRef(null);

  return (
    <div className="mb-auto">
      <DataGridDelete
        ref={deleteRefDocument}
        onDelete={onDeleteDocument}
        title="Supprimer un document"
        description={() => `Êtes vous sur de vouloir supprimer ce document?`}
      />
      <DataGridDelete
        ref={deleteRefInstruction}
        onDelete={onDeleteClientLocationData}
        title="Supprimer une instruction"
        description={() => `Êtes vous sur de vouloir supprimer cette instruction?`}
      />
      <div tabIndex={0} key={data.id} className="border collapse border-base-300 bg-base-100">
        <input type="checkbox" />
        <div className="flex justify-between text-lg font-medium collapse-title">
          <div>{getValueByKeyForDataType(data.type) || data.type}</div>
        </div>
        <div className="collapse-content">
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => {
                setIsForm(!isForm);
              }}
              type="button"
              title="Edit"
              className="hover:text-blue-500"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              onClick={() => {
                deleteRefInstruction.current && deleteRefInstruction.current.open(data.id);
              }}
              type="button"
              title="Delete"
              className="text-error hover:text-error-active"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row space-x-2 ">
              <div className="flex flex-col basis-2/4">
                {!isForm ? (
                  <p tabIndex={0}>{data.text}</p>
                ) : (
                  <Controller
                    control={control}
                    name={"text"}
                    render={({ field: { onChange, value = "" } }) => (
                      <textarea
                        className="w-full h-full mt-2 flex-full textarea input input-bordered "
                        value={value}
                        onChange={onChange}
                        placeholder={"Description"}
                      />
                    )}
                  />
                )}
              </div>
              {data.documents &&
                data.documents.map((document: any) => {
                  return (
                    <div key={document.id}>
                      {document.document.key != null && (
                        <div>
                          <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100">
                            <label className="w-20 rounded" htmlFor={"modal-" + document.document.id}>
                              {document.document.extension == "mp4" ? (
                                <video ref={vidRef}>
                                  <source src={document.document.key} type="video/mp4" />
                                </video>
                              ) : (
                                <img src={document.document.key} />
                              )}
                              {isForm && (
                                <label
                                  className="absolute top-0 right-0 border-none btn btn-sm btn-circle bg-error"
                                  onClick={() => {
                                    deleteRefDocument.current && deleteRefDocument.current.open(document.id);
                                  }}
                                >
                                  ✕
                                </label>
                              )}
                            </label>
                          </div>

                          <input type="checkbox" id={"modal-" + document.document.id} className="modal-toggle" />
                          <div className="modal">
                            <div className="relative modal-box">
                              <label htmlFor={"modal-" + document.document.id} className="absolute btn btn-sm btn-circle right-2 top-2">
                                ✕
                              </label>

                              <div className="flex justify-center">
                                {document.document.extension == "mp4" ? (
                                  <video ref={vidRef} controls>
                                    <source src={document.document.key} type="video/mp4" />
                                  </video>
                                ) : (
                                  <img src={document.document.key} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100 hover:text-blue-500 hover:border-blue-500 hover:border">
                <label className="flex w-10 m-auto rounded">
                  <FontAwesomeIcon icon={faCirclePlus} id="upfile1" className="w-10 "/>
                  <input
                    type="file"
                    accept="image/*,video/mp4,application/pdf"
                    id="file1"
                    className="hidden"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files) {
                        uploadFile(event.target.files, data.id);
                      }
                    }}
                  />
                </label>
              </div>
            </div>
            {isForm && (
              <div className="flex justify-end mt-2">
                <button
                  type="button"
                  className="mr-2 normal-case border-none btn btn-sm bg-error"
                  onClick={() => {
                    setIsForm(!isForm);
                  }}
                >
                  Annuler
                </button>
                <button className="normal-case border-none btn btn-sm bg-success" type="submit">
                  Enregistrer
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
});
