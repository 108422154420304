import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Expose, Transform, Type } from "class-transformer";
import { IsBoolean, IsNotEmpty, IsString } from "class-validator";

import { ClientLocationOperationTypeEnum } from "../clients/enums/client_location_operation-type.enum";
import { ContactsObject } from "../contact/contact.object";

export class ClientLocationObject {
  @Expose()
  @ApiProperty({
    description: "Contact's id",
    example: "6387d87d-468b-427d-aea6-571b32302ba6",
  })
  @IsString()
  id: string;

  @Expose()
  @ApiProperty({
    description: "Contact's name",
    example: "Telos-Nord",
  })
  @IsString()
  @IsNotEmpty()
  name: string;

  @Expose()
  @ApiProperty({
    description: "Contact's adress",
    example: "Telos-Nord",
  })
  @IsString()
  @IsNotEmpty()
  adressLine1: string;

  @Expose()
  @ApiProperty({
    description: "Contact's adress line 2",
    example: "Telos-Nord",
  })
  @IsString()
  adressLine2: string;

  @Expose()
  @ApiProperty({
    description: "Contact's postal code",
    example: "Telos-Nord",
  })
  @IsString()
  @IsNotEmpty()
  postalCode: string;

  @Expose()
  @ApiProperty({
    description: "Agency's name",
    example: "Telos-Nord",
  })
  @IsString()
  @IsNotEmpty()
  city: string;

  @Expose()
  @ApiProperty({
    description: "Mandate's edit status",
    example: true,
  })
  @IsBoolean()
  @IsNotEmpty()
  plasticBagHandled: boolean;

  @Expose()
  @ApiProperty({
    description: "Mandate's edit status",
    example: true,
  })
  @IsBoolean()
  @IsNotEmpty()
  sanitaryConsumablesHandled: boolean;

  @Expose()
  @ApiProperty({
    description: "Mandate's edit status",
    example: true,
  })
  @IsBoolean()
  @IsNotEmpty()
  isClientSunset: boolean;

  @Expose()
  @ApiProperty({
    description: "Mandate's edit status",
    example: true,
  })
  @IsBoolean()
  @IsNotEmpty()
  isClientPlatinium: boolean;

  @Expose()
  @ApiProperty({
    description: "Mandate's edit status",
    example: true,
  })
  @IsString()
  size: string;

  @Expose()
  @ApiProperty({
    description: "Mandate's edit status",
    example: true,
  })
  @IsString()
  geolocation: string;

  @Expose()
  @ApiProperty({
    description: "Mandate's type",
    example: "SIMPLE_MANDATE",
  })
  @IsString()
  type: ClientLocationOperationTypeEnum;

  @Expose()
  @Type(() => ContactsObject)
  @Transform(({ value }) => value?.id, { toPlainOnly: true })
  @ApiProperty({
    description: "Document owning the mandate",
  })
  contact: ContactsObject;
}
