/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContactsObject } from "@platinium/shared";
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createClientLocationContactApi(axios: AxiosInstance) {
  return {
    async fetchClientLocationContacts(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: `/clientLocationContacts`,
        method: "GET",
        params
      }).then((response) => response.data);
    },
    async fetchOneClientLocationContact(entityId: string){
      return axios({
        url: `/clientLocationContacts/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async fetchClientLocationContactClient(params?: any) {
      return axios({
        url: `/clientlocationcontacts`,
        method: "GET",
        params
      }).then((response) => response.data);
    },
    async createClientLocationContact(data: any): Promise<ContactsObject> {
      return axios({
        url: "/clientLocationContacts",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async deleteClientLocationContact(entityId: any): Promise<ContactsObject> {
      return axios({
        url: `/clientLocationContacts/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
  };
}

export type ClientLocationContactApi = ReturnType<typeof createClientLocationContactApi>;
