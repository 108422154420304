import { DrawAreaSelection } from "@bopen/leaflet-area-selection";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import React, { useEffect, useRef, useState } from "react";
import "@bopen/leaflet-area-selection/dist/index.css";
import { MapContainer, TileLayer, AttributionControl } from "react-leaflet";
import { useNavigate, useParams } from "react-router";

import "./Map.css";

import BackButton from "../../../assets/icons/back_button.svg";
import { useApi } from "../../../hooks/useApi";

export const Map = () => {
  const map = useRef<any>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [addControler, setAddControler] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [polygon, setPolygon] = useState<string>("");
  const provider = new OpenStreetMapProvider();

  const navigate = useNavigate();
  const { clientLocationId } = useParams();

  const { clientLocations } = useApi();
  const searchControl = GeoSearchControl({
    provider: provider,
    style: "bar",
    params: {
      lang: "fr",
      "boundary.country": "fr",
      layers: "address,street", // limmit which layers are queried
    },
  });

  const position = {
    lat: 48.8566,
    lng: 2.3522,
  };

  const areaSelection = new DrawAreaSelection({
    onPolygonReady: (polygon: any) => {
      setPolygon(JSON.stringify(polygon.toGeoJSON().geometry.coordinates));
      setIsOpen(true);
    },
  });

  useEffect(() => {
    if (map.current && mapLoaded && addControler) {
      setAddControler(false);
      map.current.addControl(areaSelection);
      map.current.addControl(searchControl);
    }
  }, [map, map.current, mapLoaded]);

  const handleValidateCoordinates = async () => {
    setIsOpen(false);
    await clientLocations.updateClientLocation(clientLocationId, {
      geolocation: polygon,
    });
    navigate(`/clientLocation/${clientLocationId}`);
  };

  return (
    <div className="flex-1">
      <label htmlFor="my-modal-4" className={`${isOpen ? "modal-open" : ""} cursor-pointer modal`}>
        <label className="relative modal-box" htmlFor="">
          <h3 className="text-lg font-bold">Confirmer cette zone</h3>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button onClick={handleValidateCoordinates} type="button" className="ml-2 btn btn-outline btn-success">
              Valider
            </button>
            <button type="button" className=" btn btn-outline" onClick={() => setIsOpen(false)}>
              Annuler
            </button>
          </div>
        </label>
      </label>
      <div onClick={() => navigate(`/clientLocation/${clientLocationId}`)} className="p-2 bg-white cursor-pointer">
        <img src={BackButton} />
      </div>
      <MapContainer ref={map} whenReady={() => setMapLoaded(true)} center={position} zoom={13} scrollWheelZoom={false}>
        <AttributionControl />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
};
