/* eslint-disable @typescript-eslint/no-explicit-any */
import { faCalendar, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientLocationCheckinModelType } from "@platinium/cdk";
import { saveAs } from "file-saver";
import { map } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAsync } from "react-async";
import Calendar from "react-calendar";
import Select from "react-select";
import { useDebounce } from "use-debounce";
import "./Dashboard.css";

import GreenCircle from "../../assets/icons/green.png";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from "../../components/Loader/Loader";
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { getDiffInMinutesAndHours } from "../../utils/date.utils";

const columns = [
  {
    label: "Site",
    name: "clientLocation.name",
  },
  {
    label: "Agent",
    name: "agent.user.lastName",
  },
  {
    label: "Date de mission",
    name: "startTime",
  },
  {
    label: "Heure d'arrivée",
    name: "startTime",
  },
  {
    label: "Heure de départ",
    name: "endTime",
  },
  {
    label: "Temps sur le site",
    name: "endTime",
  },
];

const sortableColumns = ["clientLocation.name", "agent.user.lastName"];

interface SelectedOption {
  name: string;
  value: string;
}

export const Dashboard = observer(() => {
  const [close, setClose] = useState(true);
  const { clientLocationCheckins: clientLocationCheckinsStore } = useStore();
  const { agents: agentsApi, clientLocations: clientLocationsApi, clientLocationCheckins: clientLocationCheckinsApi } = useApi();

  const [search, setSearch] = useState<string>("");
  const limit = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(limit);
  const [loading, setLoading] = useState<boolean>(false);

  const [agentOptions, setAgentOptions] = useState([]);
  const [clientLocationsOptions, setClientLocationsOptions] = useState([]);

  const [clientLocationSelected, setClientLocationSelected] = useState<SelectedOption | null>(null);
  const [agentSelected, setAgentSelected] = useState<SelectedOption | null>(null);
  const [timeSelected, setTimeSelect] = useState<any>();
  const [ordering, setOrdering] = useState<Record<string, "ASC" | "DESC">>({});

  const { value: agentId } = agentSelected || {};
  const { value: clientLocationId } = clientLocationSelected || {};

  useEffect(() => {
    const fetchOptions = async () => {
      /* fetch Options client locations */
      const clientLocations = await clientLocationsApi.fetchAllClientLocations();
      setClientLocationsOptions(
        clientLocations.map((clientLocation: any) => ({
          value: clientLocation.id,
          label: clientLocation.name,
        }))
      );

      /* fetch Options agents */
      const agents = await agentsApi.fetchAllAgents();
      setAgentOptions(
        agents.map((agent: any) => ({
          value: agent.id,
          label: `${agent.user.firstName} ${agent.user.lastName}`,
        }))
      );
    };
    fetchOptions();
  }, []);

  const dateRange = useMemo(() => {
    if (!timeSelected) return [];
    return timeSelected;
  }, [timeSelected]);

  const searchBuilder = (search: string) => ({
    $and: [
      {
        $or: [
          {
            "clientLocation.name": {
              $contL: search,
            },
          },
          {
            "clientLocation.client.name": {
              $contL: search,
            },
          },
          {
            "agent.user.firstName": {
              $contL: search,
            },
          },
          {
            "agent.user.lastName": {
              $contL: search,
            },
          },
        ],
      },
      ...(agentId
        ? [
          {
            agentId: {
              $eq: agentId,
            },
          },
        ]
        : []),
      ...(dateRange.length > 0
        ? [
          {
            startTime: {
              $between: dateRange,
            },
          },
        ]
        : []),
      ...(clientLocationId
        ? [
          {
            clientLocationId: {
              $eq: clientLocationId,
            },
          },
        ]
        : []),
    ],
  });

  const [findEntities, { flush }] = useDebounce(
    useCallback(async () => {
      return clientLocationCheckinsStore.findClientLocationCheckins({
        offset: (currentPage - 1) * itemsPerPage,
        limit,
        s: JSON.stringify(searchBuilder(search)),
        sort: map(ordering, (order, field) => `${field},${order}`),
      });
    }, [
      search,
      clientLocationCheckinsStore.findClientLocationCheckins,
      dateRange,
      agentId,
      clientLocationId,
      limit,
      itemsPerPage,
      currentPage,
      ordering,
    ]),
    200
  );

  useEffect(() => {
    flush();
  }, [currentPage, itemsPerPage, ordering, flush]);

  const clientLocationCheckins = useAsync({
    promiseFn: findEntities,
  }) as any;

  const pages = useMemo(() => {
    return clientLocationCheckins.data ? clientLocationCheckins.data.pageCount : 0;
  }, [clientLocationCheckins, itemsPerPage]);

  const textSelectionDate = useMemo(() => {
    if (!timeSelected) return "Sélectionner une date";
    const [date1, date2] = timeSelected;
    const date1Formated = DateTime.fromJSDate(date1).toFormat("D");
    const date2Formated = DateTime.fromJSDate(date2).toFormat("D");
    return `${date1Formated} - ${date2Formated}`;
  }, [JSON.stringify(timeSelected)]);

  const exportClientLocationCheckins = () => {
    setLoading(true);
    clientLocationCheckinsApi
      .createDownloadClientLocationCheckins({
        ...(agentId && { agentId }),
        ...(clientLocationId && { clientLocationId }),
        ...(dateRange.length > 0 && { startTime: dateRange }),
      })
      .then(async ({ downloadToken }: { downloadToken: string }) => {
        const file = await clientLocationCheckinsApi.getDownloadClientLocationCheckins(downloadToken);
        const blob = new Blob([file], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "rapport.xlsx");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const wrapperRef = useRef(null);
  const outside = useOutsideAlerter(wrapperRef);
  useEffect(() => {
    setClose(outside);
  }, [outside]);

  return (
    <>
      {!clientLocationCheckins?.data?.data ? (
        <Loader />
      ) : (
        <div className="flex-1">
          <div className="flex flex-col min-h-[60vh] w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader searchPlaceholder="Chercher une commande par son site ou client" search={search} onSearchChange={setSearch} />
              <div className="flex items-center mb-5">
                <div className="w-full mr-5" ref={wrapperRef} onClick={() => setClose(false)}>
                  <div
                    className={
                      "flex items-center m-2 rounded input input-bordered dateInput cursor-pointer justify-between " +
                      (!close ? "border-blue-500 border-2" : "border")
                    }
                  >
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCalendar} />
                      <p className="w-full ml-4" placeholder="Calendar">
                        {textSelectionDate}
                      </p>
                    </div>

                    {timeSelected && (
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="flex justify-end"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTimeSelect(null);
                        }}
                      />
                    )}
                  </div>
                  {!close && (
                    <Calendar
                      className="absolute z-50 text-center"
                      selectRange={true}
                      onChange={(data: Date[]) => {
                        setTimeSelect(data);
                        setClose(true);
                      }}
                      value={timeSelected}
                    />
                  )}
                </div>
                <Select
                  className="w-full text-sm"
                  closeMenuOnSelect={true}
                  placeholder="Sélectionner un agent"
                  value={agentSelected}
                  onChange={(data: any) => setAgentSelected(data)}
                  hideSelectedOptions={true}
                  options={agentOptions}
                  isClearable={true}
                />
                <Select
                  isClearable={true}
                  className="w-full mx-5 text-sm"
                  closeMenuOnSelect={true}
                  placeholder="Sélectionner un site"
                  value={clientLocationSelected}
                  onChange={(data) => setClientLocationSelected(data)}
                  hideSelectedOptions={true}
                  options={clientLocationsOptions}
                />
                <button
                  onClick={exportClientLocationCheckins}
                  className={`btn  ${loading ? "loading normal-case btn btn-sm" : "normal-case btn btn-sm"}`}
                >
                  TELECHARGER
                </button>
              </div>
              <div className="overflow-x-auto">
                <DataGridTable
                  dataProvider={clientLocationCheckins}
                  columns={columns}
                  ordering={ordering}
                  onOrderingChange={setOrdering}
                  sortableColumns={sortableColumns}
                >
                  {(data) =>
                    data.data.map((clientLocationCheckin: ClientLocationCheckinModelType) => {
                      return (
                        <tr key={clientLocationCheckin.id}>
                          <th className="text-sm font-medium whitespace-nowrap">{clientLocationCheckin.clientLocation.name || "N/A"}</th>
                          <th className="text-sm font-medium whitespace-nowrap">
                            {clientLocationCheckin.agent.user.lastName + " " + clientLocationCheckin.agent.user.firstName || "N/A"}
                          </th>
                          <th className="text-sm whitespace-nowrap">{clientLocationCheckin.startTime.toFormat("D")}</th>
                          <th className="text-sm whitespace-nowrap">{clientLocationCheckin.startTime.toFormat("T")}</th>
                          <th className="text-sm whitespace-nowrap">
                            {clientLocationCheckin.endTime?.toFormat("T") || (
                              <img src={GreenCircle} className="w-5" alt="Green circle"></img>
                            )}
                          </th>
                          <th className="text-sm whitespace-nowrap">
                            {getDiffInMinutesAndHours(clientLocationCheckin?.endTime, clientLocationCheckin.startTime)}
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              <p className="pt-4 ml-auto font-bold font-lg">{`Total des heures ${clientLocationCheckins?.data?.totalHours}`}</p>
              {clientLocationCheckins.data && clientLocationCheckins.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{clientLocationCheckins.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
});
