import React from "react";

export const Modal = () => {
  return (
    <div>
      <label htmlFor="my-modal" className="btn modal-button">
        Bonjour !
      </label>
      <input type="checkbox" id="my-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Congratulations random Interner user!</h3>
          <p className="py-4">Youve been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
          <div className="modal-action">
            <label htmlFor="my-modal" className="btn">
              Yay
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
