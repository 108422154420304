import { faCheck, faPen, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientLocationOperationTypeEnum } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import BackButton from "../../assets/icons/back_button.svg";
import { Contact } from "../../components/Contacts/Contact";
import { Contacts } from "../../components/Contacts/Contacts";
import { Address } from "../../components/DataDisplay/Address";
import { Loader } from "../../components/Loader/Loader";
import { WhereIs } from "../../components/WhereIs/WhereIs";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { Instruction } from "../Instruction/Instruction";
import { ClientLocationAgent } from "./ClientLocationAgent";
import { ClientLocationProduct } from "./ClientLocationProduct";
import { ClientLocationQRcode } from "./ClientLocationQRcode";

interface FormData {
  name: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  civility: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneNumberPerso: string;
  fonction: string;
  digitalCodes: string;
  text: string;
}

export const ClientLocation = observer(() => {
  const navigate = useNavigate();
  const [isInput, setIsInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputAddress, setIsInputAddress] = useState(false);
  const { search } = useLocation();
  const [clientId, setClientId] = useState<string | null>();
  const { clientLocationId } = useParams();
  const [openClients, setOpenClients] = useState<boolean>(false);
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const clientIdQuery = query.get("clientId");
  const {
    clientLocations: { clientLocation, fetchEntity },
    clientLocationAgent: { clientLocationAgents, fetchClientLocationAgent },
    agents: { agents, findAgents },
  } = useStore();

  const { clientLocations: clientLocationsApi } = useApi();
  const { control, handleSubmit, setValue } = useForm<FormData>();

  useEffect(() => {
    (async () => {
      if (clientLocationId) {
        await fetchEntity(clientLocationId);
        await fetchClientLocationAgent(`s={"$and": [{"clientLocationId": "${clientLocationId}"}]}`);
        await findAgents(null);
        setIsLoading(true);
      } else {
        setClientId(clientIdQuery);
        setIsLoading(true);
      }
    })();
  }, [clientLocationId]);

  useEffect(() => {
    if (clientLocation) {
      if (clientLocation.name) setValue("name", clientLocation.name);
      if (clientLocation.adressLine1) setValue("addressLine1", clientLocation.adressLine1);
      if (clientLocation.adressLine2) setValue("addressLine2", clientLocation.adressLine2);
      if (clientLocation.postalCode) setValue("postalCode", clientLocation.postalCode);
      if (clientLocation.city) setValue("city", clientLocation.city);
      if (clientLocation.digitalCodes) setValue("digitalCodes", clientLocation.digitalCodes);
      if (clientLocation.text) setValue("text", clientLocation.text);
    }
  }, [clientLocation]);

  const onSubmit = async (dataForm: FormData) => {
    if (clientLocation) {
      const data = {
        name: dataForm.name,
        adressLine1: dataForm.addressLine1,
        adressLine2: dataForm.addressLine2,
        postalCode: dataForm.postalCode,
        city: dataForm.city,
        digitalCodes: dataForm.digitalCodes,
        text: dataForm.text,
      };
      await clientLocationsApi.updateClientLocation(clientLocation.id, data);
      await fetchEntity(clientLocation.id);
    } else {
      const data = {
        clientId: clientId,
        name: dataForm.name,
        isClientPlatinium: false,
        isClientSunset: false,
        adressLine1: " ",
        adressLine2: "",
        postalCode: " ",
        city: " ",
        digitalCodes: "",
        text: "",
        plasticBagHandled: false,
        sanitaryConsumablesHandled: false,
        operationType: ClientLocationOperationTypeEnum.REGULAR,
      };
      const clientLocationCreated = await clientLocationsApi.createClientLocation(data);
      navigate("/clientLocation/" + clientLocationCreated.id);
    }
    setIsInputAddress(false);
    setIsInput(false);
  };

  const handleChange = async (isSunset: any, isPlatinium: any) => {
    const data = {
      isClientSunset: isSunset,
      isClientPlatinium: isPlatinium,
    };
    if (clientLocation) {
      await clientLocationsApi.updateClientLocation(clientLocation.id, data);
      await fetchEntity(clientLocation.id);
    }
  };

  const openContacts = () => {
    setOpenClients(!openClients);
  };

  return (
    <>
      {isLoading ? (
        <div className='flex-1'>
          <div className="pt-5 pl-5 md:container md:mx-auto navbar w-36" onClick={() => navigate(-1)}>
            <img src={BackButton} />
          </div>
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <form onSubmit={handleSubmit(onSubmit)} className="flex justify-center m-5">
              <div className="relative flex items-center">
                {!isInput ? (
                  <h1 className="text-2xl ">{clientLocation ? clientLocation.name : "Nom du site"}</h1>
                ) : (
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, value = "" } }) => (
                      <input type="text" value={value} onChange={onChange} placeholder={"Nom du client"} className="input input-bordered" />
                    )}
                  />
                )}

                {!isInput ? (
                  <button
                    onClick={() => {
                      setIsInput(!isInput);
                    }}
                    type="button"
                    title="Edit"
                    className="pl-4 hover:text-blue-500"
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                ) : (
                  <div className="flex justify-content">
                    <button
                      onClick={() => {
                        setIsInput(!isInput);
                      }}
                      type="button"
                      title="Edit"
                      className="pl-4 hover:text-error"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <button type="submit" title="Edit" className="pl-4 hover:text-green-500">
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                )}
              </div>
              <button onClick={() => navigate(`/clientLocation/${clientLocationId}/map`)} className="absolute right-28 btn btn-sm">
                {!clientLocation?.geolocation ? "Sélectionner zone de travail" : "Modifier zone de travail"}
              </button>
            </form>

            {clientLocationId && <ClientLocationQRcode />}
            {/* -- Contract -- */}
            <div className="flex justify-center">
              <div className="dropdown">
                <label tabIndex={0} className="m-1 btn btn-sm">
                  Contrat
                </label>
                <ul tabIndex={0} className="p-2 shadow dropdown-content bg-base-100 rounded-box w-52">
                  <li>
                    <div className="form-control">
                      <label className="justify-start cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={clientLocation?.isClientSunset}
                          onChange={() => handleChange(!clientLocation?.isClientSunset, clientLocation?.isClientPlatinium)}
                        />
                        <span className="pl-2 label-text">Sunset</span>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-control">
                      <label className="justify-start cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={clientLocation?.isClientPlatinium}
                          onChange={() => handleChange(clientLocation?.isClientSunset, !clientLocation?.isClientPlatinium)}
                        />
                        <span className="pl-2 label-text">Platinium</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {clientLocationId && (
              <div>
                <div className="flex">
                  {/* -- Address -- */}
                  <form onSubmit={handleSubmit(onSubmit)} className="flex justify-center mt-5 basis-1/2">
                    <div className="w-full p-5 pt-6 shadow-xl card card-bordered bg-base-100">
                      <div className="flex justify-between">
                        <div></div>
                        <h2 className="justify-center card-title">Adresse</h2>
                        {!isInputAddress ? (
                          <button
                            onClick={() => {
                              setIsInputAddress(!isInputAddress);
                            }}
                            type="button"
                            title="Edit"
                            className="pl-4"
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </button>
                        ) : (
                          <div className="flex justify-content">
                            <button
                              onClick={() => {
                                setIsInputAddress(!isInputAddress);
                              }}
                              type="button"
                              title="Edit"
                              className="pl-4 hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </button>
                            <button type="submit" title="Edit" className="pl-4">
                              <FontAwesomeIcon icon={faCheck} />
                            </button>
                          </div>
                        )}
                      </div>

                      {isInputAddress ? (
                        <div className="card-body">
                          <div className="space-y-2">
                            <Controller
                              control={control}
                              name={"addressLine1"}
                              render={({ field: { onChange, value = "" } }) => (
                                <input
                                  type="text"
                                  value={value != " " ? value : ""}
                                  onChange={onChange}
                                  placeholder={"addresse ligne 1"}
                                  className="w-full input input-bordered"
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={"addressLine2"}
                              render={({ field: { onChange, value = "" } }) => (
                                <input
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  placeholder={"addresse ligne 2"}
                                  className="w-full input input-bordered"
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={"postalCode"}
                              render={({ field: { onChange, value = "" } }) => (
                                <input
                                  type="text"
                                  value={value != " " ? value : ""}
                                  onChange={onChange}
                                  placeholder={"Code postal"}
                                  className="w-full input input-bordered"
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={"city"}
                              render={({ field: { onChange, value = "" } }) => (
                                <input
                                  type="text"
                                  value={value != " " ? value : ""}
                                  onChange={onChange}
                                  placeholder={"Ville"}
                                  className="w-full input input-bordered"
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={"digitalCodes"}
                              render={({ field: { onChange, value = "" } }) => (
                                <textarea
                                  className="w-full textarea textarea-bordered"
                                  value={value}
                                  onChange={onChange}
                                  placeholder={"Code"}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={"text"}
                              render={({ field: { onChange, value = "" } }) => (
                                <textarea
                                  className="w-full textarea textarea-bordered"
                                  value={value}
                                  onChange={onChange}
                                  placeholder={"Champs libre"}
                                />
                              )}
                            />
                          </div>
                        </div>
                      ) : (
                        <Address clientLocation={clientLocation} />
                      )}
                    </div>
                  </form>

                  <div className="basis-1/2">
                    {isLoading && (
                      <div className="">
                        {clientLocation != null && clientLocation?.contacts && clientLocation?.contacts?.length > 0 ? (
                          <Contact
                            contactId={clientLocation.contacts[0].id}
                            clientLocationId={clientLocationId}
                            openContacts={() => openContacts()}
                          />
                        ) : (
                          <Contact clientLocationId={clientLocationId} openContacts={() => openContacts()} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {openClients && <Contacts clientLocationId={clientLocationId} />}
                {clientLocationId != null && agents && clientLocationAgents && isLoading && (
                  <div className="flex justify-center basis-1/2">
                    <ClientLocationAgent clientLocationId={clientLocationId} />
                  </div>
                )}
                {clientLocationId != null && (
                  <div className="flex justify-center basis-1/2">
                    <ClientLocationProduct clientLocationId={clientLocationId} />
                  </div>
                )}
                <WhereIs />
                <div className="divider"></div>
                <Instruction />
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
