import { faFilePdf, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProblemModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useEffect, useRef } from "react";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { useStore } from "../../context/store.context";

interface ProblemProps {
  problem: ProblemModelType;
}
export const ProblemItem = observer(({ problem }: ProblemProps) => {
  const {
    problems: { findProblems, deleteProblem },
  } = useStore();

  useEffect(() => {
    findProblems(`s={"$and": [{"agentId": "${problem.agentId}"}]}`);
  }, []);

  const onDeleteProblem = async (entityId: string) => {
    await deleteProblem(entityId);
    findProblems(`s={"$and": [{"agentId": "${problem.agentId}"}]}`);
  };
  const deleteRefProblem = useRef<ElementRef<typeof DataGridDelete>>(null);

  const vidRef = useRef(null);

  return (
    <div className="mb-auto">
      <DataGridDelete
        ref={deleteRefProblem}
        onDelete={onDeleteProblem}
        title="Supprimer un signalement"
        description={() => `Êtes vous sur de vouloir supprimer ce signalement?`}
      />
      <div tabIndex={0} key={problem.id} className="border collapse border-base-300 bg-base-100">
        <input type="checkbox" />
        <div className="flex justify-between text-xl font-medium collapse-title">
          {problem.clientLocation && problem.clientLocation.client && <div>{problem.clientLocation.client.name}</div>}
        </div>
        <div className="collapse-content">
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => {
                deleteRefProblem.current && deleteRefProblem.current.open(problem.id);
              }}
              type="button"
              title="Delete"
              className="text-error hover:text-error-active"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
          <div className="flex flex-row space-x-2 ">
            <div className="flex flex-col basis-2/4">
              <p tabIndex={0} className="basis-2/4">
                {problem.text}
              </p>
            </div>
            {problem.documents &&
              problem.documents.map((document: any) => {
                return (
                  <div key={document.id}>
                    {document.document.key != null && (
                      <div>
                        <div className="w-20 h-20 shadow-xl cursor-pointer avatar card bg-base-100">
                          <label className="w-20 h-full rounded" htmlFor={"modal-" + document.document.id}>
                            {document.document.extension == "mp4" ? (
                              <video ref={vidRef}>
                                <source src={document.document.key} type="video/mp4" />
                              </video>
                            ) : document.document.extension == "pdf" ? (
                              <div className="flex justify-center m-auto">
                                <div>
                                  <FontAwesomeIcon className="fa-3x" icon={faFilePdf} />
                                </div>
                              </div>
                            ) : (
                              <img src={document.document.key} />
                            )}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
});
