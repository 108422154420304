/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export interface AuthenticateDto {
  email: string;
  password: string;
}

export function createAdminApi(axios: AxiosInstance) {
  return {
    async authenticate(data: AuthenticateDto) {
      return axios({
        url: "/admins/authenticate",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async current() {
      return axios({
        url: "/admins/me",
        method: "GET",
      }).then((response) => response.data);
    },
    async fetchAdmins(params?: any) {
      return axios({
        url: "/admins",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneAdmin(entityId: string): Promise<PaginatedResponse<any>> {
      return axios({
        url: `/admins/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async deleteAdmin(entityId: string) {
      return axios({
        url: `/admins/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
    async updateAdmin(entityId: string, data: Partial<any>) {
      return axios({
        url: `/admins/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async createAdmin(data: Partial<any>): Promise<any> {
      return axios({
        url: "/admins",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
  };
}

export type AdminApi = ReturnType<typeof createAdminApi>;
