import { ClientLocationObject } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

import { AgentModel } from "src/modules/models/agent.model";

export const ClientLocationAgentModel = types.model("ClientLocationAgentModel", {
  id: types.identifier,
  agent: types.frozen(AgentModel),
  clientLocation: types.frozen<ClientLocationObject>(),
});

export type ClientLocationAgentModelType = Instance<typeof ClientLocationAgentModel>;
