import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Expose } from "class-transformer";
import { IsString } from "class-validator";

export class ProblemObject {
  @Expose()
  @ApiProperty({
    description: "Problem's id",
    example: "6387d87d-468b-427d-aea6-571b32302ba6",
  })
  @IsString()
  id: string;

  @Expose()
  @ApiProperty({
    description: "Description of problem",
    example: "test",
  })
  @IsString()
  text: string;
}
