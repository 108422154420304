/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createOrderApi(axios: AxiosInstance) {
  return {
    async findOrders(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: "/orders",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneOrder(entityId: string): Promise<any> {
      return axios({
        url: `/orders/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async deleteOrder(entityId: string) {
      return axios({
        url: `/orders/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
    async updateOrder(entityId: string, data: Partial<any>) {
      return axios({
        url: `/orders/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async createOrder(data: Partial<any>) {
      return axios({
        url: `/orders/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async generateDeliveryOrder(entityId: string) {
      return axios({
        url: `/orders/${entityId}/generateDeliveryOrder`,
        method: "POST",
      }).then((response) => response.data);
    },
  };
}

export type OrderApi = ReturnType<typeof createOrderApi>;
