/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { TrainingModelType } from "src/modules/models";

export function createTrainingApi(axios: AxiosInstance) {
  return {
    async findTrainings(params?: any) {
      return axios({
        url: `/trainings/`,
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async findOneTraining(entityId: string) {
      return axios({
        url: `/trainings/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async createTraining(data: TrainingModelType) {
      return axios({
        url: `/trainings/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async updateTrainings(entityId: string, data: TrainingModelType) {
      return axios({
        url: `/trainings/${entityId}`,
        method: "PATCH",
        data: data,
      }).then((response) => response.data);
    },
    async deleteTraining(entityId: string) {
      return axios({
        url: `/trainings/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },

    async findTrainingsDocuments(entityId: string) {
      return axios({
        url: "/trainingDocuments/",
        method: "GET",
        data: entityId,
      }).then((response) => response.data);
    },
    async uploadDocument(entityId: string, files: FileList) {
      const bodyFormData = new FormData();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        bodyFormData.append(`files`, file);
      }
      bodyFormData.append(`trainingId`, entityId);

      return axios({
        url: `/trainingDocuments/upload`,
        method: "POST",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data);
    },
    async updateTrainingDocuments(entityId: string, data: TrainingModelType) {
      return axios({
        url: `/trainingDocuments/${entityId}`,
        method: "PATCH",
        data: data,
      }).then((response) => response.data);
    },
    async deleteDocument(entityId: string) {
      return axios({
        url: `/trainingDocuments/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    
  };
}

export type TrainingApi = ReturnType<typeof createTrainingApi>;
