/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgentObject } from "@platinium/shared";
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createClientLocationAgentApi(axios: AxiosInstance) {
  return {
    async fetchOneClientLocationAgent(entityId: string): Promise<PaginatedResponse<any>> {
      return axios({
        url: `/clientLocationAgents/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async fetchClientLocationAgent(params?: any) {
      return axios({
        url: `/clientlocationagents`,
        method: "GET",
        params
      }).then((response) => response.data);
    },
    async fetchClientLocationAgentClient(params?: any) {
      return axios({
        url: `/clientlocationagents`,
        method: "GET",
        params
      }).then((response) => response.data);
    },
    async createClientLocationAgent(data: any): Promise<AgentObject> {
      return axios({
        url: "/clientLocationAgents",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async deleteClientLocationAgent(entityId: any): Promise<AgentObject> {
      return axios({
        url: `/clientLocationAgents/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
  };
}

export type ClientLocationAgentApi = ReturnType<typeof createClientLocationAgentApi>;
