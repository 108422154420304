import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";

interface ContactProps {
  clientLocationId: string;
}

export const ClientLocationAgent = observer(({ clientLocationId }: ContactProps) => {
  const [agentTable, setAgentTable] = useState<any[]>([]);
  const [agentTableSelected, setAgentTableSelected] = useState<any[]>([]);
  const [assign, setAssign] = useState<boolean>(false);

  const {
    clientLocations: { clientLocation, fetchEntity },
    clientLocationAgent: { clientLocationAgents },
    agents: { agents },
  } = useStore();

  const { clientLocationAgents: clientLocationAgentsApi } = useApi();

  useEffect(() => {
    getAgent();
  }, [clientLocationId]);

  const getAgent = async () => {
    const agentList: any[] = [];
    const agentClientLocation: any[] = [];

    if (agents && agents.data) {
      agents.data.map((agent) => {
        if (agent) {
          const a = { value: agent.id, label: agent.user.firstName + " " + agent.user.lastName };
          agentList.push(a);
        }
      });
      setAgentTable(agentList);
    }
    if (clientLocationAgents) {
      clientLocationAgents.map((agent) => {
        const agentLocation = { value: agent.agent.id, label: agent.agent.user.firstName + " " + agent.agent.user.lastName };
        agentClientLocation.push(agentLocation);
      });
      setAgentTableSelected(agentClientLocation);
    }
    setAssign(!assign);
  };

  const handleChangeSelected = async (selected: any) => {
    const difference = agentTableSelected.filter((x) => !selected.includes(x));
    if (difference.length > 0) {
      const clientLocationAgentClient = await clientLocationAgentsApi.fetchClientLocationAgentClient(
        `s={"$and": [{"clientLocationId": "${clientLocationId}"}, {"agentId": "${difference[0].value}"}]}`
      );
      await clientLocationAgentsApi.deleteClientLocationAgent(clientLocationAgentClient[0].id);
    } else {
      const data = {
        clientLocationId: clientLocationId,
        agentId: selected.at(-1).value,
      };
      if (clientLocation) {
        await clientLocationAgentsApi.createClientLocationAgent(data);
        fetchEntity(clientLocationId);
      }
    }
    setAgentTableSelected(selected);
  };

  return (
    <div className="flex flex-col w-full px-1 mx-auto mb-auto sm:px-6 lg:px-8 max-w-7xl">
      <div className=" bg-base-100">
        <div className=" card-body">
          <div className="flex justify-between">
            <div></div>
            <h2 className="justify-center card-title">Agents</h2>
            <div></div>
          </div>
          {agentTableSelected && (
            <Select
              closeMenuOnSelect={false}
              isClearable={false}
              isMulti
              value={agentTableSelected}
              onChange={(e) => handleChangeSelected(e)}
              hideSelectedOptions={false}
              options={agentTable}
            />
          )}
        </div>
      </div>
    </div>
  );
});
