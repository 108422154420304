import React, { useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import Select from "react-select";

import { DocumentCard } from "./DocumentCard";

interface ProductFormProps {
  form: UseFormReturn;
}

interface FormInput {
  name: string;
  type: string;
  placeholder: string;
  label: string;
  requiredIf?: (data: any) => boolean;
}

const typeOption = [
  { key: "PRODUCT", value: "Produit" },
  { key: "CONSUMABLE", value: "Consommable" },
  { key: "MATERIAL", value: "Matériel" },
  { key: "GARBAGESBAG", value: "Sac poubelle" },
];

const inputs: FormInput[] = [
  {
    name: "reference",
    type: "text",
    placeholder: "12345",
    label: "Référence",
    requiredIf: () => true,
  },
  {
    name: "description",
    type: "text",
    placeholder: "gants",
    label: "Description",
    requiredIf: () => false,
  },
  {
    name: "defaultQuantity",
    type: "number",
    placeholder: "5",
    label: "Quantité par défault pour commande",
    requiredIf: () => true,
  },
];

export const ProductForm = (props: ProductFormProps) => {
  const { form } = props;
  const data = form.watch();
  const [ isError, setIsError ] = useState(false);

  const { documentKey, documentId, securityDocumentKey, securityDocumentId } = useMemo(
    () => ({
      documentKey: data?.document?.key,
      documentId: data?.documentId,
      securityDocumentKey: data?.securityDocument?.key,
      securityDocumentId: data?.securityDocumentId,
    }),
    [data]
  );

  /* Options orderable By */
  const options = [
    { value: "AGENT", label: "Agent" },
    { value: "CLIENT", label: "Client" },
  ];
  const handleChangeSelected = (arraySelected: any) => {
    const orderableBy = arraySelected.map((e: any) => e.value);
    form.setValue("orderableBy", orderableBy);
  };

  const handleChangeType = (selected: any) => {
    form.setValue("type", selected.target.value);
  };
  const getLabelOrderableBy = (value: string) => (value === "AGENT" ? "Agent" : "Client");
  const orderableByOptions = useMemo(() => data?.orderableBy?.map((e: any) => ({ value: e, label: getLabelOrderableBy(e) })), [data]);

  const setError = (err: any) => {
    setIsError(err);
  };

  return (
    <div className="px-6 mt-3">
      {isError && <div className="text-error">Veillez renseigner tout les champs</div>}
      <Select
        closeMenuOnSelect={false}
        placeholder="Sélectionner qui peut commander ce produit"
        isMulti
        value={orderableByOptions}
        onChange={(e) => handleChangeSelected(e)}
        hideSelectedOptions={true}
        options={options}
      />
      <label className="label">
        <span className="label-text"> Type</span>
      </label>
      <select className="w-full select select-sm select-bordered" value={data?.type} onChange={(e) => handleChangeType(e)}>
        <option disabled selected>
          Type
        </option>
        {typeOption &&
          typeOption.map((type) => {
            return (
              <option key={type.key} value={type.key}>
                {type.value}
              </option>
            );
          })}
      </select>

      {inputs.map((input) => (
        <div key={input.name} className="w-full">
          <label htmlFor={input.name} className="label">
            <span className="text-base font-bold label-text"> {input.label}</span>
          </label>
          <input
            {...form.register(input.name)}
            type={input.type}
            placeholder={input.placeholder}
            required={input.requiredIf && input.requiredIf(data)}
            className="w-full input input-bordered"
          />
        </div>
      ))}

      <DocumentCard
        documentKey={documentKey}
        documentLabel="Produit"
        documentId={documentId}
        documentProductType="documentId"
        documentTitle="le document"
        product={data}
        productId={data.id}
        form={form}
        setError={(e) => setError(e)}
      />

      <DocumentCard
        documentKey={securityDocumentKey}
        documentLabel="Fiche sécurité"
        documentId={securityDocumentId}
        documentProductType="securityDocumentId"
        documentTitle="la fiche sécurité"
        product={data}
        productId={data.id}
        form={form}
        setError={setError}
      />
    </div>
  );
};
