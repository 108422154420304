import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Expose } from "class-transformer";
import { IsArray, IsNumber, IsString } from "class-validator";

import { DocumentObject } from "../documents/document.object";
import { OrderableByEnum } from "../orders/enums/orderableBy.enum";

export class ProductObject {
  @Expose()
  @ApiProperty({
    description: "Admin's id",
    example: "6387d87d-468b-427d-aea6-571b32302ba6",
  })
  @IsString()
  id: string;

  @Expose()
  @ApiProperty({
    description: "Description of product",
    example: "Gants",
  })
  @IsString()
  description: string;

  @Expose()
  @ApiProperty({
    description: "Reference of product",
    example: "123528",
  })
  @IsString()
  reference: string;

  @Expose()
  @ApiProperty({
    description: "Reference of product",
    example: "123528",
  })
  @IsNumber()
  defaultQuantity: string;

  @Expose()
  @ApiProperty({
    description: "By whom instance if the product orderable",
    example: [OrderableByEnum.AGENT],
  })
  @IsArray()
  orderableBy: OrderableByEnum[];

  @Expose()
  @ApiProperty({
    description: "Security document related to product",
    example: [OrderableByEnum.AGENT],
  })
  @IsArray()
  securityDocument?: DocumentObject;

  @Expose()
  @ApiProperty({
    description: "Document related to product",
  })
  @IsArray()
  document?: DocumentObject;

  ////////////////////////////////////////////////////////////////
  // Relations
  @Expose()
  @ApiProperty({
    description: "Security document id related to product",
  })
  securityDocumentId: string | null;

  @Expose()
  @ApiProperty({
    description: "Document id related to product",
  })
  documentId: string | null;
}
