/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { ClientModel, PaginatedModels } from "src/modules/models";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const ClientReference = createEntityReference("clients", ClientModel);

export const ClientsStore = createEntityStore("Client", ClientModel)
  .props({
    clients: types.maybe(PaginatedModels(ClientReference)),
    client: types.maybeNull(ClientModel),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { clients: clientApi },
      } = getEnv<RootStoreEnv>(self);

      const client = yield clientApi.fetchOneClient(entityId);
      self.client = client;
      return ClientModel.create({
        rawClient: client,
        ...client,
      });
    }),
    findClients: flow(function* (findClientData: any) {
      const {
        api: { clients: clientApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientApi.fetchClients(findClientData);
      const clients = result.data.map((client: any) => {
        const entityModel = self.cacheEntity(
          ClientModel.create({
            rawClient: client,
            ...(client as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.clients = cast({
        ...result,
        data: cast(clients as any),
      });

      return self.clients as PaginatedModels<typeof ClientModel>;
    }),
    clearClient() {
      self.client = null;
    },
  }))
  .views(() => ({}));

export const clientsStore = ClientsStore.create({
  client: null
});
