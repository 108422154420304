/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { PaginatedResponse } from "src/modules/api";
import { ClientLocationModel, PaginatedModels } from "src/modules/models";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const ClientLocationReference = createEntityReference("clientLocations", ClientLocationModel);

export const ClientLocationsStore = createEntityStore("ClientLocation", ClientLocationModel)
  .props({
    clientLocations: types.maybe(PaginatedModels(ClientLocationReference)),
    clientLocation: types.maybeNull(ClientLocationModel),
    currentClientLocationCheckedIn: types.maybeNull(ClientLocationModel),
  })
  .actions((self) => ({
    findClientLocations: flow(function* (findClientLocationData: any) {
      const {
        api: { clientLocations: clientLocationApi },
      } = getEnv<RootStoreEnv>(self);

      const result: PaginatedResponse<any> = yield clientLocationApi.fetchClientLocations(findClientLocationData);
      const clientLocations = result.data.map((clientLocation: any) => {
        const entityModel = self.cacheEntity(
          ClientLocationModel.create({
            rawClientLocation: clientLocation,
            ...(clientLocation as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.clientLocations = cast({
        ...result,
        data: cast(clientLocations as any),
      });

      return self.clientLocations as PaginatedModels<typeof ClientLocationModel>;
    }),
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { clientLocations: clientLocationApi },
      } = getEnv<RootStoreEnv>(self);

      const clientLocation = yield clientLocationApi.fetchOneClientLocation(entityId);
      self.clientLocation = clientLocation;
      return ClientLocationModel.create({
        rawClient: clientLocation,
        ...clientLocation,
      });
    }),
    clearClientLocation() {
      self.clientLocation = null;
    },
  }))
  .actions((self) => ({
    setCurrentClientLocationCheckedIn: flow(function* (id?: string) {
      if(!id){
        self.currentClientLocationCheckedIn = null;
        return;
      }
      const clientLocation = yield self.fetchEntity(id);
      self.currentClientLocationCheckedIn = clientLocation;
      return self.currentClientLocationCheckedIn;
    }),
    generateQrCode: flow(function* (clientLocationId: string) {
      const {
        api: { clientLocations: clientLocationApi },
      } = getEnv<RootStoreEnv>(self);
      const clientLocation = yield clientLocationApi.generateQrCode(clientLocationId);
      self.clientLocation = clientLocation;
      return clientLocation;
    }),
    clearCurrentClientLocationCheckedIn() {
      self.currentClientLocationCheckedIn = null;
    },
  }))
  .views(() => ({}));

export const clientLocationsStore = ClientLocationsStore.create({});
