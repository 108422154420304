import { Instance, types } from "mobx-state-tree";

import { UserModel } from 'src/modules/models/user.model';

export const ContactModel = types.model("ContactModel", {
  id: types.identifier,
  user: types.frozen(UserModel),
  phoneNumberPerso: types.maybeNull(types.string),
  civility: types.maybeNull(types.string),
  fonction: types.maybeNull(types.string),
  isMainContact: types.maybe(types.boolean),
});

export type ContactModelType = Instance<typeof ContactModel>;
