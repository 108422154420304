/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";

interface DataGridDeleteProps {
  title: ((data: any) => string) | string;
  description: ((data: any) => string) | string;
  onDelete: (data: any) => Promise<void>;
}

interface DataGridDeleteHandle {
  isOpen: boolean;
  open(data?: any): void;
  data: any;
}

export const DataGridDelete = forwardRef<DataGridDeleteHandle, DataGridDeleteProps>((props, ref) => {
  const { onDelete: onDeleteCallback, title, description } = props;
  const [data, setData] = useState<any>();
  const isOpen = useMemo(() => !!data, [data]);

  const onDelete = useCallback(() => {
    onDeleteCallback(data).then(() => {
      setData(null);
    });
  }, [data, onDeleteCallback]);

  useImperativeHandle(ref, () => ({
    get data() {
      return data;
    },
    open(data?: any) {
      setData(data);
    },
    get isOpen() {
      return !!data;
    },
  }));

  return (
    <label htmlFor="my-modal-4" className={`${isOpen ? "modal-open" : ""} cursor-pointer modal`}>
      <label className="relative modal-box" htmlFor="">
        <h3 className="text-lg font-bold">{title && (typeof title === "string" ? title : title(data))}</h3>
        <p className="py-4"> {description && (typeof description === "string" ? description : description(data))}</p>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button type="button" className="ml-2 btn btn-outline btn-error" onClick={() => onDelete()}>
            Supprimer
          </button>
          <button type="button" className=" btn btn-outline" onClick={() => setData(null)}>
            Annuler
          </button>
        </div>
      </label>
    </label>
  );
});

DataGridDelete.displayName = "DataGridDelete";
