import { ClientLocationOperationTypeEnum, DocumentObject } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

export const RegularInstructionModel = types.model("RegularInstructionModel", {
  id: types.identifier,
  title: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  frequency: types.maybeNull(types.enumeration(Object.values(ClientLocationOperationTypeEnum))),
  documents: types.maybeNull(types.array(types.frozen<DocumentObject>())),
});

export type RegularInstructionModelType = Instance<typeof RegularInstructionModel>;
