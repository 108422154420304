/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { PaginatedModels, ProductModel } from "src/modules/models";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";

export const ProductReference = createEntityReference("products", ProductModel);

export const ProductsStore = createEntityStore("Product", ProductModel)
  .props({
    productsPaginated: types.maybe(PaginatedModels(ProductReference)),
    products: types.maybeNull(types.array(ProductModel)),
    product: types.maybeNull(ProductModel),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { products: productApi },
      } = getEnv<RootStoreEnv>(self);

      const product = yield productApi.fetchOneProduct(entityId);

      return ProductModel.create({
        rawProcuct: product,
        ...product,
      });
    }),
    findProducts: flow(function* (findProductData: any) {
      const {
        api: { products: productApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield productApi.findProducts(findProductData);

      const products = result.data.map((product: any) => {
        const entityModel = self.cacheEntity(
          ProductModel.create({
            rawProduct: product,
            ...(product as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.productsPaginated = cast({
        ...result,
        data: cast(products as any),
      });

      return self.productsPaginated as PaginatedModels<typeof ProductModel>;
    }),
    fetchProducts: flow(function* () {
      const {
        api: { products },
      } = getEnv<RootStoreEnv>(self);

      const result = yield products.fetchProducts();
      self.products = result;
      return self.products;
    }),
    clearProduct() {
      self.product = null;
    },
  }))
  .views(() => ({}));

export const productsStore = ProductsStore.create({
  products: [],
});
