/* eslint-disable @typescript-eslint/no-explicit-any */
import { flow, getEnv, types } from "mobx-state-tree";

import { UrgentInstructionModel } from "src/modules/models";

import { RootStoreEnv } from "./root.store";

export const UrgentInstructionsStore = types
  .model("urgentInstruction", {
    urgentInstructions: types.maybeNull(types.array(UrgentInstructionModel)),
    urgentInstruction: types.maybeNull(UrgentInstructionModel),

  })
  .actions((self) => ({
    findUrgentInstructions: flow(function* _(entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.findUrgentInstructions(entityId);
        self.urgentInstructions = result;

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    findUrgentInstruction: flow(function* _(entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.findOneUrgentInstruction(entityId);
        self.urgentInstruction = result;

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    clearUrgentInstruction() {
      self.urgentInstruction = null;
    },
  }))
  .actions((self) => ({
    updateUrgentInstructions: flow(function* _(clientLocationId: string, entityId: string, data: any) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.updateUrgentInstructions(entityId, data);
        self.findUrgentInstructions(clientLocationId);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    createUrgentInstruction: flow(function* _(clientLocationId: string, data: any) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.createUrgentInstruction(data);
        self.findUrgentInstruction(result.id);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    uploadUrgentDocument: flow(function* _(clientLocationId: string, entityId: string, files: FileList) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.uploadUrgentDocument(entityId, files);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteUrgentDocument: flow(function* _(entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.deleteUrgentDocument(entityId);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteUrgentInstruction: flow(function* _(clientLocationId: string, entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.deleteUrgentInstruction(entityId);
        self.findUrgentInstructions(clientLocationId);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
  }))
  .views(() => ({}));

export const urgentInstructionsStore = UrgentInstructionsStore.create({
  urgentInstructions: [],
});
