export const nationalityOption = [
  {
    num_code: "4",
    key: "AF",
    alpha_3_code: "AFG",
    en_short_name: "Afghanistan",
    value: "Afghan",
  },
  {
    num_code: "248",
    key: "AX",
    alpha_3_code: "ALA",
    en_short_name: "\u00c5land Islands",
    value: "\u00c5land Island",
  },
  {
    num_code: "8",
    key: "AL",
    alpha_3_code: "ALB",
    en_short_name: "Albania",
    value: "Albanian",
  },
  {
    num_code: "12",
    key: "DZ",
    alpha_3_code: "DZA",
    en_short_name: "Algeria",
    value: "Algerian",
  },
  {
    num_code: "16",
    key: "AS",
    alpha_3_code: "ASM",
    en_short_name: "American Samoa",
    value: "American Samoan",
  },
  {
    num_code: "20",
    key: "AD",
    alpha_3_code: "AND",
    en_short_name: "Andorra",
    value: "Andorran",
  },
  {
    num_code: "24",
    key: "AO",
    alpha_3_code: "AGO",
    en_short_name: "Angola",
    value: "Angolan",
  },
  {
    num_code: "660",
    key: "AI",
    alpha_3_code: "AIA",
    en_short_name: "Anguilla",
    value: "Anguillan",
  },
  {
    num_code: "10",
    key: "AQ",
    alpha_3_code: "ATA",
    en_short_name: "Antarctica",
    value: "Antarctic",
  },
  {
    num_code: "28",
    key: "AG",
    alpha_3_code: "ATG",
    en_short_name: "Antigua and Barbuda",
    value: "Antiguan or Barbudan",
  },
  {
    num_code: "32",
    key: "AR",
    alpha_3_code: "ARG",
    en_short_name: "Argentina",
    value: "Argentine",
  },
  {
    num_code: "51",
    key: "AM",
    alpha_3_code: "ARM",
    en_short_name: "Armenia",
    value: "Armenian",
  },
  {
    num_code: "533",
    key: "AW",
    alpha_3_code: "ABW",
    en_short_name: "Aruba",
    value: "Aruban",
  },
  {
    num_code: "36",
    key: "AU",
    alpha_3_code: "AUS",
    en_short_name: "Australia",
    value: "Australian",
  },
  {
    num_code: "40",
    key: "AT",
    alpha_3_code: "AUT",
    en_short_name: "Austria",
    value: "Austrian",
  },
  {
    num_code: "31",
    key: "AZ",
    alpha_3_code: "AZE",
    en_short_name: "Azerbaijan",
    value: "Azerbaijani, Azeri",
  },
  {
    num_code: "44",
    key: "BS",
    alpha_3_code: "BHS",
    en_short_name: "Bahamas",
    value: "Bahamian",
  },
  {
    num_code: "48",
    key: "BH",
    alpha_3_code: "BHR",
    en_short_name: "Bahrain",
    value: "Bahraini",
  },
  {
    num_code: "50",
    key: "BD",
    alpha_3_code: "BGD",
    en_short_name: "Bangladesh",
    value: "Bangladeshi",
  },
  {
    num_code: "52",
    key: "BB",
    alpha_3_code: "BRB",
    en_short_name: "Barbados",
    value: "Barbadian",
  },
  {
    num_code: "112",
    key: "BY",
    alpha_3_code: "BLR",
    en_short_name: "Belarus",
    value: "Belarusian",
  },
  {
    num_code: "56",
    key: "BE",
    alpha_3_code: "BEL",
    en_short_name: "Belgium",
    value: "Belgian",
  },
  {
    num_code: "84",
    key: "BZ",
    alpha_3_code: "BLZ",
    en_short_name: "Belize",
    value: "Belizean",
  },
  {
    num_code: "204",
    key: "BJ",
    alpha_3_code: "BEN",
    en_short_name: "Benin",
    value: "Beninese, Beninois",
  },
  {
    num_code: "60",
    key: "BM",
    alpha_3_code: "BMU",
    en_short_name: "Bermuda",
    value: "Bermudian, Bermudan",
  },
  {
    num_code: "64",
    key: "BT",
    alpha_3_code: "BTN",
    en_short_name: "Bhutan",
    value: "Bhutanese",
  },
  {
    num_code: "68",
    key: "BO",
    alpha_3_code: "BOL",
    en_short_name: "Bolivia (Plurinational State of)",
    value: "Bolivian",
  },
  {
    num_code: "535",
    key: "BQ",
    alpha_3_code: "BES",
    en_short_name: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire",
  },
  {
    num_code: "70",
    key: "BA",
    alpha_3_code: "BIH",
    en_short_name: "Bosnia and Herzegovina",
    value: "Bosnian or Herzegovinian",
  },
  {
    num_code: "72",
    key: "BW",
    alpha_3_code: "BWA",
    en_short_name: "Botswana",
    value: "Motswana, Botswanan",
  },
  {
    num_code: "74",
    key: "BV",
    alpha_3_code: "BVT",
    en_short_name: "Bouvet Island",
    value: "Bouvet Island",
  },
  {
    num_code: "76",
    key: "BR",
    alpha_3_code: "BRA",
    en_short_name: "Brazil",
    value: "Brazilian",
  },
  {
    num_code: "86",
    key: "IO",
    alpha_3_code: "IOT",
    en_short_name: "British Indian Ocean Territory",
    value: "BIOT",
  },
  {
    num_code: "96",
    key: "BN",
    alpha_3_code: "BRN",
    en_short_name: "Brunei Darussalam",
    value: "Bruneian",
  },
  {
    num_code: "100",
    key: "BG",
    alpha_3_code: "BGR",
    en_short_name: "Bulgaria",
    value: "Bulgarian",
  },
  {
    num_code: "854",
    key: "BF",
    alpha_3_code: "BFA",
    en_short_name: "Burkina Faso",
    value: "Burkinab\u00e9",
  },
  {
    num_code: "108",
    key: "BI",
    alpha_3_code: "BDI",
    en_short_name: "Burundi",
    value: "Burundian",
  },
  {
    num_code: "132",
    key: "CV",
    alpha_3_code: "CPV",
    en_short_name: "Cabo Verde",
    value: "Cabo Verdean",
  },
  {
    num_code: "116",
    key: "KH",
    alpha_3_code: "KHM",
    en_short_name: "Cambodia",
    value: "Cambodian",
  },
  {
    num_code: "120",
    key: "CM",
    alpha_3_code: "CMR",
    en_short_name: "Cameroon",
    value: "Cameroonian",
  },
  {
    num_code: "124",
    key: "CA",
    alpha_3_code: "CAN",
    en_short_name: "Canada",
    value: "Canadian",
  },
  {
    num_code: "136",
    key: "KY",
    alpha_3_code: "CYM",
    en_short_name: "Cayman Islands",
    value: "Caymanian",
  },
  {
    num_code: "140",
    key: "CF",
    alpha_3_code: "CAF",
    en_short_name: "Central African Republic",
    value: "Central African",
  },
  {
    num_code: "148",
    key: "TD",
    alpha_3_code: "TCD",
    en_short_name: "Chad",
    value: "Chadian",
  },
  {
    num_code: "152",
    key: "CL",
    alpha_3_code: "CHL",
    en_short_name: "Chile",
    value: "Chilean",
  },
  {
    num_code: "156",
    key: "CN",
    alpha_3_code: "CHN",
    en_short_name: "China",
    value: "Chinese",
  },
  {
    num_code: "162",
    key: "CX",
    alpha_3_code: "CXR",
    en_short_name: "Christmas Island",
    value: "Christmas Island",
  },
  {
    num_code: "166",
    key: "CC",
    alpha_3_code: "CCK",
    en_short_name: "Cocos (Keeling) Islands",
    value: "Cocos Island",
  },
  {
    num_code: "170",
    key: "CO",
    alpha_3_code: "COL",
    en_short_name: "Colombia",
    value: "Colombian",
  },
  {
    num_code: "174",
    key: "KM",
    alpha_3_code: "COM",
    en_short_name: "Comoros",
    value: "Comoran, Comorian",
  },
  {
    num_code: "178",
    key: "CG",
    alpha_3_code: "COG",
    en_short_name: "Congo (Republic of the)",
    value: "Congolese",
  },
  {
    num_code: "180",
    key: "CD",
    alpha_3_code: "COD",
    en_short_name: "Congo (Democratic Republic of the)",
    value: "Congolese",
  },
  {
    num_code: "184",
    key: "CK",
    alpha_3_code: "COK",
    en_short_name: "Cook Islands",
    value: "Cook Island",
  },
  {
    num_code: "188",
    key: "CR",
    alpha_3_code: "CRI",
    en_short_name: "Costa Rica",
    value: "Costa Rican",
  },
  {
    num_code: "384",
    key: "CI",
    alpha_3_code: "CIV",
    en_short_name: "C\u00f4te d'Ivoire",
    value: "Ivorian",
  },
  {
    num_code: "191",
    key: "HR",
    alpha_3_code: "HRV",
    en_short_name: "Croatia",
    value: "Croatian",
  },
  {
    num_code: "192",
    key: "CU",
    alpha_3_code: "CUB",
    en_short_name: "Cuba",
    value: "Cuban",
  },
  {
    num_code: "531",
    key: "CW",
    alpha_3_code: "CUW",
    en_short_name: "Cura\u00e7ao",
    value: "Cura\u00e7aoan",
  },
  {
    num_code: "196",
    key: "CY",
    alpha_3_code: "CYP",
    en_short_name: "Cyprus",
    value: "Cypriot",
  },
  {
    num_code: "203",
    key: "CZ",
    alpha_3_code: "CZE",
    en_short_name: "Czech Republic",
    value: "Czech",
  },
  {
    num_code: "208",
    key: "DK",
    alpha_3_code: "DNK",
    en_short_name: "Denmark",
    value: "Danish",
  },
  {
    num_code: "262",
    key: "DJ",
    alpha_3_code: "DJI",
    en_short_name: "Djibouti",
    value: "Djiboutian",
  },
  {
    num_code: "212",
    key: "DM",
    alpha_3_code: "DMA",
    en_short_name: "Dominica",
    value: "Dominican",
  },
  {
    num_code: "214",
    key: "DO",
    alpha_3_code: "DOM",
    en_short_name: "Dominican Republic",
    value: "Dominican",
  },
  {
    num_code: "218",
    key: "EC",
    alpha_3_code: "ECU",
    en_short_name: "Ecuador",
    value: "Ecuadorian",
  },
  {
    num_code: "818",
    key: "EG",
    alpha_3_code: "EGY",
    en_short_name: "Egypt",
    value: "Egyptian",
  },
  {
    num_code: "222",
    key: "SV",
    alpha_3_code: "SLV",
    en_short_name: "El Salvador",
    value: "Salvadoran",
  },
  {
    num_code: "226",
    key: "GQ",
    alpha_3_code: "GNQ",
    en_short_name: "Equatorial Guinea",
    value: "Equatorial Guinean, Equatoguinean",
  },
  {
    num_code: "232",
    key: "ER",
    alpha_3_code: "ERI",
    en_short_name: "Eritrea",
    value: "Eritrean",
  },
  {
    num_code: "233",
    key: "EE",
    alpha_3_code: "EST",
    en_short_name: "Estonia",
    value: "Estonian",
  },
  {
    num_code: "231",
    key: "ET",
    alpha_3_code: "ETH",
    en_short_name: "Ethiopia",
    value: "Ethiopian",
  },
  {
    num_code: "238",
    key: "FK",
    alpha_3_code: "FLK",
    en_short_name: "Falkland Islands (Malvinas)",
    value: "Falkland Island",
  },
  {
    num_code: "234",
    key: "FO",
    alpha_3_code: "FRO",
    en_short_name: "Faroe Islands",
    value: "Faroese",
  },
  {
    num_code: "242",
    key: "FJ",
    alpha_3_code: "FJI",
    en_short_name: "Fiji",
    value: "Fijian",
  },
  {
    num_code: "246",
    key: "FI",
    alpha_3_code: "FIN",
    en_short_name: "Finland",
    value: "Finnish",
  },
  {
    num_code: "250",
    key: "FR",
    alpha_3_code: "FRA",
    en_short_name: "France",
    value: "French",
  },
  {
    num_code: "254",
    key: "GF",
    alpha_3_code: "GUF",
    en_short_name: "French Guiana",
    value: "French Guianese",
  },
  {
    num_code: "258",
    key: "PF",
    alpha_3_code: "PYF",
    en_short_name: "French Polynesia",
    value: "French Polynesian",
  },
  {
    num_code: "260",
    key: "TF",
    alpha_3_code: "ATF",
    en_short_name: "French Southern Territories",
    value: "French Southern Territories",
  },
  {
    num_code: "266",
    key: "GA",
    alpha_3_code: "GAB",
    en_short_name: "Gabon",
    value: "Gabonese",
  },
  {
    num_code: "270",
    key: "GM",
    alpha_3_code: "GMB",
    en_short_name: "Gambia",
    value: "Gambian",
  },
  {
    num_code: "268",
    key: "GE",
    alpha_3_code: "GEO",
    en_short_name: "Georgia",
    value: "Georgian",
  },
  {
    num_code: "276",
    key: "DE",
    alpha_3_code: "DEU",
    en_short_name: "Germany",
    value: "German",
  },
  {
    num_code: "288",
    key: "GH",
    alpha_3_code: "GHA",
    en_short_name: "Ghana",
    value: "Ghanaian",
  },
  {
    num_code: "292",
    key: "GI",
    alpha_3_code: "GIB",
    en_short_name: "Gibraltar",
    value: "Gibraltar",
  },
  {
    num_code: "300",
    key: "GR",
    alpha_3_code: "GRC",
    en_short_name: "Greece",
    value: "Greek, Hellenic",
  },
  {
    num_code: "304",
    key: "GL",
    alpha_3_code: "GRL",
    en_short_name: "Greenland",
    value: "Greenlandic",
  },
  {
    num_code: "308",
    key: "GD",
    alpha_3_code: "GRD",
    en_short_name: "Grenada",
    value: "Grenadian",
  },
  {
    num_code: "312",
    key: "GP",
    alpha_3_code: "GLP",
    en_short_name: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    num_code: "316",
    key: "GU",
    alpha_3_code: "GUM",
    en_short_name: "Guam",
    value: "Guamanian, Guambat",
  },
  {
    num_code: "320",
    key: "GT",
    alpha_3_code: "GTM",
    en_short_name: "Guatemala",
    value: "Guatemalan",
  },
  {
    num_code: "831",
    key: "GG",
    alpha_3_code: "GGY",
    en_short_name: "Guernsey",
    value: "Channel Island",
  },
  {
    num_code: "324",
    key: "GN",
    alpha_3_code: "GIN",
    en_short_name: "Guinea",
    value: "Guinean",
  },
  {
    num_code: "624",
    key: "GW",
    alpha_3_code: "GNB",
    en_short_name: "Guinea-Bissau",
    value: "Bissau-Guinean",
  },
  {
    num_code: "328",
    key: "GY",
    alpha_3_code: "GUY",
    en_short_name: "Guyana",
    value: "Guyanese",
  },
  {
    num_code: "332",
    key: "HT",
    alpha_3_code: "HTI",
    en_short_name: "Haiti",
    value: "Haitian",
  },
  {
    num_code: "334",
    key: "HM",
    alpha_3_code: "HMD",
    en_short_name: "Heard Island and McDonald Islands",
    value: "Heard Island or McDonald Islands",
  },
  {
    num_code: "336",
    key: "VA",
    alpha_3_code: "VAT",
    en_short_name: "Vatican City State",
    value: "Vatican",
  },
  {
    num_code: "340",
    key: "HN",
    alpha_3_code: "HND",
    en_short_name: "Honduras",
    value: "Honduran",
  },
  {
    num_code: "344",
    key: "HK",
    alpha_3_code: "HKG",
    en_short_name: "Hong Kong",
    value: "Hong Kong, Hong Kongese",
  },
  {
    num_code: "348",
    key: "HU",
    alpha_3_code: "HUN",
    en_short_name: "Hungary",
    value: "Hungarian, Magyar",
  },
  {
    num_code: "352",
    key: "IS",
    alpha_3_code: "ISL",
    en_short_name: "Iceland",
    value: "Icelandic",
  },
  {
    num_code: "356",
    key: "IN",
    alpha_3_code: "IND",
    en_short_name: "India",
    value: "Indian",
  },
  {
    num_code: "360",
    key: "ID",
    alpha_3_code: "IDN",
    en_short_name: "Indonesia",
    value: "Indonesian",
  },
  {
    num_code: "364",
    key: "IR",
    alpha_3_code: "IRN",
    en_short_name: "Iran",
    value: "Iranian, Persian",
  },
  {
    num_code: "368",
    key: "IQ",
    alpha_3_code: "IRQ",
    en_short_name: "Iraq",
    value: "Iraqi",
  },
  {
    num_code: "372",
    key: "IE",
    alpha_3_code: "IRL",
    en_short_name: "Ireland",
    value: "Irish",
  },
  {
    num_code: "833",
    key: "IM",
    alpha_3_code: "IMN",
    en_short_name: "Isle of Man",
    value: "Manx",
  },
  {
    num_code: "376",
    key: "IL",
    alpha_3_code: "ISR",
    en_short_name: "Israel",
    value: "Israeli",
  },
  {
    num_code: "380",
    key: "IT",
    alpha_3_code: "ITA",
    en_short_name: "Italy",
    value: "Italian",
  },
  {
    num_code: "388",
    key: "JM",
    alpha_3_code: "JAM",
    en_short_name: "Jamaica",
    value: "Jamaican",
  },
  {
    num_code: "392",
    key: "JP",
    alpha_3_code: "JPN",
    en_short_name: "Japan",
    value: "Japanese",
  },
  {
    num_code: "832",
    key: "JE",
    alpha_3_code: "JEY",
    en_short_name: "Jersey",
    value: "Channel Island",
  },
  {
    num_code: "400",
    key: "JO",
    alpha_3_code: "JOR",
    en_short_name: "Jordan",
    value: "Jordanian",
  },
  {
    num_code: "398",
    key: "KZ",
    alpha_3_code: "KAZ",
    en_short_name: "Kazakhstan",
    value: "Kazakhstani, Kazakh",
  },
  {
    num_code: "404",
    key: "KE",
    alpha_3_code: "KEN",
    en_short_name: "Kenya",
    value: "Kenyan",
  },
  {
    num_code: "296",
    key: "KI",
    alpha_3_code: "KIR",
    en_short_name: "Kiribati",
    value: "I-Kiribati",
  },
  {
    num_code: "408",
    key: "KP",
    alpha_3_code: "PRK",
    en_short_name: "Korea (Democratic People's Republic of)",
    value: "North Korean",
  },
  {
    num_code: "410",
    key: "KR",
    alpha_3_code: "KOR",
    en_short_name: "Korea (Republic of)",
    value: "South Korean",
  },
  {
    num_code: "414",
    key: "KW",
    alpha_3_code: "KWT",
    en_short_name: "Kuwait",
    value: "Kuwaiti",
  },
  {
    num_code: "417",
    key: "KG",
    alpha_3_code: "KGZ",
    en_short_name: "Kyrgyzstan",
    value: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
  },
  {
    num_code: "418",
    key: "LA",
    alpha_3_code: "LAO",
    en_short_name: "Lao People's Democratic Republic",
    value: "Lao, Laotian",
  },
  {
    num_code: "428",
    key: "LV",
    alpha_3_code: "LVA",
    en_short_name: "Latvia",
    value: "Latvian",
  },
  {
    num_code: "422",
    key: "LB",
    alpha_3_code: "LBN",
    en_short_name: "Lebanon",
    value: "Lebanese",
  },
  {
    num_code: "426",
    key: "LS",
    alpha_3_code: "LSO",
    en_short_name: "Lesotho",
    value: "Basotho",
  },
  {
    num_code: "430",
    key: "LR",
    alpha_3_code: "LBR",
    en_short_name: "Liberia",
    value: "Liberian",
  },
  {
    num_code: "434",
    key: "LY",
    alpha_3_code: "LBY",
    en_short_name: "Libya",
    value: "Libyan",
  },
  {
    num_code: "438",
    key: "LI",
    alpha_3_code: "LIE",
    en_short_name: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    num_code: "440",
    key: "LT",
    alpha_3_code: "LTU",
    en_short_name: "Lithuania",
    value: "Lithuanian",
  },
  {
    num_code: "442",
    key: "LU",
    alpha_3_code: "LUX",
    en_short_name: "Luxembourg",
    value: "Luxembourg, Luxembourgish",
  },
  {
    num_code: "446",
    key: "MO",
    alpha_3_code: "MAC",
    en_short_name: "Macao",
    value: "Macanese, Chinese",
  },
  {
    num_code: "807",
    key: "MK",
    alpha_3_code: "MKD",
    en_short_name: "Macedonia (the former Yugoslav Republic of)",
    value: "Macedonian",
  },
  {
    num_code: "450",
    key: "MG",
    alpha_3_code: "MDG",
    en_short_name: "Madagascar",
    value: "Malagasy",
  },
  {
    num_code: "454",
    key: "MW",
    alpha_3_code: "MWI",
    en_short_name: "Malawi",
    value: "Malawian",
  },
  {
    num_code: "458",
    key: "MY",
    alpha_3_code: "MYS",
    en_short_name: "Malaysia",
    value: "Malaysian",
  },
  {
    num_code: "462",
    key: "MV",
    alpha_3_code: "MDV",
    en_short_name: "Maldives",
    value: "Maldivian",
  },
  {
    num_code: "466",
    key: "ML",
    alpha_3_code: "MLI",
    en_short_name: "Mali",
    value: "Malian, Malinese",
  },
  {
    num_code: "470",
    key: "MT",
    alpha_3_code: "MLT",
    en_short_name: "Malta",
    value: "Maltese",
  },
  {
    num_code: "584",
    key: "MH",
    alpha_3_code: "MHL",
    en_short_name: "Marshall Islands",
    value: "Marshallese",
  },
  {
    num_code: "474",
    key: "MQ",
    alpha_3_code: "MTQ",
    en_short_name: "Martinique",
    value: "Martiniquais, Martinican",
  },
  {
    num_code: "478",
    key: "MR",
    alpha_3_code: "MRT",
    en_short_name: "Mauritania",
    value: "Mauritanian",
  },
  {
    num_code: "480",
    key: "MU",
    alpha_3_code: "MUS",
    en_short_name: "Mauritius",
    value: "Mauritian",
  },
  {
    num_code: "175",
    key: "YT",
    alpha_3_code: "MYT",
    en_short_name: "Mayotte",
    value: "Mahoran",
  },
  {
    num_code: "484",
    key: "MX",
    alpha_3_code: "MEX",
    en_short_name: "Mexico",
    value: "Mexican",
  },
  {
    num_code: "583",
    key: "FM",
    alpha_3_code: "FSM",
    en_short_name: "Micronesia (Federated States of)",
    value: "Micronesian",
  },
  {
    num_code: "498",
    key: "MD",
    alpha_3_code: "MDA",
    en_short_name: "Moldova (Republic of)",
    value: "Moldovan",
  },
  {
    num_code: "492",
    key: "MC",
    alpha_3_code: "MCO",
    en_short_name: "Monaco",
    value: "Mon\u00e9gasque, Monacan",
  },
  {
    num_code: "496",
    key: "MN",
    alpha_3_code: "MNG",
    en_short_name: "Mongolia",
    value: "Mongolian",
  },
  {
    num_code: "499",
    key: "ME",
    alpha_3_code: "MNE",
    en_short_name: "Montenegro",
    value: "Montenegrin",
  },
  {
    num_code: "500",
    key: "MS",
    alpha_3_code: "MSR",
    en_short_name: "Montserrat",
    value: "Montserratian",
  },
  {
    num_code: "504",
    key: "MA",
    alpha_3_code: "MAR",
    en_short_name: "Morocco",
    value: "Moroccan",
  },
  {
    num_code: "508",
    key: "MZ",
    alpha_3_code: "MOZ",
    en_short_name: "Mozambique",
    value: "Mozambican",
  },
  {
    num_code: "104",
    key: "MM",
    alpha_3_code: "MMR",
    en_short_name: "Myanmar",
    value: "Burmese",
  },
  {
    num_code: "516",
    key: "NA",
    alpha_3_code: "NAM",
    en_short_name: "Namibia",
    value: "Namibian",
  },
  {
    num_code: "520",
    key: "NR",
    alpha_3_code: "NRU",
    en_short_name: "Nauru",
    value: "Nauruan",
  },
  {
    num_code: "524",
    key: "NP",
    alpha_3_code: "NPL",
    en_short_name: "Nepal",
    value: "Nepali, Nepalese",
  },
  {
    num_code: "528",
    key: "NL",
    alpha_3_code: "NLD",
    en_short_name: "Netherlands",
    value: "Dutch, Netherlandic",
  },
  {
    num_code: "540",
    key: "NC",
    alpha_3_code: "NCL",
    en_short_name: "New Caledonia",
    value: "New Caledonian",
  },
  {
    num_code: "554",
    key: "NZ",
    alpha_3_code: "NZL",
    en_short_name: "New Zealand",
    value: "New Zealand, NZ",
  },
  {
    num_code: "558",
    key: "NI",
    alpha_3_code: "NIC",
    en_short_name: "Nicaragua",
    value: "Nicaraguan",
  },
  {
    num_code: "562",
    key: "NE",
    alpha_3_code: "NER",
    en_short_name: "Niger",
    value: "Nigerien",
  },
  {
    num_code: "566",
    key: "NG",
    alpha_3_code: "NGA",
    en_short_name: "Nigeria",
    value: "Nigerian",
  },
  {
    num_code: "570",
    key: "NU",
    alpha_3_code: "NIU",
    en_short_name: "Niue",
    value: "Niuean",
  },
  {
    num_code: "574",
    key: "NF",
    alpha_3_code: "NFK",
    en_short_name: "Norfolk Island",
    value: "Norfolk Island",
  },
  {
    num_code: "580",
    key: "MP",
    alpha_3_code: "MNP",
    en_short_name: "Northern Mariana Islands",
    value: "Northern Marianan",
  },
  {
    num_code: "578",
    key: "NO",
    alpha_3_code: "NOR",
    en_short_name: "Norway",
    value: "Norwegian",
  },
  {
    num_code: "512",
    key: "OM",
    alpha_3_code: "OMN",
    en_short_name: "Oman",
    value: "Omani",
  },
  {
    num_code: "586",
    key: "PK",
    alpha_3_code: "PAK",
    en_short_name: "Pakistan",
    value: "Pakistani",
  },
  {
    num_code: "585",
    key: "PW",
    alpha_3_code: "PLW",
    en_short_name: "Palau",
    value: "Palauan",
  },
  {
    num_code: "275",
    key: "PS",
    alpha_3_code: "PSE",
    en_short_name: "Palestine, State of",
    value: "Palestinian",
  },
  {
    num_code: "591",
    key: "PA",
    alpha_3_code: "PAN",
    en_short_name: "Panama",
    value: "Panamanian",
  },
  {
    num_code: "598",
    key: "PG",
    alpha_3_code: "PNG",
    en_short_name: "Papua New Guinea",
    value: "Papua New Guinean, Papuan",
  },
  {
    num_code: "600",
    key: "PY",
    alpha_3_code: "PRY",
    en_short_name: "Paraguay",
    value: "Paraguayan",
  },
  {
    num_code: "604",
    key: "PE",
    alpha_3_code: "PER",
    en_short_name: "Peru",
    value: "Peruvian",
  },
  {
    num_code: "608",
    key: "PH",
    alpha_3_code: "PHL",
    en_short_name: "Philippines",
    value: "Philippine, Filipino",
  },
  {
    num_code: "612",
    key: "PN",
    alpha_3_code: "PCN",
    en_short_name: "Pitcairn",
    value: "Pitcairn Island",
  },
  {
    num_code: "616",
    key: "PL",
    alpha_3_code: "POL",
    en_short_name: "Poland",
    value: "Polish",
  },
  {
    num_code: "620",
    key: "PT",
    alpha_3_code: "PRT",
    en_short_name: "Portugal",
    value: "Portuguese",
  },
  {
    num_code: "630",
    key: "PR",
    alpha_3_code: "PRI",
    en_short_name: "Puerto Rico",
    value: "Puerto Rican",
  },
  {
    num_code: "634",
    key: "QA",
    alpha_3_code: "QAT",
    en_short_name: "Qatar",
    value: "Qatari",
  },
  {
    num_code: "638",
    key: "RE",
    alpha_3_code: "REU",
    en_short_name: "R\u00e9union",
    value: "R\u00e9unionese, R\u00e9unionnais",
  },
  {
    num_code: "642",
    key: "RO",
    alpha_3_code: "ROU",
    en_short_name: "Romania",
    value: "Romanian",
  },
  {
    num_code: "643",
    key: "RU",
    alpha_3_code: "RUS",
    en_short_name: "Russian Federation",
    value: "Russian",
  },
  {
    num_code: "646",
    key: "RW",
    alpha_3_code: "RWA",
    en_short_name: "Rwanda",
    value: "Rwandan",
  },
  {
    num_code: "652",
    key: "BL",
    alpha_3_code: "BLM",
    en_short_name: "Saint Barth\u00e9lemy",
    value: "Barth\u00e9lemois",
  },
  {
    num_code: "654",
    key: "SH",
    alpha_3_code: "SHN",
    en_short_name: "Saint Helena, Ascension and Tristan da Cunha",
    value: "Saint Helenian",
  },
  {
    num_code: "659",
    key: "KN",
    alpha_3_code: "KNA",
    en_short_name: "Saint Kitts and Nevis",
    value: "Kittitian or Nevisian",
  },
  {
    num_code: "662",
    key: "LC",
    alpha_3_code: "LCA",
    en_short_name: "Saint Lucia",
    value: "Saint Lucian",
  },
  {
    num_code: "663",
    key: "MF",
    alpha_3_code: "MAF",
    en_short_name: "Saint Martin (French part)",
    value: "Saint-Martinoise",
  },
  {
    num_code: "666",
    key: "PM",
    alpha_3_code: "SPM",
    en_short_name: "Saint Pierre and Miquelon",
    value: "Saint-Pierrais or Miquelonnais",
  },
  {
    num_code: "670",
    key: "VC",
    alpha_3_code: "VCT",
    en_short_name: "Saint Vincent and the Grenadines",
    value: "Saint Vincentian, Vincentian",
  },
  {
    num_code: "882",
    key: "WS",
    alpha_3_code: "WSM",
    en_short_name: "Samoa",
    value: "Samoan",
  },
  {
    num_code: "674",
    key: "SM",
    alpha_3_code: "SMR",
    en_short_name: "San Marino",
    value: "Sammarinese",
  },
  {
    num_code: "678",
    key: "ST",
    alpha_3_code: "STP",
    en_short_name: "Sao Tome and Principe",
    value: "S\u00e3o Tom\u00e9an",
  },
  {
    num_code: "682",
    key: "SA",
    alpha_3_code: "SAU",
    en_short_name: "Saudi Arabia",
    value: "Saudi, Saudi Arabian",
  },
  {
    num_code: "686",
    key: "SN",
    alpha_3_code: "SEN",
    en_short_name: "Senegal",
    value: "Senegalese",
  },
  {
    num_code: "688",
    key: "RS",
    alpha_3_code: "SRB",
    en_short_name: "Serbia",
    value: "Serbian",
  },
  {
    num_code: "690",
    key: "SC",
    alpha_3_code: "SYC",
    en_short_name: "Seychelles",
    value: "Seychellois",
  },
  {
    num_code: "694",
    key: "SL",
    alpha_3_code: "SLE",
    en_short_name: "Sierra Leone",
    value: "Sierra Leonean",
  },
  {
    num_code: "702",
    key: "SG",
    alpha_3_code: "SGP",
    en_short_name: "Singapore",
    value: "Singaporean",
  },
  {
    num_code: "534",
    key: "SX",
    alpha_3_code: "SXM",
    en_short_name: "Sint Maarten (Dutch part)",
    value: "Sint Maarten",
  },
  {
    num_code: "703",
    key: "SK",
    alpha_3_code: "SVK",
    en_short_name: "Slovakia",
    value: "Slovak",
  },
  {
    num_code: "705",
    key: "SI",
    alpha_3_code: "SVN",
    en_short_name: "Slovenia",
    value: "Slovenian, Slovene",
  },
  {
    num_code: "90",
    key: "SB",
    alpha_3_code: "SLB",
    en_short_name: "Solomon Islands",
    value: "Solomon Island",
  },
  {
    num_code: "706",
    key: "SO",
    alpha_3_code: "SOM",
    en_short_name: "Somalia",
    value: "Somali, Somalian",
  },
  {
    num_code: "710",
    key: "ZA",
    alpha_3_code: "ZAF",
    en_short_name: "South Africa",
    value: "South African",
  },
  {
    num_code: "239",
    key: "GS",
    alpha_3_code: "SGS",
    en_short_name: "South Georgia and the South Sandwich Islands",
    value: "South Georgia or South Sandwich Islands",
  },
  {
    num_code: "728",
    key: "SS",
    alpha_3_code: "SSD",
    en_short_name: "South Sudan",
    value: "South Sudanese",
  },
  {
    num_code: "724",
    key: "ES",
    alpha_3_code: "ESP",
    en_short_name: "Spain",
    value: "Spanish",
  },
  {
    num_code: "144",
    key: "LK",
    alpha_3_code: "LKA",
    en_short_name: "Sri Lanka",
    value: "Sri Lankan",
  },
  {
    num_code: "729",
    key: "SD",
    alpha_3_code: "SDN",
    en_short_name: "Sudan",
    value: "Sudanese",
  },
  {
    num_code: "740",
    key: "SR",
    alpha_3_code: "SUR",
    en_short_name: "Suriname",
    value: "Surinamese",
  },
  {
    num_code: "744",
    key: "SJ",
    alpha_3_code: "SJM",
    en_short_name: "Svalbard and Jan Mayen",
    value: "Svalbard",
  },
  {
    num_code: "748",
    key: "SZ",
    alpha_3_code: "SWZ",
    en_short_name: "Swaziland",
    value: "Swazi",
  },
  {
    num_code: "752",
    key: "SE",
    alpha_3_code: "SWE",
    en_short_name: "Sweden",
    value: "Swedish",
  },
  {
    num_code: "756",
    key: "CH",
    alpha_3_code: "CHE",
    en_short_name: "Switzerland",
    value: "Swiss",
  },
  {
    num_code: "760",
    key: "SY",
    alpha_3_code: "SYR",
    en_short_name: "Syrian Arab Republic",
    value: "Syrian",
  },
  {
    num_code: "158",
    key: "TW",
    alpha_3_code: "TWN",
    en_short_name: "Taiwan, Province of China",
    value: "Chinese, Taiwanese",
  },
  {
    num_code: "762",
    key: "TJ",
    alpha_3_code: "TJK",
    en_short_name: "Tajikistan",
    value: "Tajikistani",
  },
  {
    num_code: "834",
    key: "TZ",
    alpha_3_code: "TZA",
    en_short_name: "Tanzania, United Republic of",
    value: "Tanzanian",
  },
  {
    num_code: "764",
    key: "TH",
    alpha_3_code: "THA",
    en_short_name: "Thailand",
    value: "Thai",
  },
  {
    num_code: "626",
    key: "TL",
    alpha_3_code: "TLS",
    en_short_name: "Timor-Leste",
    value: "Timorese",
  },
  {
    num_code: "768",
    key: "TG",
    alpha_3_code: "TGO",
    en_short_name: "Togo",
    value: "Togolese",
  },
  {
    num_code: "772",
    key: "TK",
    alpha_3_code: "TKL",
    en_short_name: "Tokelau",
    value: "Tokelauan",
  },
  {
    num_code: "776",
    key: "TO",
    alpha_3_code: "TON",
    en_short_name: "Tonga",
    value: "Tongan",
  },
  {
    num_code: "780",
    key: "TT",
    alpha_3_code: "TTO",
    en_short_name: "Trinidad and Tobago",
    value: "Trinidadian or Tobagonian",
  },
  {
    num_code: "788",
    key: "TN",
    alpha_3_code: "TUN",
    en_short_name: "Tunisia",
    value: "Tunisian",
  },
  {
    num_code: "792",
    key: "TR",
    alpha_3_code: "TUR",
    en_short_name: "Turkey",
    value: "Turkish",
  },
  {
    num_code: "795",
    key: "TM",
    alpha_3_code: "TKM",
    en_short_name: "Turkmenistan",
    value: "Turkmen",
  },
  {
    num_code: "796",
    key: "TC",
    alpha_3_code: "TCA",
    en_short_name: "Turks and Caicos Islands",
    value: "Turks and Caicos Island",
  },
  {
    num_code: "798",
    key: "TV",
    alpha_3_code: "TUV",
    en_short_name: "Tuvalu",
    value: "Tuvaluan",
  },
  {
    num_code: "800",
    key: "UG",
    alpha_3_code: "UGA",
    en_short_name: "Uganda",
    value: "Ugandan",
  },
  {
    num_code: "804",
    key: "UA",
    alpha_3_code: "UKR",
    en_short_name: "Ukraine",
    value: "Ukrainian",
  },
  {
    num_code: "784",
    key: "AE",
    alpha_3_code: "ARE",
    en_short_name: "United Arab Emirates",
    value: "Emirati, Emirian, Emiri",
  },
  {
    num_code: "826",
    key: "GB",
    alpha_3_code: "GBR",
    en_short_name: "United Kingdom of Great Britain and Northern Ireland",
    value: "British, UK",
  },
  {
    num_code: "581",
    key: "UM",
    alpha_3_code: "UMI",
    en_short_name: "United States Minor Outlying Islands",
    value: "American",
  },
  {
    num_code: "840",
    key: "US",
    alpha_3_code: "USA",
    en_short_name: "United States of America",
    value: "American",
  },
  {
    num_code: "858",
    key: "UY",
    alpha_3_code: "URY",
    en_short_name: "Uruguay",
    value: "Uruguayan",
  },
  {
    num_code: "860",
    key: "UZ",
    alpha_3_code: "UZB",
    en_short_name: "Uzbekistan",
    value: "Uzbekistani, Uzbek",
  },
  {
    num_code: "548",
    key: "VU",
    alpha_3_code: "VUT",
    en_short_name: "Vanuatu",
    value: "Ni-Vanuatu, Vanuatuan",
  },
  {
    num_code: "862",
    key: "VE",
    alpha_3_code: "VEN",
    en_short_name: "Venezuela (Bolivarian Republic of)",
    value: "Venezuelan",
  },
  {
    num_code: "704",
    key: "VN",
    alpha_3_code: "VNM",
    en_short_name: "Vietnam",
    value: "Vietnamese",
  },
  {
    num_code: "92",
    key: "VG",
    alpha_3_code: "VGB",
    en_short_name: "Virgin Islands (British)",
    value: "British Virgin Island",
  },
  {
    num_code: "850",
    key: "VI",
    alpha_3_code: "VIR",
    en_short_name: "Virgin Islands (U.S.)",
    value: "U.S. Virgin Island",
  },
  {
    num_code: "876",
    key: "WF",
    alpha_3_code: "WLF",
    en_short_name: "Wallis and Futuna",
    value: "Wallis and Futuna, Wallisian or Futunan",
  },
  {
    num_code: "732",
    key: "EH",
    alpha_3_code: "ESH",
    en_short_name: "Western Sahara",
    value: "Sahrawi, Sahrawian, Sahraouian",
  },
  {
    num_code: "887",
    key: "YE",
    alpha_3_code: "YEM",
    en_short_name: "Yemen",
    value: "Yemeni",
  },
  {
    num_code: "894",
    key: "ZM",
    alpha_3_code: "ZMB",
    en_short_name: "Zambia",
    value: "Zambian",
  },
  {
    num_code: "716",
    key: "ZW",
    alpha_3_code: "ZWE",
    en_short_name: "Zimbabwe",
    value: "Zimbabwean",
  },
];
