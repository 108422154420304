import { ProductModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from "../../components/Loader/Loader";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";
import { getValueByKeyForProductType } from "../../utils/getValueByKey";

interface ContactProps {
  clientLocationId: string;
}

const columns = [
  {
    label: "Assigné",
    name: "actions",
  },
  {
    label: "Référence",
    name: "reference",
  },
  {
    label: "Désignation",
    name: "description",
  },
  {
    label: "Type",
    name: "type",
  },
];

const typeOption = [
  { key: "PRODUCT", value: "Produit" },
  { key: "CONSUMABLE", value: "Consommable" },
  { key: "MATERIAL", value: "Matériel" },
  { key: "GARBAGESBAG", value: "Sac poubelle" },
];

export const ClientLocationProduct = observer(({ clientLocationId }: ContactProps) => {
  const [productTableSelected, setProductTableSelected] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    clientLocations: { clientLocation, fetchEntity },
    products: { fetchProducts },
  } = useStore();
  const { products: productsStore } = useStore();

  const {
    entities: clients,
    setSearch,
    ordering,
    setOrdering,
  } = useDataGrid({
    findFunction: productsStore.findProducts,
    searchBuilder: (search) => ({
      $and: [
        {
          $or: [
            search && search != ""
              ? {
                type: {
                  $eq: search,
                },
              }
              : {},
          ],
        },
      ],
    }),
    limit: 0,
  });
  const { clientLocationProducts: clientLocationProductsApi } = useApi();

  useEffect(() => {
    (async () => {
      if (clientLocationId) {
        await fetchEntity(clientLocationId);
        await fetchProducts();
      }
    })();
  }, [clientLocationId]);

  const getProduct = async () => {
    const productClientLocation: any[] = [];
    await fetchEntity(clientLocationId);
    if (clientLocation && clientLocation.products) {
      clientLocation.products.map((product: any) => {
        const productLocation = { value: product.product.id, label: product.product.description };
        productClientLocation.push(productLocation);
      });
      setProductTableSelected(productClientLocation);
      setIsLoading(true);
    }
  };
  const handleChangeSelected = async (selected: any) => {
    const select = productTableSelected.some(function (arrVal) {
      return selected.id === arrVal.value;
    });

    if (select) {
      const clientLocationProductClient = await clientLocationProductsApi.fetchClientLocationProduct(
        `s={"$and": [{"clientLocationId": "${clientLocationId}"}, {"productId": "${selected.id}"}]}`
      );
      await clientLocationProductsApi.deleteClientLocationProduct(clientLocationProductClient[0].id);
    } else {
      const data = {
        clientLocationId: clientLocationId,
        productId: selected.id,
      };
      if (clientLocation) {
        await clientLocationProductsApi.createClientLocationProduct(data);
        fetchEntity(clientLocationId);
      }
    }
    getProduct();
  };

  function checkAvailability(val: any) {
    return productTableSelected.some(function (arrVal) {
      return val.id === arrVal.value;
    });
  }

  return (
    <div className="flex flex-col w-full px-1 mx-auto mb-auto sm:px-6 lg:px-8 max-w-7xl">
      <div className="bg-base-100">
        <div className=" card-body">
          <div tabIndex={0} className="border collapse collapse-arrow border-base-300 bg-base-100 rounded-box">
            <input type="checkbox" onClick={() => getProduct()} />
            <div className="justify-center text-xl font-medium collapse-title">Les produits du site</div>
            <div className="overflow-auto h-60 collapse-content">
              {isLoading && clients ? (
                <>
                  <div className="flex">
                    <label className="p-3 basis-1/4">Filtrer par :</label>
                    <select
                      className="w-full mt-2 mb-2 basis-3/4 select select-sm select-bordered"
                      onChange={(e) => setSearch(e.target.value)}
                    >
                      <option selected value={""}>
                        Type
                      </option>
                      {typeOption &&
                        typeOption.map((type) => {
                          return (
                            <option key={type.key} value={type.key}>
                              {type.value}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <DataGridTable dataProvider={clients} columns={columns} ordering={ordering} onOrderingChange={setOrdering}>
                    {(data) =>
                      data.data.map((product: ProductModelType) => {
                        return (
                          <tr key={product.id} className="cursor-pointer" onClick={() => handleChangeSelected(product)}>
                            <th className="text-sm whitespace-nowrap">
                              <input type="checkbox" className="checkbox" checked={checkAvailability(product)} />
                            </th>
                            <th className="text-sm font-medium whitespace-nowrap">{product.reference || "N/A"}</th>
                            <th className="text-sm font-medium whitespace-nowrap">{product.description || "N/A"}</th>
                            <th className="text-sm whitespace-nowrap">
                              <div>{getValueByKeyForProductType(product.type) || product.type}</div>
                            </th>
                          </tr>
                        );
                      })
                    }
                  </DataGridTable>
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
