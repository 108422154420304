/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { PaginatedResponse } from "src/modules/api/paginated-response";

export function createContactApi(axios: AxiosInstance) {
  return {
    async fetchContacts(params?: any): Promise<PaginatedResponse<any>> {
      return axios({
        url: "/contacts",
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async fetchOneContact(entityId: string) {
      return axios({
        url: `/contacts/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async createContact(data: Partial<any>){
      return axios({
        url: "/contacts",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async updateContact(entityId: string, data: Partial<any>) {
      return axios({
        url: `/contacts/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async deleteContact(entityId: string) {
      return axios({
        url: `/contacts/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
  };
}

export type ContactApi = ReturnType<typeof createContactApi>;
