import { faDownload, faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OperationStatusEnum, OperationStatusEnumTranslations, OperationTypeEnum, OperationTypeEnumTranslations } from '@platinium/cdk';
import clsx from 'clsx';
import React, { ReactElement, useState } from "react";
import DatePicker from 'react-datepicker';
import { UseFormReturn } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { useApi } from '../../hooks/useApi';

interface OperationFormProps {
  form: UseFormReturn;
}

interface FormInput {
  name: string;
  type: string;
  placeholder: string;
  label: string;
  render?: (form: UseFormReturn, input: FormInput, data: any) => ReactElement;
  requiredIf?: (data: any) => boolean;
}

function createTimeOptions() {
  const options = [];
  const maxHours = 12;

  for (let hours = 0; hours <= maxHours; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const value = hours * 60 + minutes;
      const label = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      options.push({ value, label });
    }
  }

  return options.splice(0, options.length - 1);
}

const timeOptions = createTimeOptions();

export const operationTypeOptions = Object.keys(OperationTypeEnum).map((operationType: any) => ({
  label: (OperationTypeEnumTranslations as any)[operationType] || operationType,
  value: operationType
}));

export const operationStatusOptions = Object.keys(OperationStatusEnum).map((operationType: any) => ({
  label: (OperationStatusEnumTranslations as any)[operationType] || operationType,
  value: operationType
}));


const inputs: FormInput[] = [
  {
    name: "status",
    type: "text",
    placeholder: "Statut",
    label: "Statut",
    requiredIf: () => true,
    render: (form, input, data) => <Select
      closeMenuOnSelect={true}
      placeholder="Sélectionnez un statut"
      // onCreateOption={(option) => operationTypeOptions.push({ label: option, value: option })}
      value={data[input.name] && (operationStatusOptions.find(operationStatus => operationStatus.value === data[input.name]) || { value: data[input.name], label: data[input.name] })}
      onChange={(e) => form.setValue(input.name, e.value)}
      // hideSelectedOptions={true}
      options={operationStatusOptions}
    />
  },
  {
    name: "type",
    type: "text",
    placeholder: "Type",
    label: "Type",
    requiredIf: () => true,
    render: (form, input, data) => <CreatableSelect
      closeMenuOnSelect={true}
      placeholder="Sélectionnez un type"
      // onCreateOption={(option) => operationTypeOptions.push({ label: option, value: option })}
      value={data[input.name] && (operationTypeOptions.find(operationType => operationType.value === data[input.name]) || { value: data[input.name], label: data[input.name] })}
      onChange={(e) => form.setValue(input.name, e.value)}
      hideSelectedOptions={true}
      options={operationTypeOptions}
    />
  },
  {
    name: "clientName",
    type: "text",
    placeholder: "Topologie",
    label: "Nom du client",
    requiredIf: () => true,
  },
  {
    name: "clientContactName",
    type: "text",
    placeholder: "John Doe",
    label: "Nom du contact",
    requiredIf: () => true,
  },
  {
    name: "adressLine1",
    type: "text",
    placeholder: "1 rue de la Liberté",
    label: "Adresse Ligne 1",
    requiredIf: () => false,
  },
  {
    name: "adressLine2",
    type: "text",
    placeholder: "Batiment B, 1er étage à droite...",
    label: "Adresse Ligne 2",
    requiredIf: () => false,
  },
  {
    name: "postalCode",
    type: "text",
    placeholder: "Code postal",
    label: "Code postal",
    requiredIf: () => true,
  },
  {
    name: "city",
    type: "text",
    placeholder: "Ville",
    label: "Ville",
    requiredIf: () => true,
  },
  {
    name: "accessInformations",
    type: "text",
    placeholder: "Informations d'accès",
    label: "Informations d'accès",
    requiredIf: () => false,
    render: (form, input, data) => <textarea
      {...form.register(input.name)}
      placeholder={input.placeholder}
      className="w-full textarea textarea-bordered"
      required={input.requiredIf && input.requiredIf(data)}
    />
  },
  {
    name: "requestedOperationDate",
    type: "date",
    placeholder: "...",
    label: "Date demandé par le client",
    requiredIf: () => false,
    render: (form, input, data) => <DatePicker 
      locale="fr-FR"
      showTimeSelect
      selected={data[input.name] ? new Date(data[input.name]) : null}
      onChange={(date) => form.setValue(input.name, date && date.toISOString())}
      dateFormat={"dd/MM/yyyy HH:mm"}
      isClearable
      placeholderText='Sélectionnez une date'
    />
  },
  {
    name: "requestInformations",
    type: "text",
    placeholder: "...",
    label: "Cahier des charges",
    requiredIf: () => false,
    render: (form, input, data) => <textarea
      {...form.register(input.name)}
      placeholder={input.placeholder}
      value={data[input.name]}
      className="w-full textarea textarea-bordered"
    />
  },
  {
    name: "requiredEquipments",
    type: "text",
    placeholder: "...",
    label: "Matériel à prévoir",
    requiredIf: () => false,
    render: (form, input, data) => <textarea
      {...form.register(input.name)}
      placeholder={input.placeholder}
      value={data[input.name]}
      className="w-full textarea textarea-bordered"
    />
  },
  {
    name: "agentsCount",
    type: "number",
    placeholder: "Nombre d'agents",
    label: "Nombre d'agents",
    requiredIf: () => true,
    render(form, input, data) {
      return <input
        {...form.register(input.name)}
        type={input.type}
        min={0}
        placeholder={input.placeholder}
        required={input.requiredIf && input.requiredIf(data)}
        className="w-full input input-bordered"
      />;
    },
  },
  {
    name: "isRequestingManager",
    type: "checkbox",
    placeholder: "",
    label: "Chef d'équipe requis",
    requiredIf: () => true,
    render: (form, input, data) => <input
      {...form.register(input.name)}
      type={input.type}
      placeholder={input.placeholder}
      className="toggle"
    />
  },
  {
    name: "plannedOperationDate",
    type: "date",
    placeholder: "",
    label: "Planifiée le",
    requiredIf: () => false,
    render: (form, input, data) => <DatePicker 
      locale="fr-FR"
      showTimeSelect
      selected={data[input.name] ? new Date(data[input.name]) : null}
      onChange={(date) => form.setValue(input.name, date && date.toISOString())}
      dateFormat={"dd/MM/yyyy HH:mm"}
      isClearable
      placeholderText='Sélectionnez une date'
    />
  },
  {
    name: "isBillable",
    type: "checkbox",
    placeholder: "",
    label: "Facturable",
    requiredIf: () => true,
    render: (form, input, data) => <input
      {...form.register(input.name)}
      type={input.type}
      placeholder={input.placeholder}
      className="toggle"
    />
  },
  {
    name: "operatingTime",
    type: "number",
    placeholder: "",
    label: "Temps total",
    requiredIf: () => false,
    render: (form, input, data) => <Select
      closeMenuOnSelect={true}
      placeholder="Sélectionner une heure"
      value={data[input.name] && timeOptions.find(operationType => operationType.value === data[input.name])}
      onChange={(e) => form.setValue(input.name, e.value)}
      hideSelectedOptions={true}
      options={timeOptions}
    />
  },
  {
    name: "completedOperationDate",
    type: "date",
    placeholder: "",
    label: "Effectuée le",
    requiredIf: () => false,
    render: (form, input, data) => <DatePicker 
      locale="fr-FR"
      showTimeSelect
      selected={data[input.name] ? new Date(data[input.name]) : null}
      onChange={(date) => form.setValue(input.name, date && date.toISOString())}
      dateFormat={"dd/MM/yyyy HH:mm"}
      isClearable
      placeholderText='Sélectionnez une date'
    />
  },
  {
    name: "publicResult",
    type: "textarea",
    label: "Compte-rendu",
    placeholder: "...",
    requiredIf: () => false,
    render: (form, input, data) => <textarea
      {...form.register(input.name)}
      placeholder={input.placeholder}
      className="w-full textarea textarea-bordered"
      required={input.requiredIf && input.requiredIf(data)}
    />
  },
  {
    name: "privateResult",
    type: "textarea",
    placeholder: "...",
    label: "Notes (visible uniquement en interne)",
    requiredIf: () => false,
    render: (form, input, data) => <textarea
      {...form.register(input.name)}
      placeholder={input.placeholder}
      className="w-full textarea textarea-bordered"
      required={input.requiredIf && input.requiredIf(data)}
    />
  },
];

export const OperationForm = (props: OperationFormProps) => {
  const { operations: operationsApi } = useApi();
  const { form } = props;
  const data = form.watch();
  const [isError, setIsError] = useState(false);

  const setError = (err: any) => {
    setIsError(err);
  };

  const [generatingReport, setGeneratingReport] = useState(false);

  return (
    <div className="px-6 mt-3">
      {isError && <div className="text-error">Veuillez renseigner tous les champs</div>}

      {inputs.map((input) => (
        <div key={input.name} className="w-full">
          <label htmlFor={input.name} className="label">
            <span className="text-base font-bold label-text">{input.label}</span>
          </label>
          {input.render
            ? input.render(form, input, data)
            : <input
              {...form.register(input.name)}
              type={input.type}
              placeholder={input.placeholder}
              required={input.requiredIf && input.requiredIf(data)}
              className="w-full input input-bordered"
            />}
        </div>
      ))}

      {data.id && <>
        <div className="flex items-center my-6">
          <p className="w-4/12 pr-3 font-bold">Rapport Interne</p>
          <button
            onClick={async () => {
              setGeneratingReport(true);
              try {
                const operationReport = await operationsApi.generatePrivateOperationReport(data.id);
                const link = document.createElement("a");
                link.href = operationReport.signedUrl;
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
              } catch (error) {
                console.error(error);
              } finally {
                setGeneratingReport(false);
              }
            }}
            disabled={generatingReport}
            type="button"
            title={`Télécharger le rapport interne`}
            className="hover:text-blue-500"
          >
            <FontAwesomeIcon className={clsx({"animate-spin": !!generatingReport})} icon={generatingReport ? faLoader : faDownload} />
          </button>

        </div>
        <div className="flex items-center my-6">
          <p className="w-4/12 pr-3 font-bold">Rapport Externe</p>
          <button
            onClick={async () => {
              setGeneratingReport(true);
              try {
                const operationReport = await operationsApi.generatePublicOperationReport(data.id);
                const link = document.createElement("a");
                link.href = operationReport.signedUrl;
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
              } catch (error) {
                console.error(error);
              } finally {
                setGeneratingReport(false);
              }
            }}
            disabled={generatingReport}
            type="button"
            title={`Télécharger le rapport externe`}
            className="hover:text-blue-500"
          >
            <FontAwesomeIcon className={clsx({"animate-spin": !!generatingReport})} icon={generatingReport ? faLoader : faDownload} />
          </button>

        </div>
      </>}
    </div>
  );
};
