import { UserRoleEnum } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

import { DateTimeType } from "./date-time.model";

export const UserModel = types.model("UserModel", {
  id: types.identifier,
  role: types.enumeration(Object.values(UserRoleEnum)),
  avatarPath: types.maybeNull(types.string),
  email: types.string,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  civility: types.maybeNull(types.string),
  nationality: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  resetPasswordToken: types.maybeNull(types.string),
  createdAt: DateTimeType,
  updatedAt: DateTimeType,
  dateOfBirth: types.maybeNull(DateTimeType),
});

export type UserModelType = Instance<typeof UserModel>;
