/* eslint-disable @typescript-eslint/no-explicit-any */
import { SCondition } from "@nestjsx/crud-request";
import { PaginatedModels } from "@platinium/cdk";
import { map } from "lodash";
import { IAnyType } from "mobx-state-tree";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async";
import { useDebounce } from "use-debounce";

export function useDataGrid<ENTITY extends IAnyType>(props: {
  findFunction: (params: unknown) => Promise<PaginatedModels<ENTITY>>;
  limit?: number;
  searchBuilder: (search: string) => SCondition;
  initialOrdering?: Record<string, "ASC" | "DESC">;
}) {
  const { findFunction, searchBuilder, limit = 10, initialOrdering } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(limit);
  const [search, setSearch] = useState<string>("");
  const [ordering, setOrdering] = useState<Record<string, "ASC" | "DESC">>(initialOrdering || {});

  const [findEntities, { flush }] = useDebounce(

    useCallback(async () => {
      return findFunction({
        offset: (currentPage - 1) * itemsPerPage,
        limit,
        s: JSON.stringify(searchBuilder(search)),
        sort: map(ordering, (order, field) => (`${field},${order}`)),
      });
    }, [search, searchBuilder, findFunction, limit, itemsPerPage, ordering, currentPage]),
    200
  );

  useEffect(() => {
    flush();
  }, [currentPage, itemsPerPage, ordering, flush]);

  const entities = useAsync({
    promiseFn: findEntities,
  });

  const pages = useMemo(() => {
    return entities.data ? entities.data.pageCount : 0;
  }, [entities, itemsPerPage]);

  return {
    search,
    setSearch,
    entities,
    pages,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    ordering,
    setOrdering,
  };
}
