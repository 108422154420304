import { AgentRoleEnum, ClientLocationAgentObject, ProblemObject } from "@platinium/shared";
import { Instance, types } from "mobx-state-tree";

import { DateTimeType } from "src/modules/models/date-time.model";
import { DocumentModel } from 'src/modules/models/document.model';
import { MeetingModelType } from 'src/modules/models/meeting.model';
import { UserModel } from "src/modules/models/user.model";

export const AgentModel = types.model("AgentModel", {
  id: types.identifier,
  user: types.frozen(UserModel),
  createdAt: DateTimeType,
  updatedAt: DateTimeType,
  document: types.maybe(types.maybeNull(DocumentModel)),
  role: types.enumeration(Object.values(AgentRoleEnum)),
  isMultiEmployee: types.boolean,
  expirationDateResidencePermit: types.maybeNull(DateTimeType),
  phoneNumberPro: types.maybeNull(types.string),
  meetings: types.array(types.frozen<MeetingModelType>()),
  problems: types.array(types.frozen<ProblemObject>()),
  clientLocationAgents: types.array(types.frozen<ClientLocationAgentObject>()),
  addressLine1: types.maybeNull(types.string),
  addressLine2: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
});

export type AgentModelType = Instance<typeof AgentModel>;

