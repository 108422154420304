/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgentObject } from "@platinium/shared";
import { AxiosInstance } from "axios";

export function createClientLocationProductApi(axios: AxiosInstance) {
  return {
    async createClientLocationProduct(data: any): Promise<AgentObject> {
      return axios({
        url: "/clientLocationProducts",
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async deleteClientLocationProduct(entityId: any): Promise<AgentObject> {
      return axios({
        url: `/clientLocationProducts/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async fetchClientLocationProduct(params?: any) {
      return axios({
        url: `/clientLocationProducts`,
        method: "GET",
        params
      }).then((response) => response.data);
    },
  };
}

export type ClientLocationProductApi = ReturnType<typeof createClientLocationProductApi>;
