/* eslint-disable @typescript-eslint/no-explicit-any */
import { flow, getEnv, types } from "mobx-state-tree";

import { ClientLocationProductModel } from 'src/modules/models/clientLocationProduct.model';

import { RootStoreEnv } from "./root.store";

export const ClientLocationProductsStore = types
  .model("ClientLocationProduct", {
    clientLocationProducts: types.maybeNull(types.array(ClientLocationProductModel)),

  })
  .actions((self) => ({
    fetchClientLocationProducts: flow(function* (params?: string) {
      const {
        api: { clientLocationProducts },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientLocationProducts.fetchClientLocationProduct(params);
      self.clientLocationProducts = result;
      return self.clientLocationProducts;
    }),
  }))
  .views(() => ({}));

export const clientLocationProductsStore = ClientLocationProductsStore.create({
  clientLocationProducts: [],
});
