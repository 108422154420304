import { Instance, types } from "mobx-state-tree";

import { AgentModel } from "src/modules/models/agent.model";
import { ClientLocationModel } from "src/modules/models/clientLocation.model";
import { DateTimeType } from "src/modules/models/date-time.model";

export const ClientLocationCheckinModel = types.model("ClientLocationCheckinModel", {
  id: types.identifier,
  clientLocationId: types.string,
  clientLocation: ClientLocationModel,
  agentId: types.string,
  agent: AgentModel,
  startTime: DateTimeType,
  endTime: types.maybeNull(DateTimeType),
  createdAt: DateTimeType,
  updatedAt: DateTimeType,
});

export type ClientLocationCheckinModelType = Instance<typeof ClientLocationCheckinModel>;
