import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useStore } from "../context/store.context";

export const ProtectedRoute = () => {
  const {
    admins: { isLoggedIn },
  } = useStore();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
