import { faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientModelType } from "@platinium/cdk";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from "../../components/Loader/Loader";
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";
import { displayContracts } from "./client.utils";

const columns = [
  {
    label: "Nom du client",
    name: "name",
  },
  {
    label: "Contrat",
    name: "isClientPlatinium",
  },
  {
    label: "Sites",
    name: "clientLocations.name",
  },
  {
    label: "",
    name: "actions",
  },
];
const sortableColumns = ["name"];

export const Clients = observer(() => {
  const { clients: clientsStore } = useStore();
  const { clients: clientsApi } = useApi();

  const {
    clients: { clearClient },
  } = useStore();
  const navigate = useNavigate();
  const {
    search,
    setSearch,
    entities: clients,
    pages,
    currentPage,
    setCurrentPage,
    ordering,
    setOrdering,
  } = useDataGrid({
    findFunction: clientsStore.findClients,
    searchBuilder: (search) => ({
      $or: [
        {
          name: {
            $contL: search,
          },
        },
      ],
    }),
  });

  useEffect(() => {
    clearClient();
  }, []);

  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onDelete = useCallback(
    async (data: ClientModelType) => {
      await clientsApi.deleteClient(data.id);
      clients.reload();
    },
    [clients, clientsApi]
  );

  useEffect(() => {
    if (Object.keys(ordering).includes("clientLocations.name")) {
      delete ordering["clientLocations.name"];
    }
  }, [ordering]);
  return (
    <>
      {clients.data ? (
        <div className="flex-1">
          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer un client"
            description={(data) => `Êtes vous sur de vouloir supprimer le client ${data?.name} ?`}
          />
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader
                searchPlaceholder="Chercher un client par nom"
                search={search}
                onSearchChange={setSearch}
                actions={
                  <button onClick={() => navigate("/client")} type="button" className="btn btn-sm">
                    Nouveau client
                  </button>
                }
              />
              <div className="overflow-x-auto">
                <DataGridTable
                  dataProvider={clients}
                  columns={columns}
                  ordering={ordering}
                  onOrderingChange={setOrdering}
                  sortableColumns={sortableColumns}
                >
                  {(data) =>
                    data.data.map((client: ClientModelType) => {
                      return (
                        <tr
                          key={client.id}
                          className="cursor-pointer hover"
                          onClick={() => {
                            navigate("/client/" + client.id);
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">{client.name || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">{displayContracts(client.isClientPlatinium, client.isClientSunset)}</th>
                          <th className="text-sm truncate whitespace-normal">
                            {client.clientLocations?.length > 0
                              ? client.clientLocations.map((clientLocation: any, index: number) => {
                                  return <span key={clientLocation.id}>{(index ? ", " : "") + clientLocation.name}</span>;
                                })
                              : "N/A"}
                          </th>
                          <th className="space-x-2 text-sm font-medium text-right">
                            <button
                              onClick={() => {
                                navigate("/client/" + client.id);
                              }}
                              type="button"
                              title="Edit"
                              className="hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>

                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteRef.current && deleteRef.current.open(client);
                              }}
                              type="button"
                              title="Delete"
                              className="text-error hover:text-error-active"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {clients.data && clients.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{clients.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
