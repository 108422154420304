export enum OperationTypeEnumTranslations {
  WINDOWS = "Vitres",
  CLEANING = "Ménage",
  GARBAGE = "Encombrants",
  SMALL_WORKS = "Petits travaux",
  MOQUETTE = "Moquette",
  KARCHER = "Karcher",
  SUNSET = "Sunset",
  OTHER = "Autre"
}
