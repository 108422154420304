import { ApiProperty } from "@nestjs/swagger/dist/decorators/api-property.decorator";
import { Exclude, Expose } from "class-transformer";
import { IsNotEmpty, IsString, MinLength } from "class-validator";

import { UserRoleEnum } from "../users/enums/user-role.enum";

export class CreateAgentRequest {
  @Expose()
  @ApiProperty({
    description: "User's email address",
    example: "john.doe@gmail.com",
  })
  @IsString()
  @IsNotEmpty()
  email: string;

  @Expose()
  @ApiProperty({ description: "User's first name", example: "John" })
  @IsString()
  @IsNotEmpty()
  firstName: string;

  @Expose()
  @ApiProperty({ description: "User's last name", example: "Doe" })
  @IsString()
  @IsNotEmpty()
  lastName: string;

  @Expose()
  @ApiProperty({ description: "User's nationality", example: "French" })
  @IsString()
  @IsNotEmpty()
  nationality: string;

  @Expose()
  @Exclude({ toPlainOnly: true })
  @ApiProperty({
    description: "User's encoded password",
    example: "e33@1adl;j*",
  })
  @IsString()
  @MinLength(6)
  password: string;

  @Expose()
  @ApiProperty({
    description: "User's phone number",
    example: "+33.1.23.45.67.89",
  })
  @IsString()
  @IsNotEmpty()
  phoneNumberPro: string;

  @Expose()
  @ApiProperty({
    description: "User's phone number",
    example: "+33.1.23.45.67.89",
  })
  @IsString()
  @IsNotEmpty()
  phoneNumber: string;

  @Expose()
  @ApiProperty({
    enum: UserRoleEnum,
    description: "User's role",
    example: "agent",
  })
  @IsString()
  role: UserRoleEnum;
}
