/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, getRoot, IAnyType, IMaybeNull, Instance, IReferenceType, types } from "mobx-state-tree";

import { PaginatedResponse } from "src/modules/api";
import { PaginatedModels } from "src/modules/models";

export const BaseEntity = types.model({
  id: types.identifier,
});

export const createEntityStore = <T extends typeof BaseEntity>(entityName: string, model: T) => {
  type Model = Instance<T>;

  return types
    .model(`${entityName}Store`, {
      cache: types.optional(types.map(model), () => cast({})),
    })
    .actions((self) => ({
      cacheEntity(entity: Model, force?: boolean) {
        const cachedEntity = self.cache.get(entity.id.toString());
        if (cachedEntity && !force) {
          return cachedEntity as Model;
        }
        self.cache.set(entity.id.toString(), entity);
        return entity;
      },
      fetchEntity: async (entityId: number): Promise<Model> => {
        throw new Error("Not implemented entity :" + entityId);
      },
    }))
    .actions((self) => {
      const inFlightEntities = new Map<string, Promise<Model>>();

      return {
        loadEntityById: flow(function* (entityId: number) {
          const formattedEntityId = entityId.toString();

          if (self.cache.has(formattedEntityId)) {
            return self.cache.get(formattedEntityId) as Model;
          }

          if (inFlightEntities.has(formattedEntityId)) {
            return inFlightEntities.get(formattedEntityId);
          }

          const entityPromise = self.fetchEntity(entityId);

          inFlightEntities.set(formattedEntityId, entityPromise);

          const resolvedEntity = yield entityPromise;

          const addedUser = self.cacheEntity(resolvedEntity);

          inFlightEntities.delete(formattedEntityId);

          return addedUser;
        }),
      };
    })
    .views((self) => {
      return {
        getEntity(userId: number): Model | undefined {
          self.loadEntityById(userId);
          return self.cache.get(userId.toString()) as Model | undefined;
        },
        getEntityAsync(userId: number): Promise<Model> {
          if (self.cache.has(userId.toString())) {
            return Promise.resolve(self.cache.get(userId.toString()) as Model);
          }
          return self.loadEntityById(userId) as Promise<Model>;
        },
      };
    });
};

export const createEntityReference = <T extends typeof BaseEntity>(storeIdentifier: string, model: T) => {
  return types.maybeNull(
    types.reference(model, {
      get(identifier, parent: any) {
        if (!identifier && !parent) {
          return;
        }
        const rootStore = (getEnv(parent) && getEnv(parent).rootStore) || getRoot<any>(parent);
        if (!rootStore || !rootStore[storeIdentifier] || !rootStore[storeIdentifier].getEntity) {
          return;
        }
        return rootStore[storeIdentifier].getEntity(identifier);
      },
      set(value) {
        return value ? value.id : (null as any);
      },
    })
  );
};

export const createEntityQueryStore = <T extends typeof BaseEntity>(
  model: T,
  modelReference: IMaybeNull<IReferenceType<T>>,
  findEntities: (self: Instance<IAnyType>, params?: any) => Promise<PaginatedResponse<T>>
) => {
  return types
    .model({
      entities: types.maybe(PaginatedModels(modelReference)),
      isLoading: types.optional(types.boolean, () => false),
    })
    .actions((self) => ({
      findEntities: flow(function* (params?: any) {
        self.isLoading = true;

        try {
          const result: PaginatedResponse<Instance<T>> = yield findEntities(self, params);

          const tasks = result.data.map((data) => {
            return data.id;
          });

          self.entities = cast({
            ...result,
            data: cast(tasks as any),
          });

          return self.entities as PaginatedModels<T>;
        } finally {
          self.isLoading = false;
        }
      }),
    }));
};
