import { observer } from "mobx-react-lite";
import React from "react";

import { RegularInstruction } from "../../components/Instruction/RegularInstruction";
import { UrgentInstruction } from "../../components/Instruction/UrgentInstruction";

export const Instruction = observer(() => {
  return (
    <div className="flex-1">
      <RegularInstruction />
      <UrgentInstruction />
    </div>
  );
});
