import React from "react";
import { Outlet } from 'react-router-dom';

import { Navbar } from "../../components/Navbar/Navbar";
import { Footer } from '../Footer/Footer';

export const Layout = () => {
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <Outlet/>
      <Footer />
    </div>
  );
};
