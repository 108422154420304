/* eslint-disable @typescript-eslint/no-explicit-any */
import { cast, flow, getEnv, types } from "mobx-state-tree";

import { PaginatedModels } from "src/modules/models";
import { ClientLocationContactModel } from "src/modules/models/clientLocationContact.model";
import { createEntityReference, createEntityStore } from "src/modules/stores/entity.store";

import { RootStoreEnv } from "./root.store";
export const ClientLocationContactReference = createEntityReference("clientLocationContacts", ClientLocationContactModel);

export const ClientLocationContactsStore = createEntityStore("ClientLocationContact", ClientLocationContactModel)
  .props({
    clientLocationContacts: types.maybe(PaginatedModels(ClientLocationContactReference)),
    clientLocationContact: types.maybeNull(ClientLocationContactModel),
  })
  .actions((self) => ({
    fetchClientLocationContacts: flow(function* (params?: string) {
      const {
        api: { clientLocationContacts },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientLocationContacts.fetchClientLocationContacts(params);
      self.clientLocationContacts = result;
      return self.clientLocationContacts;
    }),
    fetchOneClientLocationContact: flow(function* (params?: string) {
      const {
        api: { clientLocationContacts },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientLocationContacts.fetchClientLocationContacts(params);
      self.clientLocationContact = result;
      return self.clientLocationContact;
    }),
    findClientLocationContacts: flow(function* (findContactData?: any) {
      const {
        api: { clientLocationContacts: clientLocationContactsApi },
      } = getEnv<RootStoreEnv>(self);

      const result = yield clientLocationContactsApi.fetchClientLocationContacts(findContactData);

      const contacts = result.data.map((clientLocationContact: any) => {
        const entityModel = self.cacheEntity(
          ClientLocationContactModel.create({
            rawAdmin: clientLocationContact,
            ...(clientLocationContact as any),
          }),
          true
        );
        return entityModel.id;
      });
      self.clientLocationContacts = cast({
        ...result,
        data: cast(contacts as any),
      });

      return self.clientLocationContacts as PaginatedModels<typeof ClientLocationContactModel>;
    }),
  }))
  .actions((self) => ({
    fetchEntity: flow(function* (entityId: string) {
      const {
        api: { clientLocationContacts },
      } = getEnv<RootStoreEnv>(self);

      const clientLocationContact = yield clientLocationContacts.fetchOneClientLocationContact(entityId);

      return ClientLocationContactModel.create({
        rawAdmin: clientLocationContact,
        ...clientLocationContact,
      });
    }),
  }))
  .views(() => ({}));

export const clientLocationContactsStore = ClientLocationContactsStore.create({});
