import { IAnyType, Instance, types } from 'mobx-state-tree';

export const PaginatedModels = <T extends IAnyType>(model: T) => {
  return types.model(`PaginatedModels(${model.name})`, {
    data: types.array(model),
    count: types.number,
    total: types.number,
    page: types.number,
    pageCount: types.number,
  });
};

// Hack to add the possibility of extracting the generic type from the function without building it manually.
class PaginatedModelsWrapper<ENTITY extends IAnyType> {
  wrapped(entity: ENTITY) {
    return PaginatedModels(entity);
  }
}

//  Represent the TS type for PaginatedModels of an Entity
export type PaginatedModels<ENTITY extends IAnyType> = Instance<ReturnType<PaginatedModelsWrapper<ENTITY>['wrapped']>>;
