import { observer } from "mobx-react-lite";
import React from "react";

interface clientLocationProps {
  clientLocation: any;
  title?: string;
}

export const Address = observer(({ clientLocation, title }: clientLocationProps) => {
  return (
    <div className="flex flex-col w-full">
      <div>
        <div className="card bg-base-100">
          <div className="card-body">
            <div className="flex justify-center w-full ">
              <div className="justify-center w-full bg-white rounded-lg mt-18">
                {title && (
                  <div className="p-4">
                    <h2 className="text-2xl text-center">{title}</h2>
                  </div>
                )}
                <div className="w-full">
                  <div className="p-4 space-y-1 border-t border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                    <p className="text-gray-600">Adresse</p>
                    <div>
                      <p className="font-semibold text-left">
                        {clientLocation?.adressLine1 && clientLocation.adressLine1 != " " ? clientLocation.adressLine1 : "N/A"}
                      </p>
                      <p className="font-semibold text-left">{clientLocation?.adressLine2 != null ? clientLocation.adressLine2 : ""}</p>
                      <p className="font-semibold text-left">
                        {clientLocation?.postalCode != null ? clientLocation.postalCode : ""}{" "}
                        {clientLocation?.city != null ? clientLocation.city : ""}
                      </p>
                    </div>
                  </div>
                  {clientLocation?.digitalCodes != null && (
                    <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                      <p className="text-gray-600">Code d&apos;accès</p>
                      <p className="font-semibold text-left ">{clientLocation.digitalCodes || "N/A"}</p>
                    </div>
                  )}
                  {clientLocation?.text != null && (
                    <div className="p-4 space-y-1 border-b md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0">
                      <p className="text-gray-600 ">Autre informations</p>
                      <p className="font-semibold text-left">{clientLocation.text}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
