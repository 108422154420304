import { Instance, types } from "mobx-state-tree";

import { DocumentModelType } from "src/modules/models/document.model";

export const TrainingModel = types.model("TrainingModel", {
  id: types.identifier,
  title: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  documents: types.maybeNull(types.array(types.frozen<DocumentModelType>())),
});

export type TrainingModelType = Instance<typeof TrainingModel>;
