/* eslint-disable @typescript-eslint/no-explicit-any */
import { faDownload, faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductModelType, ProductObject } from "@platinium/cdk";
import { cloneDeep, pick } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ElementRef, useCallback, useRef } from "react";

import { DataGridDelete } from "../../components/DataGrid/DataGridDelete";
import { DataGridForm } from "../../components/DataGrid/DataGridForm";
import { DataGridHeader } from "../../components/DataGrid/DataGridHeader";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { Loader } from "../../components/Loader/Loader";
import { Pagination } from "../../components/Pagination";
import { useStore } from "../../context/store.context";
import { useApi } from "../../hooks/useApi";
import { useDataGrid } from "../../hooks/useDataGrid.hook";
import { getValueByKeyForProductType } from "../../utils/getValueByKey";
import { ProductForm } from "./ProductForm";

const columns = [
  {
    label: "Référence",
    name: "reference",
  },
  {
    label: "Nom",
    name: "description",
  },
  {
    label: "Type",
    name: "type",
  },
  {
    label: "",
    name: "actions",
  },
];

export const Products = observer(() => {
  const { products: productsStore } = useStore();
  const { products: productsApi } = useApi();
  const {
    search,
    setSearch,
    entities: products,
    pages,
    currentPage,
    setCurrentPage,
    ordering,
    setOrdering,
  } = useDataGrid({
    findFunction: productsStore.findProducts,
    searchBuilder: (search) => ({
      $or: [
        {
          description: {
            $contL: search,
          },
        },
        {
          reference: {
            $contL: search,
          },
        },
      ],
    }),
  });

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onSubmitForm = useCallback(
    async (data: ProductObject) => {
      if (!data.orderableBy) {
        data.orderableBy = [];
      }

      const payload = {
        ...pick(data, ["reference", "description", "orderableBy", "type"]),
        defaultQuantity: parseInt(data.defaultQuantity),
      };
      if (data.id) {
        await productsApi.updateProduct(data.id, payload);
      } else {
        await productsApi.createProduct(payload);
      }
      products.reload();
    },
    [products]
  );

  const onDelete = useCallback(
    async (data: ProductModelType) => {
      await productsApi.deleteProduct(data.id);
      products.reload();
    },
    [products, productsApi]
  );

  const cancel = (form: any) => {
    if (form.id) {
      productsApi.deleteProduct(form.id);
    }
  };

  return (
    <>
      {products.data ? (
        <div className="flex-1">
          <DataGridForm
            ref={formRef}
            title={(form) => {
              const data = form.getValues();
              return data.id ? `Modifier un produit` : "Créer un produit";
            }}
            onSubmit={onSubmitForm}
            cancel={cancel}
          >
            {(form) => <ProductForm form={form}></ProductForm>}
          </DataGridForm>

          <DataGridDelete
            ref={deleteRef}
            onDelete={onDelete}
            title="Supprimer un produit"
            description={() => "Êtes vous sur de vouloir supprimer ce produit"}
          />
          <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
              <DataGridHeader
                searchPlaceholder="Chercher un produit par son nom"
                search={search}
                onSearchChange={setSearch}
                actions={
                  <button onClick={() => formRef.current && formRef.current.open()} type="button" className="btn btn-sm">
                    Nouveau produit
                  </button>
                }
              />
              <div className="overflow-x-auto">
                <DataGridTable dataProvider={products} columns={columns} ordering={ordering} onOrderingChange={setOrdering}>
                  {(data) =>
                    data.data.map((product: ProductModelType) => {
                      return (
                        <tr
                          key={product.id}
                          className="cursor-pointer hover"
                          onClick={() => {
                            formRef.current && formRef.current.open(cloneDeep(product));
                          }}
                        >
                          <th className="text-sm font-medium whitespace-nowrap">{product.reference || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">{product.description || "N/A"}</th>
                          <th className="text-sm whitespace-nowrap">
                            <div>{getValueByKeyForProductType(product.type) || product.type}</div>
                          </th>
                          <th className="space-x-2 text-sm font-medium text-right ">
                            <button
                              onClick={() => {
                                formRef.current && formRef.current.open(cloneDeep(product));
                              }}
                              type="button"
                              title="Edit"
                              className="hover:text-blue-500"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            {product.document?.key ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (product.document?.key) {
                                    const link = document.createElement("a");
                                    link.href = product.document.key;
                                    link.setAttribute("target", "_blank");
                                    document.body.appendChild(link);
                                    link.click();
                                  }
                                }}
                                type="button"
                                title="Download"
                                className="hover:text-blue-500"
                              >
                                <FontAwesomeIcon icon={faDownload} />
                              </button>
                            ) : (
                              <button type="button" title="Download" className="text-transparent">
                                <FontAwesomeIcon icon={faDownload} />
                              </button>
                            )}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteRef.current && deleteRef.current.open(product);
                              }}
                              type="button"
                              title="Delete"
                              className="text-error hover:text-error-active"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </th>
                        </tr>
                      );
                    })
                  }
                </DataGridTable>
              </div>
              {products.data && products.data.data.length !== 0 && (
                <div className="flex items-center justify-between w-full mt-3 mb-12">
                  <span className="hidden text-sm sm:block ">
                    <b>{products.data.total}</b> résultats sur <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                  </span>
                  <div>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} maxPages={pages} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
