/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

export function createMeetingApi(axios: AxiosInstance) {
  return {
    async deleteMeeting(entityId: string) {
      return axios({
        url: `/meetings/${entityId}`,
        method: "DELETE",
        data: entityId,
      }).then((response) => response.data);
    },
    async createMeeting(data: { agentId: string }) {
      return axios({
        url: `/meetings`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async updateMeeting(entityId: string, data: Partial<any>) {
      return axios({
        url: `/meetings/${entityId}`,
        method: "PATCH",
        data,
      }).then((response) => response.data);
    },
    async fetchMeetings(entityId: string) {
      return axios({
        url: `/meetings/${entityId}`,
        method: "GET",
        data: entityId,
      }).then((response) => response.data);
    },
  };
}

export type MeetingApi = ReturnType<typeof createMeetingApi>;
