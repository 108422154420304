/* eslint-disable @typescript-eslint/no-explicit-any */
import { flow, getEnv, types } from "mobx-state-tree";

import { RegularInstructionModel } from "src/modules/models";

import { RootStoreEnv } from "./root.store";

export const RegularInstructionsStore = types
  .model("RegularInstruction", {
    regularInstructions: types.maybeNull(types.array(RegularInstructionModel)),
    regularInstruction: types.maybeNull(RegularInstructionModel),
  })
  .actions((self) => ({
    findRegularInstructions: flow(function* _(entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.findRegularInstructions(entityId);
        self.regularInstructions = result;
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    findOneRegularInstruction: flow(function* _(entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.findOneRegularInstruction(entityId);
        self.regularInstruction = result;

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    clearRegularInstruction() {
      self.regularInstruction = null;
    },
  }))
  .actions((self) => ({
    updateRegularInstructions: flow(function* _(clientLocationId: string, entityId: string, data: any) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.updateRegularInstructions(entityId, data);
        self.findRegularInstructions(clientLocationId);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    uploadDocument: flow(function* _(clientLocationId: string, entityId: string, files: FileList) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.uploadDocument(entityId, files);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteDocument: flow(function* _(entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.deleteDocument(entityId);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    deleteInstruction: flow(function* _(clientLocationId: string, entityId: string) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.deleteInstruction(entityId);
        self.findRegularInstructions(clientLocationId);

        return result;
      } catch (error) {
        console.error(error);
      }
    }),
    createRegularInstruction: flow(function* _(clientLocationId: string, data: any) {
      const {
        api: { instructions },
      } = getEnv<RootStoreEnv>(self);
      try {
        const result = yield instructions.createRegularInstruction(data);
        self.findOneRegularInstruction(result.id);
        return result;
      } catch (error) {
        console.error(error);
      }
    }),
  }))
  .views(() => ({}));

export const regularInstructionsStore = RegularInstructionsStore.create({
  regularInstructions: [],
  regularInstruction: null,
});
