import { AxiosInstance } from "axios";

export function createAuthenticationApi(axios: AxiosInstance) {
  return {
    async logout() {
      return axios({
        url: "/authenticate/logout",
        method: "POST",
      }).then((response) => response.data);
    }
  };
}

export type AuthenticationApi = ReturnType<typeof createAuthenticationApi>;
