import React, { useState } from "react";

import { useStore } from "../../context/store.context";

export const ClientLocationQRcode = () => {
  const [loadingDocument, setLoadingDocument] = useState(false);

  const {
    clientLocations: { clientLocation, generateQrCode },
  } = useStore();

  const openQrCodeDocument = (key: string) => {
    const link = document.createElement("a");
    link.href = key;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  /* Check if document exist already or generate new one then open in new tab the document */
  const generateQRCode = async () => {
    if (!clientLocation?.id) {
      return;
    }
    setLoadingDocument(true);
    const newQrCode = await generateQrCode(clientLocation.id);
    if (newQrCode?.qrcodeDocument?.key) {
      openQrCodeDocument(newQrCode.qrcodeDocument.key);
    }
    setLoadingDocument(false);
  };
  return (
    <div className="flex items-center my-6">
      <div className="w-full mt-4 text-center">
        <button onClick={() => generateQRCode()} className={`mx-auto cursor-pointer btn btn-sm ${loadingDocument ? "loading" : ""}`}>
          Télécharger le Qr Code
        </button>
      </div>
    </div>
  );
};
