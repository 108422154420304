/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { RegularInstructionModelType, UrgentInstructionModelType } from "src/modules/models";

export function createInstructionApi(axios: AxiosInstance) {
  return {
    async findRegularInstructions(params?: any) {
      return axios({
        url: `/regularInstructions/`,
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async findOneRegularInstruction(entityId: string) {
      return axios({
        url: `/regularInstructions/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async findUrgentInstructions(params?: any) {
      return axios({
        url: `/urgentInstructions/`,
        method: "GET",
        params,
      }).then((response) => response.data);
    },
    async findOneUrgentInstruction(entityId: string) {
      return axios({
        url: `/urgentInstructions/${entityId}`,
        method: "GET",
      }).then((response) => response.data);
    },
    async findRegularInstructionsDocuments(entityId: string) {
      return axios({
        url: "/regularInstructionDocuments/",
        method: "GET",
        data: entityId,
      }).then((response) => response.data);
    },
    async uploadDocument(entityId: string, files: FileList) {
      const bodyFormData = new FormData();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        bodyFormData.append(`files`, file);
      }
      bodyFormData.append(`regularInstructionId`, entityId);

      return axios({
        url: `/regularInstructionDocuments/upload`,
        method: "POST",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data);
    },
    async uploadUrgentDocument(entityId: string, files: FileList) {
      const bodyFormData = new FormData();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        bodyFormData.append(`files`, file);
      }
      bodyFormData.append(`urgentInstructionId`, entityId);

      return axios({
        url: `/urgentInstructionDocuments/upload`,
        method: "POST",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data);
    },
    async updateRegularInstructions(entityId: string, data: RegularInstructionModelType) {
      return axios({
        url: `/regularInstructions/${entityId}`,
        method: "PATCH",
        data: data,
      }).then((response) => response.data);
    },
    async updateUrgentInstructions(entityId: string, data: UrgentInstructionModelType) {
      return axios({
        url: `/urgentInstructions/${entityId}`,
        method: "PATCH",
        data: data,
      }).then((response) => response.data);
    },
    async deleteDocument(entityId: string) {
      return axios({
        url: `/regularInstructionDocuments/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async deleteInstruction(entityId: string) {
      return axios({
        url: `/regularInstructions/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async deleteUrgentDocument(entityId: string) {
      return axios({
        url: `/urgentInstructionDocuments/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async deleteUrgentInstruction(entityId: string) {
      return axios({
        url: `/urgentInstructions/${entityId}`,
        method: "DELETE",
      }).then((response) => response.data);
    },
    async createRegularInstruction(data: RegularInstructionModelType) {
      return axios({
        url: `/regularInstructions/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
    async createUrgentInstruction(data: UrgentInstructionModelType) {
      return axios({
        url: `/urgentInstructions/`,
        method: "POST",
        data,
      }).then((response) => response.data);
    },
  };
}

export type InstructionApi = ReturnType<typeof createInstructionApi>;
